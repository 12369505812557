(function () {
	angular.module('Plania').controller('CreateWorkOrderXSparePartController', ['$scope', 'ngTableParams', '$modalInstance', 'params', 'Repository', 'ListService', 'TranslationService', controller]);

	function controller($scope, ngTableParams, $modalInstance, params, repository, listService, translationService) {
		$scope.model = { Quantity: 0 };
		$scope.isUpdate = params.isUpdate;
		$scope.isWithdrawal = params.withdraw;
		$scope.restrictEdit = false;
		$scope.restrictEditMessage = '';
		$scope.isLoaded = false;
		$scope.info = {};

		$scope.tabs = [
			{ heading: translationService.translate('web-sparepart-chooseSparePart', "Velg reservedel"), step: 1 },
			{ heading: translationService.translate('web-sparepart-setNumber', "Sett antall"), step: 2 }
		];

		//Used on sparePartWithdraw
		$scope.modelProperties = { quantityToWithdraw: 0 };

		$scope.title = $scope.isUpdate ? translationService.translate('web-sparepart-edit-title', "Rediger reservedel") : translationService.translate('web-sparepart-new-title', "Legg til ny reservedel");

		$scope.step = $scope.isUpdate ? 2 : 1;

		$scope.setCurrentStep = function (step) {
			$('.tab-content').css('overflow', 'hidden');
			setTimeout(function () {
				$('.tab-content').css('overflow', 'visible');
			}, 500);
			$scope.step = step;
		};

		var sparePartColumns = [
			{ Title: translationService.translate('web-component-id', 'Id'), Property: 'Component.Id' },
			{ Title: translationService.translate('web-component-description', 'Beskrivelse'), Property: 'Component.Description' },
			{ Title: translationService.translate('web-componentXSupplier-partnumber', 'Delenummer'), Property: 'ComponentXSupplier.PartNumber' },
			{ Title: translationService.translate('web-componentXSupplier-Manufacturer', 'Fabrikat'), Property: 'ComponentXSupplier.Manufacturer' },
			{ Title: translationService.translate('web-componentXSupplier-Supplier', 'Leverandør'), Property: 'ComponentXSupplier.Supplier.Description' },
			{ Property: 'ComponentXSupplier.Price', hidden: true },
			{ Property: 'Inventory', hidden: true },
			{ Property: 'Component.Unit', hidden: true },
			{ Property: 'Component.GuidImage', hidden: true }
		];
		$scope.workOrder = params.workOrder;

		var getSparePartAttachments = function (guidComponent) {

			repository.getWithUrlParameter(repository.apiData.workOrder.endpoint.getSparePartAttachments, "Guid=" + $scope.workOrder.Guid + "&GuidComponent=" + guidComponent).then(function (result) {
				$scope.info.GuidComponentXEquipment=result.GuidComponentXEquipment;
				$scope.info.GuidComponentXArea =result.GuidComponentXArea;

			});


		};

		$scope.columns = _.filter(sparePartColumns, function (c) { return !c.hidden; });

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.reloadSpareParts = function () {

			$scope.isLoaded = true;

			if ($scope.sparePartTable) {
				$scope.sparePartTable.reload();
				return;
			}

			$scope.sparePartTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: {},
				filter: {}
			},
				{
					total: 0,
					counts: [10, 50, 100],
					paginationMaxBlocks: 8,
					getData: function ($defer, params) {
						var columns = _.map(sparePartColumns, 'Property');

						var filter = angular.copy(params.filter());

						if ($scope.info.selectedFilterEntity === "Equipment") {
							filter.GuidWorkOrderEquipment = $scope.workOrder.GuidEquipment;
							filter.PropertyFilter = [{ Property: 'IsTemplate', Operator: '=', Value: false }];
						}

						if ($scope.info.selectedFilterEntity === "Area") filter.GuidWorkOrderArea = $scope.workOrder.GuidArea;

						repository.GetPaginated(repository.apiData.sparePart.url,
							params.page() - 1,
							params.count(),
							params.sorting(),
							filter,
							null,
							JSON.stringify(columns))
							.then(
								function (result) {
									params.total(result.TotalCount);
									$defer.resolve(result.List);
								},
								function (error) {
									repository.growl(error, 'danger');
								});
					}
				});
		};

		if ($scope.isUpdate) {
			var columns = [
				'SparePart.ComponentXSupplier.PartNumber', 'SparePart.Inventory', 'SparePart.Component.Unit',
				'SparePart.Component.GuidImage', 'SparePart.Component.Id', 'SparePart.Component.Description', 'SparePart.Component.Guid',
				'WorkOrder.EndDate', 'WorkOrder.Stage', 'WorkOrder.Project.ClosedDate', 'WorkOrder.Equipment.Id', 'WorkOrder.Equipment.Description'
			];

			repository.getSingle(repository.apiData.workOrderSparePart.url, params.guid, JSON.stringify(columns))
				.then(
					function (result) {
						$scope.model = result.Data;
						$scope.model.Inventory = result.Data.SparePart.Inventory;

						if (result.Data.SparePart.Component) {
							$scope.model.Unit = result.Data.SparePart.Component.Unit;
							$scope.model.GuidImage = result.Data.SparePart.Component.GuidImage;
							$scope.model.GuidComponent = result.Data.SparePart.Component.Guid;
							$scope.model.ComponentId = result.Data.SparePart.Component.Id;
							$scope.model.ComponentDescription = result.Data.SparePart.Component.Description;
							$scope.model.PartNumber = result.Data.SparePart.ComponentXSupplier.PartNumber;

						}
						if ($scope.modelProperties) {
							if (params.withdraw) {
								$scope.modelProperties.quantityToWithdraw = $scope.model.Quantity - $scope.model.QuantityWithdrawn;
								$scope.maxValue = $scope.model.Inventory;
							}
							else {
								$scope.modelProperties.quantityToWithdraw = $scope.model.QuantityWithdrawn;
								$scope.maxValue = $scope.model.QuantityWithdrawn;
							}
						}
						$scope.isLoaded = true;
						if ($scope.isUpdate && !$scope.restrictEdit) {
							if ($scope.model.WorkOrder && $scope.model.WorkOrder.Stage === 'History') {
								$scope.restrictEdit = true;
								$scope.restrictEditMessage = translationService.translate('web-workOrderXSparePart-restrictEditMessage-woIsHistory', 'Redigering og sletting er ikke tilgjengelig når arbeidsordren som denne reservedelen er koblet til er hisorisk.');
							} else if ($scope.model.WorkOrder && $scope.model.WorkOrder.EndDate) {
								$scope.restrictEdit = true;
								$scope.restrictEditMessage = translationService.translate('web-workOrderXSparePart-restrictEditMessage-woHasEndDate', 'Redigering og sletting er ikke tilgjengelig når arbeidsordren som denne reservedelen er koblet til er fullført.');
							} else if ($scope.model.WorkOrder && $scope.model.WorkOrder.Project && $scope.model.WorkOrder.Stage === 'Project' && $scope.model.WorkOrder.Project.ClosedDate) {
								$scope.restrictEdit = true;
								$scope.restrictEditMessage = translationService.translate('web-workOrderXSparePart-restrictEditMessage-projectIsCompleted', 'Redigering og sletting er ikke tilgjengelig når prosjektet via tilknyttet arbeidsordre er fullført.');
							} else if ($scope.model.IsInvoiced) {
								$scope.restrictEdit = true;
								$scope.restrictEditMessage = translationService.translate('web-workOrderXSparePart-restrictEditMessage-isInvoiced', 'Redigering og sletting er ikke tilgjengelig fordi denne reservedelen er fakturert.');
							}
							getSparePartAttachments($scope.model.GuidComponent);
						}
					});
		} else {
			repository.getWithUrlParameter(repository.apiData.workOrder.endpoint.getDefaultSparePartEntity, "Guid=" + $scope.workOrder.Guid).then(function (result) {

				$scope.info = { selectedFilterEntity: result.Data };
				$scope.reloadSpareParts();
			});

		}

		$scope.chooseSparePart = function (item) {
			if ($scope.itemExcluded(item.Guid)) {
				return;
			}

			$scope.model = {
				GuidSparePart: item.Guid,
				Id: item.Component.Id,
				Description: item.Component.Description,
				Inventory: item.Inventory, //transient property for ui
				Unit: item.Component.Unit, //transient property for ui
				GuidImage: item.Component.GuidImage,
				GuidComponent:item.GuidComponent,
				Quantity: 0
			};
			if (item.ComponentXSupplier) {
				$scope.model.PartNumber = item.ComponentXSupplier.PartNumber;
				$scope.model.Price = item.ComponentXSupplier.Price;
			}
			getSparePartAttachments(item.GuidComponent);
			$scope.setCurrentStep(2);
		};

		$scope.itemExcluded = function (guid) {
			return _.contains(params.excludeGuids, guid);
		};

		$scope.save = function () {
			delete $scope.model.Inventory;
			delete $scope.model.Unit;
			delete $scope.model.GuidUmage;

			if ($scope.isWithdrawal !== undefined) {
				if ($scope.isWithdrawal) {
					$scope.model.QuantityWithdrawn = $scope.model.QuantityWithdrawn + $scope.modelProperties.quantityToWithdraw;
				}
				else {
					$scope.model.QuantityWithdrawn = $scope.model.QuantityWithdrawn - $scope.modelProperties.quantityToWithdraw;
				}
			}

			$modalInstance.close($scope.model);
		};

		$scope.cancel = function () {
			$modalInstance.close();
		};

		$scope.addComponentToEquipment = function () {
			if ($scope.isSaving) return;

			componentXEquipment = {
				GuidComponent: $scope.model.GuidComponent,
				GuidEquipment: $scope.workOrder.GuidEquipment,
				Quantity: 0.0
			};

			repository.createSingleDictionary(repository.apiData.componentEquipment.url, componentXEquipment).then(function (result) {
				getSparePartAttachments($scope.isUpdate ? $scope.model.GuidComponent : $scope.model.GuidComponent);
				swal(translationService.translate('web-swal-component-add-equipment-success', 'Komponenten ble lagt til anlegget!'), "", "success");
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.addComponentToArea = function () {
			if ($scope.isSaving) return;

			componentXArea = {
				GuidComponent: $scope.model.GuidComponent,
				GuidArea: $scope.workOrder.GuidArea,
				Quantity: 0.0
			};

			repository.createSingleDictionary(repository.apiData.componentArea.url, componentXArea).then(function (result) {
				getSparePartAttachments($scope.isUpdate ? $scope.model.GuidComponent : $scope.model.GuidComponent);
				swal(translationService.translate('web-swal-component-add-area-success', 'Komponenten ble lagt til arealet!'), "", "success");
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.removeComponentFromArea = function () {
			if ($scope.isSaving) return;

			repository.deleteSingle(repository.apiData.componentArea.url, $scope.info.GuidComponentXArea)
				.then(function (result) {
					getSparePartAttachments($scope.isUpdate ? $scope.model.GuidComponent : $scope.model.GuidComponent);
					swal(translationService.translate('web-swal-component-remove-area-success', 'Komponenten ble fjernet fra arealet!'), "", "success");
				});
		};

		$scope.removeComponentFromEquipment = function () {
			if ($scope.isSaving) return;

			repository.deleteSingle(repository.apiData.componentEquipment.url, $scope.info.GuidComponentXEquipment)
				.then(function (result) {
					getSparePartAttachments($scope.isUpdate ? $scope.model.GuidComponent : $scope.model.GuidComponent);
					swal(translationService.translate('web-swal-component-remove-equipment-success', 'Komponenten ble fjernet fra anlegget!'), "", "success");
				});
		};

		$scope.manageEquipment = function () {
			if ($scope.info.GuidComponentXEquipment) {
				$scope.removeComponentFromEquipment();
			} else {
				$scope.addComponentToEquipment();
			}

		};

		$scope.manageArea = function () {
			if ($scope.info.GuidComponentXArea) {
				$scope.removeComponentFromArea();
			}
			else {
				$scope.addComponentToArea();

			}

		};

		$scope.$watch('info.selectedFilterEntity', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			if (newValue) {
				$scope.reloadSpareParts();
			}
		});
	}

})();
