(function () {
	var app = angular.module('Plania');

	app.directive('plDrawingWidget', ['TranslationService', '$rootScope', function (translationService, $rootScope) {
		return {
			restrict: 'A',
			require: '^plDashboardGrid',
			scope: {
				widget: '=widget',
				edit: '=edit',
				saveFunction: '=saveFunction',
				shouldStopInterval: '=shouldStopInterval'
			},
			link: function (scope, element, attrs, gridCtrl) {
				scope.$parent.attachWidget(element, attrs);

				scope.removeWidget = function () {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-dashboard-drawing-message', "Tegningene vil bli permanent fjernet!"),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-dashboard-drawing-confirm', 'Ja, fjern tegningene'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-dashboard-drawing-success', 'Tegningene ble fjernet!'), '', "success");
						scope.$parent.removeWidget(element, attrs);
					});
				};
			},
			templateUrl: 'app/dashboard/directives/widgets/drawingWidget.html',
			controller: ['$scope', 'TranslationService', 'Repository', '$modal', '$interval', 'DashboardSharedDataService', function ($scope, translationService, repository, modal, $interval, sharedDataService) {
				$scope.model = $scope.widget;
				$scope.model.fullscreen = false;
				$scope.showNetAreal = true;
				$scope.isStaticTheme = true;
				$scope.update = false;

				$scope.errorState = {
					hasError: false,
					text: ""
				};

				if (typeof ($scope.model.WidgetData) === "string") {
					$scope.model.WidgetData = JSON.parse($scope.model.WidgetData);
				}

				$scope.drawings = [];

				var areas = [];

				var getDrawings = function () {
					$scope.errorState = {
						hasError: false,
						text: ""
					};

					var filter = {
						PropertyFilter: []
					};

					var guidDrawings = [];

					if ($scope.model.WidgetData.DrawingSource && $scope.model.WidgetData.DrawingSource !== "selection") {
						switch ($scope.model.WidgetData.DrawingSource) {
							case "personOrganization":
								if (!$rootScope.userInfo.guidPersonOrganization) {
									$scope.drawings = [];
									areas = [];
									delete $scope.model.selectedDrawing;
									$scope.errorState.hasError = true;
									$scope.errorState.text = "Mangler organisasjon på person.";
									return;
								}
								filter.PropertyFilter.push({
									Property: "Areas.GuidOrganization",
									Operator: "=",
									Value: $rootScope.userInfo.guidPersonOrganization
								});
								break;
							case "personOrganizationUnit":
								if (!$rootScope.userInfo.guidPersonOrganizationUnit) {
									$scope.drawings = [];
									areas = [];
									delete $scope.model.selectedDrawing;
									$scope.errorState.hasError = true;
									$scope.errorState.text = "Mangler organisasjonsenhet på person.";
									return;
								}

								filter.PropertyFilter.push({
									Property: "Areas.GuidOrganizationUnit",
									Operator: "=",
									Value: $rootScope.userInfo.guidPersonOrganizationUnit
								});
								break;
							case "personOrganizationSection":
								if (!$rootScope.userInfo.guidPersonOrganizationSection) {
									$scope.drawings = [];
									areas = [];
									delete $scope.model.selectedDrawing;
									$scope.errorState.hasError = true;
									$scope.errorState.text = "Mangler organisasjonseksjon på person.";
									return;
								}
								filter.PropertyFilter.push({
									Property: "Areas.GuidOrganizationSection",
									Operator: "=",
									Value: $rootScope.userInfo.guidPersonOrganizationSection
								});
								break;
						}
					} else {
						// Originally we kept whole object of Drawings
						if (typeof ($scope.model.WidgetData.Drawings[0]) === "object")
							guidDrawings = _.map($scope.model.WidgetData.Drawings, 'Guid');
						// Prepare to only keep Guid of drawings.
						else if (typeof ($scope.model.WidgetData.Drawings[0]) === "string")
							guidDrawings = $scope.model.WidgetData.Drawings;

						if (!guidDrawings.length) {
							$scope.drawings = [];
							areas = [];
							delete $scope.model.selectedDrawing;
							return;
						}

						filter.PropertyFilter.push({
							Property: "Guid",
							Operator: "in",
							Value: guidDrawings.join(",")
						});
					}

					repository.GetPaginated(repository.apiData.drawing.url, 0, -1, {}, filter, null, JSON.stringify(["Id", "Description"])).then(function (result) {
						var drawings = result.List;

						// Make sure that the order for drawings are the same as the list of guids (when using manual selection).
						if (guidDrawings.length) {
							drawings = drawings.sort(function (a, b) {
								return guidDrawings.indexOf(a.Guid) - guidDrawings.indexOf(b.Guid);
							});
						}

						$scope.drawings = drawings;

						$scope.model.selectedDrawing = $scope.drawings[0];
						var guidSelectedDrawings = _.map($scope.drawings, 'Guid');
						sharedDataService.setDrawingGuids(guidSelectedDrawings);

						areas = [];
						var filter = { PropertyFilter: [{ Property: 'GuidDrawing', Operator: 'in', value: guidSelectedDrawings.join(',') }] };
						repository.GetPaginated(repository.apiData.area.url, 0, -1, {}, filter, null, JSON.stringify(['Id'])).then(function (result) {
							areas = areas.concat(result.List);
						});
					}, function (error) {
						repository.showError(error);
					});
				};

				getDrawings();

				$scope.$on('dashboard.activateItem', function (evt, data) {
					if (!data.GuidArea) return;
					var area = _.find(areas, { Guid: data.GuidArea });
					if (!area) //selected request is not in a area connected to any of the drawings
						return;
					var drawing = _.find($scope.drawings, { Guid: area.GuidDrawing });
					if (!drawing) return;
					$scope.model.selectedDrawing = drawing;
					$scope.model.highlightArea = data.GuidArea;
				});

				$scope.setFullscreen = function () {
					$scope.model.fullscreen = !$scope.model.fullscreen;
				};

				$scope.editWidget = function () {
					modal.open({
						controller: 'AddWidgetModalController',
						templateUrl: 'app/dashboard/addWidgetModal.html',
						resolve: {
							widget: function () {
								return $scope.model;
							}
						}
					}).result.then(function (widgetModel) {
						$scope.saveFunction(false).then(function () {
							getDrawings();
						});
					}, function () {
						//Dismissed
					});
				};

				$scope.updateFunction = function () {
					$scope.update = !$scope.update;
				};
			}],
		};
	}]);
})();
