(function () {
	angular.module('Plania').controller('ConditionChangeMaintenanceReferencesModalController', ['$scope', 'Repository', 'modalParams', '$modalInstance', 'TranslationService', controller]);

	function controller($scope, repository, modalParams, $modalInstance, translationService) {
		$scope.model = angular.copy(modalParams.model);

		$scope.autoCompleteFilter = modalParams.autoCompleteFilter;

		if (modalParams.onChangeEvents) {
			Object.keys(modalParams.onChangeEvents).forEach(function (onChangeKey) {
				$scope[onChangeKey] = function (entity) {
					modalParams.onChangeEvents[onChangeKey]($scope.model, entity);
				};
			});
		}

		$scope.confirmChange = function () {
			var properties = {
				GuidArea: $scope.model.GuidArea,
				GuidEquipment: $scope.model.GuidEquipment,
			};

			repository.patch(repository.apiData.condition.url, $scope.model.Guid, JSON.stringify(properties)).then(function (result) {
				repository.growl(translationService.translate('web-condition-changeMaintenanceReferences-success', 'Vedlikeholdsobjekt er oppdatert'), 'success');
				$modalInstance.close();
			}, function (error) {
				repository.showError(error);
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
