(function () {
    angular.module('Plania').controller('ConditionConfirmVersionModalController', ['$scope', 'Repository', 'modalParams', '$modalInstance', 'ngTableParams', '$rootScope', 'TranslationService', '$window', controller]);

    function controller($scope, repository, modalParams, $modalInstance, ngTableParams, $rootScope, translationService, $window) {
        var selectedGuids = modalParams.guids;
        var conditionDate = modalParams.conditionDate;
        var type = modalParams.type;
        var guidConditionType = modalParams.guidConditionType;

        var responses = [];

        $scope.currentTab = 'info';
		$scope.VersionComment = "";
		$scope.isRunningAction = false;

		var isToday = function (testDate) {
			if (!testDate || testDate === "") return;
			var d = new Date(testDate);

			var today = new Date();
			return d.getDate() === today.getDate() &&
				d.getMonth() === today.getMonth() &&
				d.getFullYear() === today.getFullYear();
        };

        var shouldShowConfirmWithDate = function () {
            if (selectedGuids.length === 1 && conditionDate && type && !isToday(conditionDate)) {
                return type === 'CurrentConditionInfo' || (type === 'CorrectiveAction' && !!guidConditionType);
            }
            return false;
        };

        $scope.showConfirmWithConditionDateAsTodayButton = shouldShowConfirmWithDate();

        $scope.confirmVersion = function (setConditionDateAsToday) {
            $scope.isRunningAction = true;
            var dto = {
                Guids: selectedGuids,
                VersionComment: $scope.VersionComment.trim() !== "" ? $scope.VersionComment : null,
                ConditionDate: setConditionDateAsToday ? new Date().toISOString() : null
            };

            repository.runAction(repository.apiData.condition.endpoint.confirmVersion, dto, null, true).then(function (result) {
                $scope.isRunningAction = false;
                if (!result.AnyFailed) {
                    $modalInstance.close();
                } else {
                    $scope.currentTab = 'errorMessages';
                    responses = result.Items;
                    $scope.errorMessageTable.reload();
                }
            }, function (error) {
                $scope.isRunningAction = false;
                repository.showError(error);
            });
        };

        $scope.errorMessageTable = new ngTableParams({
            page: 1,
            count: 10
        }, {
            total: responses.length,
            counts: [5, 10, 20],
            getData: function ($defer, params) {
                $scope.errorMessageTable.settings().total = responses.length;
                $defer.resolve(responses.filter(function (i) { return !i.IsSuccess; }).slice((params.page() - 1) * params.count(), params.page() * params.count()));
            }
        });

        $scope.contextMenuOptions = function (item) {
            var options = [];
            options.push({
                html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="fas fa-calendar-note fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-condition-confirmVersion-viewCondition', 'Åpne tilstand/tiltak i nytt vindu') + '</a >',
                click: function ($itemScope, $event, modelValue, text, $li) {
                    $window.open($rootScope.navigation.href('condition.edit', { guid: item.Guid }), '_blank');
                }
            });
            return options;
        };

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
