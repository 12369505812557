angular.module('Plania').directive('dwgSelection', ['$injector', '$interval', '$rootScope', '$timeout', function ($injector, $interval, $rootScope, $timeout) {
    return {
        restrict: 'E',
		scope: {
			drawings: '=drawings', //Drawing[]
			selectedDrawing: '=selectedDrawing', //Drawing
			rotation: '=rotation', // {active: bool, interval: int}
			refresh: '=refresh', // {active: bool, interval: int}
            shouldStopInterval: '=shouldStopInterval', //boolean
            updateFunction: '=updateFunction' //() => void
        },
        link: function link(scope) {
            var rotationInterval;
            var refreshInterval;
			scope.setSelectedDrawing = function (dwg) {
				// Previously used scope.apply, but this causes errors randomly.
				// Use timeout since it will do the same without error.
				// Reloading scope, since we cause events outside angularJs with the slick slider click event.
				$timeout(function () {
					scope.selectedDrawing = dwg;
					setRotationInterval();
				}, 10);
            };

            scope.responsiveSliderBreakpoints = [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                }, {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }, {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
				}
			];

			scope.$watchGroup(['rotation.active', 'rotation.interval'], function (newValues, oldValues, scope) {
                if (newValues[0] === oldValues[0] && newValues[1] === oldValues[1]) return;
                setRotationInterval();
            });

			scope.$watchGroup(['refresh.active', 'refresh.interval'], function (newValues, oldValues, scope) {
                if (newValues[0] === oldValues[0] && newValues[1] === oldValues[1]) return;
                setRefreshInterval();
            });

            scope.$watch('shouldStopInterval', function (newValue, oldValue) {
            	if (newValue === oldValue) return;

            	if (newValue) {
            		cancelInterval(rotationInterval);
            		cancelInterval(refreshInterval);
            	} else {
            		setRotationInterval();
            		setRefreshInterval();
            	}
            });

            function setRefreshInterval() {
                if (scope.refresh) {
					if (scope.refresh.interval && scope.refresh.active) {
                        if (refreshInterval) {
                            cancelInterval(refreshInterval);
                        }

                        refreshInterval = $interval(function() {
                            if (scope.updateFunction && typeof scope.updateFunction === "function")
                                scope.updateFunction();

						}, scope.refresh.interval * 1000);

                        scope.$on('$destroy', function() {
                            cancelInterval(refreshInterval);
                        });
                    } else if (refreshInterval) {
                        cancelInterval(refreshInterval);
                    }
                }
            }

            scope.$watch('selectedDrawing', function (newValue, oldValue) {
                if (newValue === oldValue) return;
                if (!newValue) return;
                scope.setSelectedDrawing(newValue);
            });

            function setRotationInterval() {
                if (scope.rotation) {
					if (scope.rotation.interval && scope.rotation.active) {
                        if (rotationInterval) {
                            cancelInterval(rotationInterval);
                        }

                        rotationInterval = $interval(function() {
                            var idx = scope.drawings.indexOf(scope.selectedDrawing);
							if (idx === scope.drawings.length - 1) {
                                idx = 0;
                            } else {
                                idx++;
                            }

                            scope.selectedDrawing = scope.drawings[idx];
                            $('slick').slick('slickGoTo', idx);
						}, scope.rotation.interval * 1000);

                        scope.$on('$destroy', function() {
                            cancelInterval(rotationInterval);
                        });
                    } else if (rotationInterval) {
                        cancelInterval(rotationInterval);
                    }
                }
            }

            function cancelInterval(interval) {
                $interval.cancel(interval);
                interval = undefined;
            }

            var repository = $injector.get('Repository');
			scope.svg = {};

			// Make sure we only fetch once.
			var drawingStates = {};
            var getDrawings = function () {
                //this should be optimalized to get all thumbnails in one call instead of foreaching and sending multiple requests to api
                if (scope.drawings) {
					scope.drawings.forEach(function (drawing) {
						if (drawingStates[drawing.Guid]) return;
						drawingStates[drawing.Guid] = true;

		                var columns = ['Thumbnail'];
                        repository.getSingle(repository.apiData.drawing.url, drawing.Guid, JSON.stringify(columns)).then(function(result) {
                            scope.svg[drawing.Guid] = 'data:image/png;base64,' + result.Data.Thumbnail;
                        });
                    });
                }
			};

            setRotationInterval();
			setRefreshInterval();
			getDrawings();

			scope.$watch("drawings", function () {
				getDrawings();
			});

			// Fix for slick since it will lose data from angular, ngClick, dwg... when the screen resizes or using tablets/phones.
			$timeout(function () {
				$('.slick-slider').on('click', '.slick-slide', function () {
					// The first selected element does not remove selected for some reasons on IOS. Force it to be removed.
					$('#' + scope.selectedDrawing.Guid + ' .dwg-thumbnail').removeClass('selected');

					var guidDrawing = $(this).attr('id');
					if (guidDrawing) {
						var drawing = scope.drawings.find(function (dwg) { return dwg.Guid === guidDrawing; });
						if (drawing)
							scope.setSelectedDrawing(drawing);
					}
				});
			}, 100);

			// Just in case.
			scope.$on('$destroy', function () {
				$('.slick-slider').off('click', '.slick-slide');
			});
        },
        templateUrl: '/app/dwg/views/dwgSelectionDirective.html'
    };
}]);
