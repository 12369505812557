(function () {
	var app = angular.module('Plania');
	app.directive('controlListInfoDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate',
				activateAutocomplete: '=activateAutocomplete',
				currentTab: '=currentTab'
			},
			controller: ['$scope', 'ngTableParams', 'Repository', 'TranslationService', '$modal', '$rootScope', 'ListService', '$timeout', controller],
			templateUrl: 'app/controlList/views/editControlListViews/controlListInfo.html',
		};
	});

	function controller($scope, ngTableParams, repository, translationService, $modal, $rootScope, listService, $timeout) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasEditAccess = $rootScope.hasEditAccess;
		$scope.hasModule = $rootScope.hasModule;

		$scope.viewMode = $scope.isUpdate;
		$scope.controlListRevisionTableSearch = {
			focus: false,
			toggle: false
		};

		// Make boolean available to do something when current tab has been loaded.
		$scope.hasPageLoaded = $scope.currentTab === 'info';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'info' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						// Unregister watcher
						pageListener();
					}
				}, 250);
			});
		}

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		//This list is also hard-coded in ControlListPrint.cs because of color format issues, so any changes here should be reflected there.
		$scope.availableColors = ['#2196F3', '#673AB7', '#009688', '#4caf50', '#ff9800', '#00bcd4', '#9E9E9E', '#607D8B', '#000000', '#f44336'];

		$scope.openIconModal = function () {
			var modalInstance = $modal.open({
				templateUrl: 'app/common/modal/views/selectIconModal.html',
				controller: 'SelectIconModalController'
			});

			modalInstance.result.then(function (selectedIcon) {
				$scope.model.Icon = selectedIcon;
			});
		};

		$scope.canSetNotExecutedChanged = function (model) {
			if (!model.canSetNotExecuted) {
				$scope.model.GuidReferenceTypeNotExecuted = '';
				delete $scope.model.ReferenceTypeNotExecuted;
			}
		};

		$scope.controlListRevisionColumns = [
			{ Position: 1, Title: translationService.translate('web-controlList-revisionTable-controlList-version', 'Versjon'), Property: "Version", PropertyType: "string" },
			{ Position: 2, Title: translationService.translate('web-controlList-revisionTable-controlList-versionStatus', 'Status'), Property: 'VersionStatus', PropertyType: 'enum.ControlListVersionStatus' },
			{ Position: 3, Title: translationService.translate('web-controlList-revisionTable-controlList-updatedDate', 'Oppdatert'), Property: "UpdatedDate", PropertyType: "date" },
			{ Position: 4, Title: translationService.translate('web-controlList-revisionTable-controlList-versionComment', 'Kommentar'), Property: "VersionComment", PropertyType: "string" }
		];

		var getRevisionTable = function () {
			if (!$scope.isUpdate) return;

			if ($scope.controlListRevisionTable) {
				$scope.controlListRevisionTable.reload();
				return;
			}

			$scope.controlListRevisionTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'Version': 'desc' },
				filter: { GetAllVersions: true, PropertyFilter: [{ Property: 'GuidMasterRecord', Operator: '=', Value: $scope.model.GuidMasterRecord }] },
			}, {
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				getData: function ($defer, params) {
					var columns = [];
					$scope.controlListRevisionColumns.forEach(function (col) {
						if (col.Property)
							columns.push(col.Property);
					});

					repository.GetPaginated(repository.apiData.controlList.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns)).then(function (result) {
						$scope.controlListRevisionTable.settings().total = result.TotalCount;
						$scope.controlListRevisionTable.settings().filterDelay = 500;
						$defer.resolve(result.List);
					}, function (error) {
						repository.growl(error, 'danger');
					});
				}
			});
		};

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		if ($scope.isUpdate) {
			$scope.$watch("model.GuidMasterRecord", function (newVal) {
				if (!newVal) return;
				getRevisionTable();
			});
		}

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "referenceType":
					filter.PropertyFilter = [
						{ Property: 'Context', Operator: "=", Value: 2 },
					];
					return filter;
			}
			return filter;
		};

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			// Show icon, else draw a random icon to keep same padding on left.
			if (icon)
				htmlText += '<i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.goToVersion = function (guid) {
			if (!guid || guid === $scope.model.Guid) return;
			window.open($rootScope.navigation.href('controlList.edit', { guid: guid }), "_blank");
		};

		$scope.revisionTableContextMenu = function (item) {
			var options = [];

			if (item.Guid !== $scope.model.Guid)
				options.push(getMenuLink($rootScope.navigation.href('controlList.edit', { guid: item.Guid }), translationService.translate('web-controlList-infoTab-revisionTableContextMenu-controlList-view', 'Åpne kontrolliste')));

			return options;
		};
	}
})();
