(function () {
	angular.module('Plania').controller('AddWidgetModalController', ['$scope', 'Repository', '$modalInstance', '$modal', 'TranslationService', 'ListService', 'widget', 'DwgThemeService', '$timeout', controller]);

	function controller($scope, repository, modalInstance, $modal, translationService, listService, widget, dwgThemeService, $timeout) {
		$scope.model = {};
		$scope.isEdit = false;
		$scope.apiData = _.sortBy(_.filter(repository.apiData, function (data) { return data.availableForListView === true; }), function (o) { return o.description; });
		$scope.keywords = repository.filterKeyWords;
		$scope.timeKeywords = repository.timeKeywords;
		$scope.sortingColumns = [];
		$scope.boolSelect = [{ name: 'Ja', value: true }, { name: 'Nei', value: false }];

		$scope.minimumRefreshInterval = 60;
		var defaultRefreshInterval = 600;

		$scope.widgetTypes = [
			{ title: translationService.translate('web-widget-widgetType-table', 'Tabell'), directiveName: 'pl-table-widget' },
			{ title: translationService.translate('web-widget-widgetType-list', 'Innmeldinger'), directiveName: 'pl-list-widget' },
			{ title: translationService.translate('web-widget-widgetType-link', 'Linker'), directiveName: 'pl-link-widget' },
			{ title: translationService.translate('web-widget-widgetType-report', 'Rapport'), directiveName: 'pl-report-widget' },
			{ title: translationService.translate('web-widget-widgetType-calendar', 'Kalender'), directiveName: 'pl-calendar-widget' },
			{ title: translationService.translate('web-widget-widgetType-drawing', 'Tegning'), directiveName: 'pl-drawing-widget' },
			{ title: translationService.translate('web-widget-widgetType-kpi', 'Nøkkeltall'), directiveName: 'pl-kpi-widget' },
			{ title: translationService.translate('web-widget-widgetType-kpiGroupTable', 'Kpi tabell'), directiveName: 'pl-kpi-group-table-widget' },
			{ title: translationService.translate('web-widget-widgetType-htmlText', 'Html tekst'), directiveName: 'pl-html-text-widget' }
		];
		$scope.valueColumnFunctions = [
			{ title: translationService.translate('web-widget-function-sum', 'Sum'), function: 'sum' },
			{ title: translationService.translate('web-widget-function-count', 'Antall'), function: 'count' },
			{ title: translationService.translate('web-widget-function-average', 'Gjennomsnitt'), function: 'average' },
			{ title: translationService.translate('web-widget-function-max', 'Maks'), function: 'max', },
			{ title: translationService.translate('web-widget-function-min', 'Min'), function: 'min' }
		];

		$scope.widgetTypes = _.sortBy($scope.widgetTypes, function (o) { return o.title; });
		$scope.valueColumnFunctions = _.sortBy($scope.valueColumnFunctions, function (o) { return o.title; });

		$scope.getPaginationCounts = function () {
			if (!$scope.model || !$scope.model.WidgetType) return [];
			if ($scope.model.WidgetType === 'pl-kpi-group-table-widget')
				return ['10', '25', '50', '100', translationService.translate('web-table-pagination-all', 'Alle')];
			else
				return ['10', '25', '50', '100'];
		};

		$scope.colors = [
			'bluegray',
			'lightblue',
			'cyan',
			'teal',
			'green',
			'yellow',
			'lightorange',
			'orange',
			'blue',
			'purple',
			'deeppurple',
			'red',
			'black'
		];
		$scope.limitedColors = [
			'lightblue',
			'teal',
			'green',
			'yellow',
			'lightorange',
			'orange',
			'red',
			'black'
		];

		$scope.datePickers = {};
		$scope.open = function ($event, calendar) {
			$event.preventDefault();
			$event.stopPropagation();

			$scope.datePickers[calendar] = !$scope.datePickers[calendar];
		};
		$scope.calendarViewTypes = [
			{
				id: 'month',
				description: translationService.translate('web-addwidget-calendartype-month', 'Måned')
			},
			{
				id: 'basicWeek',
				description: translationService.translate('web-addwidget-calendartype-week', 'Uke')
			},
			{
				id: 'basicDay',
				description: translationService.translate('web-addwidget-calendartype-day', 'Dag')
			}
		];

		var changeModalSize = function (size) {
			$timeout(function () {
				if (size)
					$('#addWidgetModalHeader').closest('.modal-dialog').removeClass('modal-lg modal-sm modal-md modal-xl').addClass('modal-' + size);
				else
					$('#addWidgetModalHeader').closest('.modal-dialog').removeClass('modal-lg modal-sm modal-md modal-xl');
			}, 100);
		};

		// Helpers for finding correct contextId depending on the available data per widget.
		var getContextIdFromApiData = function (selectedApiData) {
			if (!selectedApiData)
				return;
			if (selectedApiData.contextId)
				return selectedApiData.contextId;
			return getContextIdFromFromPrefix(selectedApiData.prefix);
		};

		var getContextIdFromFromPrefix = function (prefix) {
			if (!prefix)
				return;
			return 'List-' + prefix;
		};

		var getContextIdFromFromServiceUrl = function (serviceUrl) {
			if (!serviceUrl)
				return;

			var dataTypes = _.filter($scope.apiData, function (data) {
				return data.url === serviceUrl;
			});

			if (!dataTypes.length)
				return;

			if (dataTypes.length === 1)
				return getContextIdFromApiData(dataTypes[0]);

			var firstPrefix = _.find($scope.apiData, function (data) {
				return !!data.prefix;
			});

			if (!firstPrefix)
				return;

			return getContextIdFromFromPrefix(firstPrefix);
		};

		var getWebListViews = function () {
			var filters = {};

			if ($scope.model.selectedDataType.description === 'Tiltak') {
				filters.isCorrectiveAction = true;
			} else if ($scope.model.selectedDataType.description === 'Avvik') {
				filters.isCorrectiveAction = false;
			}

			var contextId = $scope.model.selectedDataType.contextId || 'List-' + $scope.model.selectedDataType.prefix;
			listService.getWebListViews($scope.model.WidgetData.SelectedListSetup, $scope.model.selectedDataType.prefix, true, contextId, filters).then(function (result) {
				$scope.listOptions = result.listOptions;
				$scope.model.WidgetData.SelectedListSetup = result.selectedListSetup;
				$scope.model.WidgetData.SelectedListSetup.VisibleColumns = _.filter($scope.model.WidgetData.SelectedListSetup.Columns, function (c) {
					if (c.Filter && c.Filter.some(function (f) { return f.Hidden; }))
						return false;
					return true;
				});
			});
		};

		$scope.onSelectWidgetType = function (prev) {
			var setDefaultConfiguration = function () {
				switch ($scope.model.WidgetType) {
					case 'pl-table-widget':
						$scope.model.WidgetData.RefreshInterval = defaultRefreshInterval;
						$scope.model.WidgetData.defaultPaginationCount = 10;
						break;
					case 'pl-list-widget':
						$scope.model.WidgetData.RefreshInterval = defaultRefreshInterval;
						break;
					case 'pl-kpi-widget':
						$scope.model.WidgetData.color = 'bluegray';
						$scope.model.WidgetData.columns = [];
						$scope.model.WidgetData.thresholds = [];
						break;
					case 'pl-link-widget':
						if (!$scope.model.WidgetData.Links) {
							$scope.model.WidgetData.Links = [];
						}
						break;
					case 'pl-drawing-widget':
						if (!$scope.model.WidgetData.Drawings) {
							$scope.model.WidgetData.Drawings = [];
						}
						$scope.dwgThemes = dwgThemeService.getAvailableThemes();
						$scope.model.WidgetData.RefreshInterval = defaultRefreshInterval;

						// For radios to select where drawings should be fetched from.
						$scope.drawingDataSourceSelection = 'selection';
						$scope.model.WidgetData.DrawingSource = 'selection';
						break;
					case 'pl-report-widget':
						var filter = {
							PropertyFilter: [
								{ Property: 'ReportType', Operator: '=', Value: '3' },
							]
						};
						repository.GetPaginated(repository.apiData.report.url, 0, 100, null, filter).then(function (result) {
							$scope.availableReports = result.List;
						});
						break;
					case 'pl-calendar-widget':
						$scope.model.WidgetData.ViewType = 'basicWeek';
						break;
					case 'pl-kpi-group-table-widget':
						$scope.model.WidgetData.columns = [];
						$scope.model.WidgetData.defaultPaginationCount = 10;
						$scope.model.WidgetData.RefreshInterval = defaultRefreshInterval;
						$scope.model.WidgetData.kpiGroupTable = {
							valueColumns: [], sortingColumn: {}, sortingProperty: null
						};
						$scope.model.WidgetData.color = 'blue';
						break;
					case 'pl-html-text-widget':
						$scope.model.WidgetData.color = 'bluegray';
						$scope.model.WidgetData.ServiceUrl = repository.apiData.building.url;
						$scope.model.WidgetData.prefix = repository.apiData.building.prefix;
						$scope.model.WidgetData.fromActiveBuilding = true;
						$scope.model.WidgetData.htmlTextBody = "";
						$scope.model.WidgetData.showWidgetHeader = false;
						setTinymceOptions();
						break;
					case 'pl-chart-widget':
						$scope.model.WidgetData.color = 'bluegray';
						$scope.model.WidgetData.columns = [];
						$scope.model.WidgetData.thresholds = [];
						$scope.model.WidgetData.chartType = "column";
						$scope.model.WidgetData.kpiGroupTable = {
							valueColumns: [], sortingColumn: {}, sortingProperty: null
						};
						break;
				}

				if ($scope.model.WidgetType === 'pl-html-text-widget')
					changeModalSize('lg');
				else
					changeModalSize();
			};

			if (!$scope.model.WidgetData) {
				$scope.model.WidgetData = {};
			}

			if (prev && $scope.model.WidgetType !== prev) {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-addWidgetModal-onSelectWidgetType-message', 'Endring av widgettype vil nullstille alle konfigurasjonene du har lagt til for denne widgeten.'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-addWidgetModal-onSelectWidgetType-button-confirm', 'Ja, endre widgettype'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt')
				}, function (confirm) {
					window.onkeydown = null;
					window.onfocus = null;
					if (confirm) {
						$scope.model.WidgetData = {};
						$scope.model.selectedDataType = undefined;
						setDefaultConfiguration();
					} else {
						$scope.model.WidgetType = prev;
						$scope.$apply();
					}
				});
			} else {
				setDefaultConfiguration();
			}

		};

		$scope.onSelectDataType = function () {
			if (!$scope.model.WidgetData) {
				$scope.model.WidgetData = {};
			}

			var updateConfiguration = function (reset) {
				// Always set contextId for future features.
				$scope.model.WidgetData.contextId = getContextIdFromApiData($scope.model.selectedDataType);

				if ($scope.model.WidgetType === 'pl-table-widget') {
					$scope.model.WidgetData.ServiceUrl = $scope.model.selectedDataType.url;
					$scope.model.WidgetData.NavigationState = $scope.model.selectedDataType.navigationState;
					getWebListViews();
				}

				if ($scope.model.WidgetType === 'pl-kpi-widget') {
					$scope.model.WidgetData.prefix = $scope.model.selectedDataType.prefix;
					if (!$scope.model.WidgetData.kpiProperty || reset)
						$scope.model.WidgetData.kpiProperty = {};
					if (!$scope.model.WidgetData.columns || reset)
						$scope.model.WidgetData.columns = [];
					if (!$scope.model.WidgetData.thresholds || reset)
						$scope.model.WidgetData.thresholds = [];
					getWebListViews();
				}

				if ($scope.model.WidgetType === 'pl-kpi-group-table-widget') {
					$scope.model.WidgetData.prefix = $scope.model.selectedDataType.prefix;
					if (!$scope.model.WidgetData.columns || reset) {
						$scope.model.WidgetData.columns = [];
					}
					if (reset) {
						$scope.model.WidgetData.kpiGroupTable = {
							valueColumns: [], sortingColumn: {}, sortingProperty: null
						};
					}
				}

				if ($scope.model.WidgetType === 'pl-chart-widget') {
					if ($scope.model.selectedDataType) {
						$scope.model.WidgetData.prefix = $scope.model.selectedDataType.prefix;
						if (!$scope.model.WidgetData.columns || reset) {
							$scope.model.WidgetData.columns = [];
						}
					}
					if (reset) {
						$scope.model.WidgetData.chartType = "column";
						$scope.model.WidgetData.kpiGroupTable = {
							valueColumns: [], sortingColumn: {}, sortingProperty: null
						};
					}
				}

				if (reset)
					$scope.$apply();
			};

			var showMessage = false;
			var prefixChanged = $scope.model.WidgetData.prefix && $scope.model.selectedDataType.prefix && $scope.model.WidgetData.prefix !== $scope.model.selectedDataType.prefix;
			if (($scope.model.WidgetType === 'pl-kpi-widget' || $scope.model.WidgetType === 'pl-kpi-group-table-widget' || $scope.model.WidgetType === 'pl-chart-widget') && prefixChanged)
				showMessage = true;

			if (showMessage) {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-addWidgetModal-onChangeDataType-message', 'Endring av datatype vil slette noen konfigurasjoner for å unngå inkonsistent oppsett'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-addWidgetModal-onChangeDataType-button-confirm', 'Ja, endre datatype'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt')
				}, function (confirm) {
					window.onkeydown = null;
					window.onfocus = null;
					if (confirm) {
						updateConfiguration(true);
					} else {
						$scope.model.selectedDataType = $scope.apiData.find(function (data) {
							return data.prefix === $scope.model.WidgetData.prefix;
						});
						$scope.$apply();
					}
				});
			} else {
				updateConfiguration();
			}
		};

		$scope.onSelectDrawingDataSourceSelection = function (drawingDataSource) {
			if (drawingDataSource === 'user')
				$scope.model.WidgetData.DrawingSource = '';
			else
				$scope.model.WidgetData.DrawingSource = 'selection';
		};

		$scope.updateSortingColumns = function (skipDefault) {
			var columns = [];

			if ($scope.model.WidgetData.kpiGroupTable.groupColumn) {
				columns.push({
					Title: $scope.model.WidgetData.kpiGroupTable.groupColumn.Title,
					Property: $scope.model.WidgetData.kpiGroupTable.groupColumn.Property,
					Direction: $scope.model.WidgetData.kpiGroupTable.groupColumn.Direction ? $scope.model.WidgetData.kpiGroupTable.groupColumn.Direction : 'asc',
					PropertyType: $scope.model.WidgetData.kpiGroupTable.groupColumn.PropertyType
				});
			}

			if ($scope.model.WidgetData.kpiGroupTable.valueColumns && $scope.model.WidgetData.kpiGroupTable.valueColumns.length) {
				$scope.model.WidgetData.kpiGroupTable.valueColumns.forEach(function (col) {
					var colCopy = angular.copy(col);
					var functionTitle = '';
					if (colCopy.Function === 'sum') functionTitle = translationService.translate('web-widget-function-sum-headerTitle', 'Sum');
					else if (colCopy.Function === 'count') functionTitle = translationService.translate('web-widget-function-count-headerTitle', 'Antall');
					else if (colCopy.Function === 'average') functionTitle = translationService.translate('web-widget-function-average-headerTitle', 'Snitt');
					else if (colCopy.Function === 'max') functionTitle = translationService.translate('web-widget-function-max-headerTitle', 'Maks');
					else if (colCopy.Function === 'min') functionTitle = translationService.translate('web-widget-function-min-headerTitle', 'Min');
					colCopy.Title = functionTitle + " " + colCopy.Title;

					columns.push({
						Title: colCopy.Title,
						Property: colCopy.Property,
						Direction: colCopy.Direction ? colCopy.Direction : 'asc',
						Function: colCopy.Function,
						PropertyType: colCopy.PropertyType
					});
				});
			}

			if (!skipDefault) {
				var firstColumn = columns[0];
				if (!$scope.model.WidgetData.kpiGroupTable.sortingColumn)
					$scope.model.WidgetData.kpiGroupTable.sortingColumn = firstColumn;
				else if (firstColumn && firstColumn.Property !== $scope.model.WidgetData.kpiGroupTable.sortingColumn.Property) {
					$scope.model.WidgetData.kpiGroupTable.sortingColumn = firstColumn;
				}
			}

			$scope.sortingColumns = columns;
		};

		var initSelectedDataTypeFromWidgetData = function () {
			var selectedDataType = null;

			if ($scope.model.WidgetType === 'pl-table-widget') {
				if (!$scope.model.WidgetData.NavigationState)
					return;
			}

			selectedDataType = _.find($scope.apiData, function (data) {
				var apiDataContextId = data.contextId;

				var shouldCheckContext = !!apiDataContextId && !!$scope.model.WidgetData.contextId;
				if (shouldCheckContext && apiDataContextId !== $scope.model.WidgetData.contextId)
					return false;

				if (['pl-table-widget'].includes($scope.model.WidgetType))
					return data.url === $scope.model.WidgetData.ServiceUrl;

				if (['pl-kpi-widget', 'pl-kpi-group-table-widget', 'pl-chart-widget'].includes($scope.model.WidgetType))
					return data.prefix === $scope.model.WidgetData.prefix;

				return false;
			});

			$scope.model.selectedDataType = selectedDataType;
			if (selectedDataType)
				$scope.onSelectDataType();
		};

		if (widget) {
			$scope.model = widget;

			switch ($scope.model.WidgetType) {
				case 'pl-table-widget':
					if (!$scope.model.WidgetData.defaultPaginationCount)
						$scope.model.WidgetData.defaultPaginationCount = 10;

					if (!$scope.model.WidgetData.contextId)
						$scope.model.WidgetData.contextId = getContextIdFromFromServiceUrl($scope.model.WidgetData.ServiceUrl);

					initSelectedDataTypeFromWidgetData();
					break;
				case 'pl-link-widget':
					if (!$scope.model.WidgetData.Links) {
						$scope.model.WidgetData.Links = [];
					}
					break;
				case 'pl-report-widget':
					$scope.onSelectWidgetType();
					break;
				case 'pl-drawing-widget':
					$scope.dwgThemes = dwgThemeService.getAvailableThemes();
					if (!$scope.model.WidgetData.DrawingSource && $scope.model.WidgetData.Drawings)
						$scope.model.WidgetData.DrawingSource = $scope.model.WidgetData.Drawings.length ? "selection" : undefined;
					if (!$scope.model.WidgetData.DrawingSource || $scope.model.WidgetData.DrawingSource === "selection")
						$scope.drawingDataSourceSelection = 'selection';
					else
						$scope.drawingDataSourceSelection = 'user';
					break;
				case 'pl-kpi-widget':
					if (!$scope.model.WidgetData.contextId)
						$scope.model.WidgetData.contextId = getContextIdFromFromPrefix($scope.model.WidgetData.prefix);

					initSelectedDataTypeFromWidgetData();
					break;
				case 'pl-kpi-group-table-widget':
					if (!$scope.model.WidgetData.contextId)
						$scope.model.WidgetData.contextId = getContextIdFromFromPrefix($scope.model.WidgetData.prefix);

					initSelectedDataTypeFromWidgetData();
					$scope.updateSortingColumns(true);
					break;
				case 'pl-html-text-widget':
					setTinymceOptions();
					if ($scope.model.WidgetData.htmlTextBody)
						$scope.model.WidgetData.htmlTextBody = decodeHtml($scope.model.WidgetData.htmlTextBody);
					break;
				case 'pl-chart-widget':
					if (!$scope.model.WidgetData.contextId)
						$scope.model.WidgetData.contextId = getContextIdFromFromPrefix($scope.model.WidgetData.prefix);
					initSelectedDataTypeFromWidgetData();
					break;
			}

			if ($scope.model.WidgetType === 'pl-html-text-widget')
				changeModalSize('lg');
			else
				changeModalSize();
			$scope.isEdit = true;
		}

		$scope.editLink = function (link) {
			$modal.open({
				controller: 'AddNewLinkModalController',
				templateUrl: 'app/dashboard/directives/widgets/addNewLinkModal.html',
				resolve: {
					link: function () {
						return link;
					}
				}
			}).result.then(function (editedLink) {
				if (link) {
					link = editedLink;
				} else {
					if ($scope.model.WidgetData.Links.length > 0) {
						var sortedLinks = _.sortBy($scope.model.WidgetData.Links, function (o) { return o.Id; });
						var newId = sortedLinks[sortedLinks.length - 1].Id + 1;
						editedLink.Id = newId;
					} else {
						editedLink.Id = 1;
					}

					$scope.model.WidgetData.Links.push(editedLink);
				}
			});
		};

		$scope.addDrawings = function () {
			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Legg til Tegning',
							columns: [
								{ Title: translationService.translate('web-drawing-id'), Property: 'Id' },
								{ Title: translationService.translate('web-drawing-description'), Property: 'Description' },
							],
							url: repository.apiData.drawing.url,
							sorting: { Id: 'asc' },
							filter: {},
							useObjects: true
						};
					}
				}
			}).result.then(function (selectedItems) {
				if (!$scope.model.WidgetData.Drawings)
					$scope.model.WidgetData.Drawings = [];

				selectedItems.forEach(function (item) {
					if (!_.find($scope.model.WidgetData.Drawings, function (o) { return o.Guid === item.Guid; })) {
						$scope.model.WidgetData.Drawings.push(item);
					}
				});
			}, function (error) {

			});
		};

		$scope.addNewColumn = function (type) {
			if (!$scope.model.WidgetData.prefix) {
				swal(
					translationService.translate('web-widget-addNewListColumn-missingPrefix-title', 'Mangler datatype'),
					translationService.translate('web-widget-addNewListColumn-missingPrefix-message', 'Velg en datatype for å kunne legge til en kolonne'),
					'warning');
				return;
			}

			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/addNewListColumnModal.html',
				controller: 'AddNewListColumnController',
				resolve: {
					params: function () {
						return {
							prefix: $scope.model.WidgetData.prefix,
							existingColumns: type === 'filter' ? null : $scope.model.WidgetData.columns,
							hideExpandableColumns: type === 'keycolumn',
							showAdvancedMode: true
						};
					}
				}
			});

			modalInstance.result.then(function (selectedColumns) {
				var column = selectedColumns[0];
				if (type === 'filter') {
					selectedColumns.forEach(function (column) {
						column.Filter = [{ Operator: '=', Property: column.Property }];

						// Set null to trigger default selection in plEnumDropdown
						if (column.PropertyType.startsWith('enum.'))
							column.Filter[0].Value = null;

						$scope.model.WidgetData.columns.push(column);
						if (column.PropertyType === 'date') {
							$scope.datePickers[column.$$hashKey] = false;
						}
					});
				}
				if (type === 'keycolumn') {
					$scope.model.WidgetData.kpiProperty = { title: column.Title, property: column.Property, propertyType: column.PropertyType, operation: $scope.model.WidgetData.kpiProperty.operation, value: '' };
				}
				if (type === 'interval') {
					$scope.model.WidgetData.interval = { title: column.Title, property: column.Property, daysBack: 0, daysForward: 0 };
				}
				if (type === 'keyGroupColumn') {
					$scope.model.WidgetData.kpiGroupTable.groupColumn = { Title: column.Title, Property: column.Property, PropertyType: column.PropertyType };
					$scope.updateSortingColumns();
				}
				if (type === 'valueColumn') {
					selectedColumns.forEach(function (column) {
						var valueColumn = {
							Title: column.Title,
							Property: column.Property,
							Function: ((column.PropertyType === 'number' || column.PropertyType === 'double' || column.PropertyType === 'decimal') && column.Property !== "Id") ? 'sum' : 'count',
							PropertyType: column.PropertyType
						};
						$scope.model.WidgetData.kpiGroupTable.valueColumns.push(valueColumn);
						if (column.PropertyType === 'date') {
							$scope.datePickers[column.$$hashKey] = false;
						}
					});
					$scope.updateSortingColumns();
				}
			});
		};

		$scope.valueColumnFunctionFilter = function (column) {
			return function (valueFunction) {
				if (((column.PropertyType === 'number' || column.PropertyType === 'double' || column.PropertyType === 'decimal') && column.Property !== "Id")) return true;
				else {
					if (valueFunction.function === "count" || valueFunction.function === "percent") return true;
					return false;
				}
			};
		};

		$scope.openIconModal = function () {
			var modalInstance = $modal.open({
				templateUrl: 'app/common/modal/views/selectIconModal.html',
				controller: 'SelectIconModalController'
			});

			modalInstance.result.then(function (selectedIcon) {
				$scope.model.WidgetData.icon = selectedIcon;
			});
		};

		$scope.removeIcon = function () {
			$scope.model.WidgetData.icon = null;
		};

		$scope.$watch('model.WidgetData.kpiProperty.operation', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			if (newValue === 'count' && !$scope.model.WidgetData.kpiProperty.property) {
				$scope.model.WidgetData.kpiProperty = { title: 'Guid', property: 'Guid', operation: 'count', value: '' };
			}
		});

		$scope.onHtmlDataTypeSelect = function () {
			if ($scope.model.WidgetData) {
				if (!$scope.model.WidgetData.prefix) {
					$scope.model.WidgetData.ServiceUrl = '';
					$scope.model.WidgetData.prefix = '';
					$scope.model.WidgetData.fromActiveBuilding = false;
				} else {
					$scope.model.WidgetData.ServiceUrl = repository.apiData.building.url;
					$scope.model.WidgetData.prefix = repository.apiData.building.prefix;
					$scope.model.WidgetData.fromActiveBuilding = true;
				}
				setTinymceOptions();
			}
		};

		function encodeHtml(html) {
			if (!html) html = '';
			var tmpElement = document.createElement('div');
			return $(tmpElement).text(html).html();
		}

		function decodeHtml(html) {
			if (!html) html = '';
			var tmpElement = document.createElement('textarea');
			tmpElement.innerHTML = html;
			return tmpElement.value;
		}

		function setTinymceOptions() {
			var toolbar = 'undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright lineheightselect | bullist numlist outdent indent | addProperty addUserProperty addBuildingImage';
			if (!$scope.model.WidgetData.prefix)
				toolbar = 'undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright lineheightselect | bullist numlist outdent indent | addUserProperty';

			var setup = function (editor) {
				if ($scope.model.WidgetData.prefix) {
					editor.addButton('addProperty', {
						icon: 'plus',
						tooltip: translationService.translate('web-htmlWidget-tinyMceButton-addDomainProperties', 'Sett inn et databasefelt'),
						onclick: function () {
							if (!$scope.model.WidgetData.prefix) {
								swal('Ingen entitet valgt', 'Velg en entitet for å kunne legge til en kolonne i malen', 'warning');
								return;
							}

							var modalInstance = $modal.open({
								templateUrl: 'app/common/views/addNewListColumnModal.html',
								controller: 'AddNewListColumnController',
								resolve: {
									params: function () {
										return {
											prefix: $scope.model.WidgetData.prefix,
											existingColumns: []
										};
									}
								}
							});

							modalInstance.result.then(function (selectedColumns) {
								selectedColumns.forEach(function (column) {
									editor.insertContent(column.Title + ': ' + '{{' + column.Property + '}} ');
								});
							});
						}
					});

					editor.addButton('addBuildingImage', {
						icon: 'image',
						tooltip: translationService.translate('web-htmlWidget-tinyMceButton-addBuildingImage', 'Sett inn fasadebilde'),
						onclick: function () {
							// Add class just in case so we can modify, and maybe select the class when we replace the src.
							// Currently we replace the exact src name, so if we change image, then we need to find a way to change by selecting the class.
							editor.insertContent('<img src="/images/plania-placeholder-bordered-759x500.png" class="htmlTextWidgetBuildingPlaceholder" width="375" height="214" />');
						}
					});
				}

				editor.addButton('addUserProperty', {
					icon: 'user',
					tooltip: translationService.translate('web-htmlWidget-tinyMceButton-addLoggedInUserProperties', 'Sett inn felt fra innlogget bruker'),
					onclick: function () {
						var modalInstance = $modal.open({
							templateUrl: 'app/common/modal/views/selectUserInfoPropertyModal.html',
							controller: 'SelectUserInfoPropertyModal'
						});

						modalInstance.result.then(function (selectedProperties) {
							selectedProperties.forEach(function (property) {
								editor.insertContent('{{' + property + '}} ');
							});
						});
					}
				});
			};

			$scope.tinymceOptions = {
				language: 'nb_NO',
				branding: false,
				plugins: [
					'advlist autolink lists link image hr',
					'searchreplace lineheight',
					'save table contextmenu directionality',
					'paste textcolor colorpicker imagetools autoresize'
				],
				menubar: 'edit insert format table',
				toolbar: toolbar,
				image_advtab: true,
				relative_urls: false,
				convert_urls: false,
				remove_script_host: false,
				paste_data_images: true,
				autoresize_min_height: 400,
				autoresize_max_height: 600,
				forced_root_block: false,
				lineheight_formats: "14pt 16pt 18pt 20pt 22pt 24pt 26pt 36pt",
				inline_styles: true,
				ui_container: '.modal', // select modal to set correct position for dropdowns.
				font_formats: 'Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Calibri=calibri;Comic Sans MS=comic sans ms,sans-serif;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;',
				setup: setup
			};
			$scope.$broadcast('$tinymce:refresh');
		}

		$scope.validateWidget = function () {
			switch ($scope.model.WidgetType) {
				case 'pl-table-widget':
					break;
				case 'pl-list-widget':
					break;
				case 'pl-link-widget':
					break;
				case 'pl-report-widget':
					break;
				case 'pl-drawing-widget':
					if (!$scope.model.WidgetData.DrawingSource) {
						repository.growl(translationService.translate('web-drawingWidget-missing-drawingSource', 'Må velge en datakilde for tegninger'), 'danger');
						return false;
					}
					break;
				case 'pl-kpi-widget':
					var property = $scope.model.WidgetData.kpiProperty;
					if (!property) {
						repository.growl(translationService.translate('web-kpiWidget-missing-selectedDatatype', 'Datatype er obligatorisk'), 'danger');
						return false;
					}

					if (!property.operation) {
						repository.growl(translationService.translate('web-kpiWidget-missing-operation', 'Kalkuleringstype er obligatorisk'), 'danger');
						return false;
					}
					if (property.operation !== 'count' && !property.property) {
						repository.growl(translationService.translate('web-kpiWidget-missing-property', 'Nøkkelkolonne er obligatorisk'), 'danger');
						return false;
					}

					if (property.operation === 'percent' && property.propertyType.startsWith('enum.') && (property.value === undefined || property.value === null || property.value === "")) {
						repository.growl(translationService.translate('web-kpiWidget-percentOperation-enumMissingValue', 'Mangler sammenligningsverdi'), 'danger');
						return false;
					}

					break;
				case 'pl-kpi-group-table-widget':
					if (!$scope.model.WidgetData.prefix) {
						repository.growl(translationService.translate('web-kpiGroupTableWidget-missing-prefix', 'Datatype er obligatorisk'), 'danger');
						return false;
					}

					if (!$scope.model.WidgetData.kpiGroupTable.groupColumn) {
						repository.growl(translationService.translate('web-kpiGroupTableWidget-missing-groupColumn', 'Grupperingskolonne er obligatorisk'), 'danger');
						return false;
					}

					if (!$scope.model.WidgetData.kpiGroupTable.valueColumns || !$scope.model.WidgetData.kpiGroupTable.valueColumns.length) {
						repository.growl(translationService.translate('web-kpiGroupTableWidget-missing-valueColumns', 'Minst 1 verdi kolonne må bli valgt'), 'danger');
						return false;
					}

					break;
				case 'pl-chart-widget':
					if (!$scope.model.WidgetData.prefix) {
						repository.growl(translationService.translate('web-chartWidget-missing-prefix', 'Datatype er obligatorisk'), 'danger');
						return false;
					}
					if (!$scope.model.WidgetData.kpiGroupTable.groupColumn) {
						repository.growl(translationService.translate('web-chartWidget-missing-groupColumn', 'Grupperingskolonne er obligatorisk'), 'danger');
						return false;
					}

					if (!$scope.model.WidgetData.kpiGroupTable.valueColumns || !$scope.model.WidgetData.kpiGroupTable.valueColumns.length) {
						repository.growl(translationService.translate('web-chartWidget-missing-valueColumns', 'Minst 1 verdi kolonne må bli valgt'), 'danger');
						return false;
					}

					break;
			}

			if ($scope.model.WidgetData.AutoRefresh && (!$scope.model.WidgetData.RefreshInterval || $scope.model.WidgetData.RefreshInterval < $scope.minimumRefreshInterval)) {
				$scope.model.WidgetData.RefreshInterval = $scope.minimumRefreshInterval;
			}

			return true;
		};

		$scope.save = function () {
			if (!$scope.validateWidget()) return;
			if ($scope.model.WidgetType === 'pl-html-text-widget') {
				// IMPORTANT - Else users can run javascript code.
				$scope.model.WidgetData.htmlTextBody = encodeHtml($scope.model.WidgetData.htmlTextBody);
			}

			if ($scope.model.WidgetType === 'pl-drawing-widget') {
				if ($scope.model.WidgetData.DrawingSource !== 'selection')
					$scope.model.WidgetData.Drawings = [];
			}

			modalInstance.close($scope.model);
		};

		$scope.cancel = function (reason) {
			modalInstance.dismiss('cancel');
		};
	}
})();
