(function () {
	angular.module('Plania').controller('SelectAttachmentModalController', ['$scope', '$modalInstance', 'params', 'Repository', '$modal', controller]);

	function controller($scope, $modalInstance, params, repository, $modal) {
		$scope.selectedDocuments = [];

		// Create a new copy so we won't modify by reference.
		$scope.documents = [];
		params.attachments.forEach(function (attachment) {
			$scope.documents.push(angular.copy(attachment));
		});

		$scope.connectedEntityDocuments = [];
		$scope.entityType = params.entity;

		var guidEntity = params.guid;

		// Events to notify that new documents and images have been created. on event is only to notify, while reload event expects the new entities in a list.
		var onNewDocument = params.onNewDocument;
		var onNewImage = params.onNewImage;

		var reloadDocuments = params.reloadDocuments;
		var reloadImages = params.reloadImages;

		$scope.canAddImage = $scope.entityType === 'WorkOrder' || $scope.entityType === 'Request';
		$scope.canAddDocument = true;

		$scope.documents.forEach(function (document) {
			if (_.contains(params.selectedAttachmentGuids, document.Guid)) {
				document.isAlreadySelected = true;
				$scope.selectedDocuments.push(document);
			} else {
				document.isAlreadySelected = false;
			}
		});

		if (params.connectedEntity && params.connectedEntityGuid) {
			repository.GetPaginated(repository.apiData.documentXEntity.url, 0, 100, { 'Document.Id': 'desc' }, { PropertyFilter: [{ Property: 'Guid' + params.connectedEntity, Operator: '=', Value: params.connectedEntityGuid }] }, 0, JSON.stringify(columns)).then(function (response) {
				$scope.connectedEntityDocuments = response.List;
			});
		}

		$scope.isSelected = function (guid) {
			return _.find($scope.selectedDocuments, { Guid: guid });
		};

		$scope.selectDocument = function (document) {
			if (document.isAlreadySelected) return;

			if (!_.find($scope.selectedDocuments, { Guid: document.Guid }))
				$scope.selectedDocuments.push(document);
			else {
				for (var i = 0; i < $scope.selectedDocuments.length; i++) {
					var doc = $scope.selectedDocuments[i];
					if (doc.Guid === document.Guid) {
						$scope.selectedDocuments.splice(i, 1);
						return;
					}
				}
			}
		};

		var resolveParams = function () {
			var params = {};
			params["guid" + $scope.entityType] = guidEntity;
			return params;
		};

		$scope.addNewDocument = function () {
			$modal.open({
				templateUrl: 'app/document/views/createDocumentModal.html',
				controller: 'CreateDocumentXEntityController',
				size: 'lg',
				resolve: {
					params: resolveParams
				}
			}).result.then(function (response) {
				// Guids of Documents.
				var guids = [];
				if (response && response.savedFiles && Array.isArray(response.savedFiles)) {
					response.savedFiles.forEach(function (obj) {
						if (obj.guid)
							guids.push(obj.guid);
					});
				}
				
				if (reloadDocuments && typeof reloadDocuments === "function") {
					reloadDocuments(guids).then(function (documents) {
						documents.forEach(function (document) {
							if (!_.find($scope.documents, { Guid: document.Guid })) {
								var newDocument = angular.copy(document);
								$scope.documents.push(angular.copy(newDocument));
								if (guids.includes(newDocument.GuidDocument))
									$scope.selectDocument(newDocument);
							}
						});
					});
				}

				if (onNewDocument && typeof onNewDocument === "function")
					onNewDocument(guids);
			});
		};

		$scope.addNewImage = function () {
			$modal.open({
				templateUrl: 'app/document/views/createImageModal.html',
				controller: 'CreateDocumentXEntityController',
				size: 'lg',
				resolve: {
					params: resolveParams
				}
			}).result.then(function (images) {
				var guids = [];
				if (images && Array.isArray(images)) {
					images.forEach(function (image) {
						if (image.Guid)
							guids.push(image.Guid);
					});
				}
				
				if (reloadImages && typeof reloadImages === "function") {
					var promise = reloadImages();
					if (promise) {
						promise.then(function (images) {
							images.forEach(function (image) {
								if (!_.find($scope.documents, { Guid: image.Guid })) {
									var newImage = angular.copy(image);
									$scope.documents.push(angular.copy(newImage));
									if (guids.includes(newImage.Guid))
										$scope.selectDocument(newImage);
								}
							});
						});
					}
				}

				if (onNewImage && typeof onNewImage === "function")
					onNewImage(images);
			});
		};

		$scope.ok = function () {
			$modalInstance.close($scope.selectedDocuments);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss();
		};
	}
})();
