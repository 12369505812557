(function () {

	var app = angular.module('Plania');

	app.directive('operationalMessage', function () {
		return {
			restrict: 'E',
			scope: {
				isCollapsed: '=isCollapsed',
				reload: '=reload',
				operationalMessages: '=operationalMessages',
				disableActions: '=disableActions',
				parentEntity: '=parentEntity',
				parentModel: '=parentModel',
				guid: '=guid',
				restrictEdit: '=restrictEdit',
			},
			controller: ['$scope', '$modal', 'ngTableParams', 'Repository', '$rootScope', 'TranslationService', '$localStorage', 'OperationalMessageService', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/operationalMessage/views/operationalMessageDirective.html'
		};
	});

	function controller($scope, $modal, ngTableParams, repository, $rootScope, translationService, $localStorage, operationalMessageService) {
		$scope.messagesIsLoading = false;
		$scope.search = {};

		var updateAccess = function () {
			$scope.hasReadAccess = $rootScope.hasReadAccess('OperationalMessage');
			$scope.hasEditAccess = $rootScope.hasEditAccess('OperationalMessage');
			$scope.hasCreateAccess = $rootScope.hasCreateAccess('OperationalMessage');
			$scope.hasDeleteAccess = $rootScope.hasDeleteAccess('OperationalMessage');
		};
		updateAccess();

		var getPropertyFilter = function () {
			var propertyFilter = [];

			// Due to multiple levels of OR filters, we create use subFilters logic that is not implemented yet as of 8.17 with custom implementation on domain to handle this.
			// Problem is the first OR will be grouped correctly, but next OR filters will break out into its own group.
			var entityFilter = {
				Operand: "or", SubFilters: [{ Property: 'Guid' + $scope.parentEntity, Operator: '=', Value: $scope.guid }]
			};

			propertyFilter.push(entityFilter);
			var commonFilter = operationalMessageService.getCommonPropertyFilter($localStorage.selectedOperationalMessageFilterType);
			if (commonFilter && Array.isArray(commonFilter)) {
				commonFilter.forEach(function (f) { propertyFilter.push(f); });
			}

			return propertyFilter;
		};

		$scope.$watch('reload', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			if ($scope.reload && $scope.reload === true) {
				$scope.operationalMessageTable.reload();
				$scope.reload = false;
			}
		});

		var columns = [
			'Message', 'DueDate', 'Type',
			'StartDate', 'Person.FirstName', 'Person.LastName',
			'Equipment.Id', 'Equipment.Description', 'Equipment.Building.Id', 'Equipment.Building.Description', 'Equipment.Building.Estate.Id', 'Equipment.Building.Estate.Description',
			'Building.Id', 'Building.Description', 'Building.Estate.Id', 'Building.Estate.Description',
			'Estate.Id', 'Estate.Description',
			'WorkOrder.Id', 'WorkOrder.Description',
		];

		$scope.operationalMessageTable = new ngTableParams({
			page: 1,
			count: 5,
			sorting: { 'DueDate': 'desc' }
		}, {
			total: 0,
			counts: [5, 10, 20],
			filterDelay: 50,
			paginationMaxBlocks: 6,
			getData: function ($defer, params) {
				var propertyFilter = getPropertyFilter();
				if (propertyFilter.length === 0) return;
				var filter = angular.copy(params.filter());
				filter.PropertyFilter = propertyFilter;
				$scope.messagesIsLoading = true;

				repository.GetPaginated(repository.apiData.operationalMessage.url, params.page() - 1, params.count(), params.sorting(), filter, null, JSON.stringify(columns)).then(function (result) {
					$scope.operationalMessageTable.settings().total = result.TotalCount;
					$scope.operationalMessageTable.settings().filterDelay = 500;

					if (!$scope.operationalMessages)
						$scope.operationalMessages = [];

					if ($scope.operationalMessages) {
						while ($scope.operationalMessages.length > 0)
							$scope.operationalMessages.pop();
					}

					result.List.forEach(function (message) {
						message.state = operationalMessageService.getCurrentStateEnum(message);
						message.startDateText = operationalMessageService.getStartDateText(message);
						message.dueDateText = operationalMessageService.getDueDateText(message);
						$scope.operationalMessages.push(message);
					});

					$defer.resolve(result.List);
					$scope.messagesIsLoading = false;
				}, function (error) {
					repository.growl(error, 'danger');
				});
			}
		});

		var filterTypes = operationalMessageService.getCommonFilterTypes();

		$scope.setFilter = function (filterType) {
			$scope.selectedFilterName = _.find(filterTypes, { value: filterType }).name;
			$localStorage.selectedOperationalMessageFilterType = filterType;
			$scope.operationalMessageTable.reload();
			$scope.showFilterDropdown = false;
		};

		if ($localStorage.selectedOperationalMessageFilterType) {
			$scope.selectedFilterName = _.find(filterTypes, { value: $localStorage.selectedOperationalMessageFilterType }).name;
			$scope.setFilter($localStorage.selectedOperationalMessageFilterType);
		} else {
			$scope.selectedFilterName = _.find(filterTypes, { value: 'active' }).name;
			$scope.setFilter(_.find(filterTypes, { value: 'active' }).value);
		}

		$scope.create = function (type) {
			$modal.open({
				templateUrl: 'app/operationalMessage/views/operationalMessageModal.html',
				controller: 'OperationalMessageModalController',
				resolve: {
					params: function () {
						return {
							parentGuid: $scope.guid,
							parentEntityType: $scope.parentEntity,
							parentModel: $scope.parentModel
						};
					}
				}
			}).result.then(function () {
				$scope.operationalMessageTable.reload();
			}, function () {
				$scope.operationalMessageTable.reload();
				//cancel
			});
		};

		$scope.open = function (message) {
			$modal.open({
				templateUrl: 'app/operationalMessage/views/operationalMessageModal.html',
				controller: 'OperationalMessageModalController',
				resolve: {
					params: function () {
						return {
							guid: message.Guid
						};
					}
				}
			}).result.then(function () {
				$scope.operationalMessageTable.reload();
			}, function () {
				//cancel
			});
		};

		$scope.remove = function (message) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-operationalMessage-message', 'Driftsmeldingen vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-operationalMessage-button-confirm', 'Ja, fjern driftsmeldingen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.deleteSingle(repository.apiData.operationalMessage.url, message.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-operationalMessage-success', 'Driftsmeldingen ble fjernet!'), result, "success");
						$scope.operationalMessageTable.reload();
					}, function (error) {
						swal('Error', error, "error");
					});
			});
		};

		$scope.connectOperationalMessage = function () {
			var operationalMessageColumns = [
				{ Position: 1, Title: translationService.translate('web-operationalMessage-type'), Property: 'Type', PropertyType: 'enum.OperationalMessageType' },
				{ Position: 2, Title: translationService.translate('web-operationalMessage-equipment-id', 'Anleggsnr'), Property: 'Equipment.Id', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-operationalMessage-equipment-description', 'Anleggsnavn'), Property: 'Equipment.Description', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-operationalMessage-message'), Property: 'Message', PropertyType: 'string' },
				{ Position: 5, Title: translationService.translate('web-operationalMessage-startDate'), Property: 'StartDate', PropertyType: 'date' },
				{ Position: 6, Title: translationService.translate('web-operationalMessage-dueDate'), Property: 'DueDate', PropertyType: 'date' },
				
				
				
			];

			var propertyFilter = [{ Property: "GuidWorkOrder", Operator: '=', Value: null }];

			if ($scope.parentModel.GuidBuilding && $scope.parentModel.Building)
				propertyFilter.push({ Property: "GuidBuilding", Operator: '=', Value: $scope.parentModel.GuidBuilding, allowToggle: true, isDisabled: false, filterValue: $scope.parentModel.Building.Caption });

			if ($scope.parentModel.GuidEquipment && $scope.parentModel.Equipment)
				propertyFilter.push({ Property: "GuidEquipment", Operator: '=', Value: $scope.parentModel.GuidEquipment, allowToggle: true, isDisabled: false, filterValue: $scope.parentModel.Equipment.Caption });

			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				size: 'lg',
				resolve: {
					modalParams: function () {
						return {
							title: translationService.translate('web-operational-message-choose', 'Velg driftsmelding'),
							columns: operationalMessageColumns,
							useObjects: true,
							url: repository.apiData.operationalMessage.url,
							sorting: { DueDate: 'desc' },
							filter: { PropertyFilter: propertyFilter }
						};
					}
				}
			}).result.then(function (operationalMessages) {
				if (operationalMessages.length > 0) {
					var changeList = [];

					operationalMessages.forEach(function (operationalMessage) {
						changeList.push(JSON.stringify({ Guid: operationalMessage.Guid, GuidWorkOrder: $scope.guid }));
					});

					repository.patch(repository.apiData.operationalMessage.url, null, changeList).then(function (result) {
						repository.growl(translationService.translate('operationalMessageList-addMultiple-success', 'Driftsmeldingene har blitt lagt til'), "success");
						$scope.operationalMessageTable.reload();
					}, function (error) {
						repository.growl(error, 'danger');
						$scope.operationalMessageTable.reload();
					});
				}
			});
		};

		$scope.getLowestEntityCaption = operationalMessageService.getLowestEntityCaption;
		$scope.getEntityIcon = operationalMessageService.getEntityIcon;

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			// Show icon, else draw a random icon to keep same padding on left.
			if (icon)
				htmlText += '<i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.menuOptions = function (message) {
			var options = [];
			if ($scope.hasReadAccess) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href=""><i class="zmdi invisible zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-operationalMessage-menuOption-view', 'Åpne') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.open(message);
					}
				});
			}

			if (message.GuidWorkOrder && $scope.hasEditAccess) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-operationalMessage-menuOption-removeWorkOrder', 'Fjern fra arbeidsordren') + '</a >',
					click: function () {
						repository.patch(repository.apiData.operationalMessage.url, message.Guid, JSON.stringify({ GuidWorkOrder: null })).then(function (response) {
							repository.growl(translationService.translate('web-operationalMessage-removeWorkOrder-success', 'Driftsmeldingen ble fjernet fra arbeidsordren'), 'success');
							$scope.operationalMessageTable.reload();
						}, function (error) {
							repository.showError(error);
						});
					}
				});
			}

			if (message.GuidEstate && message.GuidEstate !== $scope.guid) {
				options.push(getMenuLink($rootScope.navigation.href('estate.edit', { guid: message.GuidEstate }), translationService.translate('web-operationalMessage-menuOption-viewEstate', 'Gå til eiendom')));
			}

			if (message.GuidBuilding && message.GuidBuilding !== $scope.guid) {
				options.push(getMenuLink($rootScope.navigation.href('building.edit', { guid: message.GuidBuilding }), translationService.translate('web-operationalMessage-menuOption-viewBuilding', 'Gå til bygg')));
			}

			if (message.GuidEquipment && message.GuidEquipment !== $scope.guid) {
				options.push(getMenuLink($rootScope.navigation.href('equipment.edit', { guid: message.GuidEquipment }), translationService.translate('web-operationalMessage-menuOption-viewEquipment', 'Gå til anlegg')));
			}

			if (message.GuidWorkOrder && message.GuidWorkOrder !== $scope.guid) {
				options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: message.GuidWorkOrder }), translationService.translate('web-operationalMessage-menuOption-viewWorkOrder', 'Gå til arbeidsodre')));
			} else if (!message.GuidWorkOrder && $rootScope.hasCreateAccess('WorkOrder')) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-operationalMessage-menuOption-createWorkOrder', 'Opprett arbeidsordre') + '</a >',
					click: function () {
						operationalMessageService.createWorkOrder(message);
					}
				});
			}

			if ($scope.hasDeleteAccess) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href=""><i class="zmdi invisible zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-operationalMessage-menuOption-delete', 'Slett') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.remove(message);
					}
				});
			}

			return options;
		};

		$scope.$on($rootScope.events.newSelection, function () {
			updateAccess();
			$scope.operationalMessageTable.reload();
		});
	}
})();
