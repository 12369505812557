
(function () {

	var app = angular.module('Plania');

	app.directive('plAlert', function () {
		return {
			restrict: 'E',
			scope: {
				severity: '=severity',
				header: '@header',
				hideHeader: '=hideHeader',
				hideIcon: '=hideIcon',
				text: '@text'
			},
			controller: ['$scope', 'TranslationService',  controller],
			templateUrl: 'app/common/directives/views/plAlert.html'
		};
	});

	function controller($scope, translationService) {
		if (!$scope.icon && !$scope.hideIcon ) {
			if ($scope.severity === 'info') {
				$scope.icon = 'zmdi-info-outline';
			} else if ($scope.severity === 'danger') {
				$scope.icon = 'zmdi-alert-circle';
			} else if ($scope.severity === 'warning') {
				$scope.icon = 'zmdi-alert-triangle';
			} else if ($scope.severity === 'success') {
				$scope.icon = 'zmdi-check-circle';
			}
		}

		if (!$scope.hideHeader && !$scope.header) {
			if ($scope.severity === 'info') {
				$scope.header = translationService.translate('web-plAlert-info-header', 'Info');
			} else if ($scope.severity === 'danger') {
				$scope.header = translationService.translate('web-plAlert-danger-header', 'Fare');
			} else if ($scope.severity === 'warning') {
				$scope.header = translationService.translate('web-plAlert-warning-header', 'Advarsel');
			} else if ($scope.severity === 'success') {
				$scope.header = translationService.translate('web-plAlert-success-header', 'Vellykket');
			}
		}
	}

})();
