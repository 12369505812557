(function () {
	angular.module('Plania').controller('ArchiveListController', ['$scope', 'ngTableParams', 'ListService', 'Repository', 'TranslationService', controller]);

	function controller($scope, ngTableParams, listService, repository, translationService) {
		$scope.archiveStatusFilterList = null;
		$scope.newArchiveStatus = null;
		$scope.validNextArchiveSteps = [];

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.$watchCollection('archiveList.selectedItems', function (arrayValues) {
			var archiveSteps = _.pluck(arrayValues, 'NextValidArchiveSteps');
			var commonArchiveSteps = _.intersection.apply(null, archiveSteps);
			var uniqueCommonArchiveSteps = _.uniq(commonArchiveSteps);

			$scope.validNextArchiveSteps = uniqueCommonArchiveSteps;
		});

		$scope.$watch('archiveStatusFilterList', function (newValue, oldValue) {
			if (!newValue && !oldValue) return;
			if (newValue === oldValue) return;
			$scope.archiveList.archiveStatusFilterList = newValue;
			$scope.archiveList.clearSelectedItems();
			$scope.archiveList.table.reload();
		});

		$scope.updateArchiveDocuments = function() {
			if ($scope.archiveList.selectedItems.length === 0) return;
			var changeList = [];

			$scope.archiveList.selectedItems.forEach(function(item) {
				changeList.push(JSON.stringify({ Guid: item.Guid, ArchiveStatus: $scope.newArchiveStatus }));
			});

			repository.patch(repository.apiData.documentXEntity.url, null, changeList).then(function(result) {
				repository.growl('Arkivstatus oppdatert', 'success');
				$scope.archiveList.table.reload();
				$scope.archiveList.clearSelectedItems();
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.setArchiveStatus = function (item, archiveStatus) {
			var properties = {
				ArchiveStatus: archiveStatus
			};

			repository.patch(repository.apiData.documentXEntity.url, item.Guid, JSON.stringify(properties)).then(function (result) {
				repository.growl('Arkivstatus oppdatert', 'success');
				$scope.archiveList.table.reload();
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.archiveList = new function () {
			var me = this;
			this.apiData = repository.apiData.documentXEntity;
			this.jsPrefix = _.camelCase(this.apiData.prefix);
			this.url = this.apiData.url;
			this.selectedItems = [];
			this.allSelected = false;
			this.archiveStatusFilterList = null;

			this.initTable = function () {
				if (me.table)
					return;

				me.table = new ngTableParams({
					page: 1,
					count: 50,
					sorting: { 'UpdatedDate': 'desc' },
					filter: {}
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							var columns = [];
							params.filter().PropertyFilter = [];

							params.filter().PropertyFilter.push({
								Property: 'Document.DocumentCategory.ArchiveType',
								Operator: 'IN',
								Value: '1,2',
								Operand: 'AND'
							});

							if (me.archiveStatusFilterList && me.archiveStatusFilterList.length > 0){
								params.filter().PropertyFilter.push({
									Property: 'ArchiveStatus',
									Operator: 'IN',
									Value:  me.archiveStatusFilterList.join(','),
									Operand: 'AND'
								});
							}

							me.listSetup.Columns.forEach(function (col) {
								columns.push(col.Property);
								if (col.Filter) {
									col.Filter.forEach(function (filter) {
										params.filter().PropertyFilter.push(filter);
									});
								}
							});

							columns.push("ArchiveStatus");

							repository.GetPaginated(me.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								null,
								JSON.stringify(columns)).then(
									function (result) {
										me.table.settings().total = result.TotalCount;
										me.table.settings().filterDelay = 500;
										$defer.resolve(result.List);
									},
									function (error) {
										repository.growl(error, 'danger');
									});
						}
					});

				this.onClick = function (item, event) {
					var itemGuid = item.GuidDocument;
					$scope.navigation.go('document.edit', { guid: itemGuid, menuGuid: $scope.navigation.params.menuGuid }, event);
				};

				this.selectAllRows = function () {
					if (this.allSelected) {
						this.clearSelectedItems();
					} else {
						this.table.data.forEach(function (row) {
							if (!me.isSelected(row)) me.selectItem(row);
						});
					}
					this.allSelected = !this.allSelected;
				};

				this.clearSelectedItems = function() {
					_.remove(this.selectedItems, function () { return true; });
				};

				this.selectItem = function (item) {
					if (_.some(this.selectedItems, function (i) { return i.Guid === item.Guid; })) {
						_.remove(this.selectedItems, function (i) { return i.Guid === item.Guid; });
					} else {
						this.selectedItems.push(item);
					}
				};

				this.isSelected = function (item) {
					return _.find(this.selectedItems, function (i) { return i.Guid === item.Guid; });
				};

				this.optionsMenu = function (item) {
					var optionsMenu = [];
					item.NextValidArchiveSteps.forEach(function (archiveStep) {
						var changeStatus = translationService.translate("web-archiveList-changeTo-helpText", "Ny status:");
						var translation = changeStatus + " " + translationService.translate("web-enum-archivestatus-" + archiveStep, "");
						optionsMenu.push({
							html: '<a class="dropdown-item" href="" tabindex="-1">' + translation + '</a>',
							click: function () {
								$scope.setArchiveStatus(item, archiveStep);
							}
						});
					});
					if (optionsMenu.length === 0) {
						optionsMenu.push({
							html: '<a class="dropdown-item" href="" tabindex="-1" pl-translate="web-archiveList-noValidNextStatus">Ingen gyldige arkivstatuser å sette</a>'
						});
					}
					return optionsMenu;
				};
			};

			listService.getWebListViews(null, this.apiData.prefix, false, null, {}).then(function (result) {
				me.listOptions = result.listOptions;
				me.listSetup = result.selectedListSetup;

				$scope.archiveList.initTable();
			});
		}();
	}
})();
