(function () {
	angular.module('Plania').controller('DocumentDataAcquisitionMatrixController', ['$scope', '$rootScope', 'Repository', 'ngTableParams', 'TranslationService', 'DocumentService', '$modal', 'ListService', controller]);

	function controller($scope, $rootScope, repository, ngTableParams, translationService, documentService, $modal, listService) {
		$scope.model = {};
		$scope.viewMode = true;
		$scope.filters = { PropertyFilter: [] };

		$scope.documentSetting = {
			listSetup: null,
			listOptions: null
		};

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		// order matters!
		$scope.documentationContextColumns = {
			RegulatoryCompliance: translationService.translate('web-enum-documentationContext-regulatoryCompliance', 'RegulatoryCompliance'),
			Operation: translationService.translate('web-enum-documentationContext-operation', 'Operation'),
			Maintenance: translationService.translate('web-enum-documentationContext-maintenance', 'Maintenance'),
			Identification: translationService.translate('web-enum-documentationContext-identification', 'Identification'),
			ProductInfo: translationService.translate('web-enum-documentationContext-ProductInfo', 'ProductInfo'),
			Attestation: translationService.translate('web-enum-documentationContext-Attestation', 'Attestation'),
			Instruction: translationService.translate('web-enum-documentationContext-Instruction', 'Instruction'),
			Description: translationService.translate('web-enum-documentationContext-Description', 'Description'),
			Drawing: translationService.translate('web-enum-documentationContext-Drawing', 'Drawing'),
			Protocol: translationService.translate('web-enum-documentationContext-Protocol', 'Protocol'),
			Image: translationService.translate('web-enum-documentationContext-Image', 'Image')
		};
		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};
		function resetColumnState() {
			$scope.columnState = angular.copy($scope.documentationContextColumns);
			$scope.rowState = {}; //not filled until api data is fetched
			for(var col in $scope.columnState){
				$scope.columnState[col] = false;
			}
		}
		resetColumnState();

		$scope.columnChecked = function(column){
			return $scope.columnState[column];
		};

		$scope.toggleColumn = function(column) {
			$scope.columnState[column] = !$scope.columnState[column];
			_.forEach($scope.documentTable.data, function(row){
				row.documentationContexts[column].Checked = $scope.columnState[column];
			});
		};

		$scope.checkCells = function(row, docContext) {
			var rows = _.filter($scope.documentTable.data, function(item) {
				return row.Guid !== item.Guid && item.GuidDocument === row.GuidDocument;
			});
			_.forEach(rows, function(r){
				r.documentationContexts[docContext.Key].Checked = !r.documentationContexts[docContext.Key].Checked;
			});
		};

		$scope.rowChecked = function(row){
			return $scope.rowState[row.GuidDocument];
		};

		$scope.toggleRow = function (row){
			$scope.rowState[row.GuidDocument] = !$scope.rowState[row.GuidDocument];
			var rows = _.filter($scope.documentTable.data, function(item) {
				return item.GuidDocument === row.GuidDocument;
			});
			_.forEach(rows, function(r){
				_.forEach(r.documentationContexts, function (docContext){
					docContext.Checked = $scope.rowState[r.GuidDocument];
				});
			});

		};

		$scope.save = function(destination) {
			var changedRows = _.filter($scope.documentTable.data, function(row) {
				return _.some(row.documentationContexts, function(docContext){
					return docContext.Checked !== docContext.OriginalChecked;
				});
			});
			if (changedRows.length === 0) return;
			var changeList = [];

			_.forEach(changedRows, function (row) {
				var docContext = _.pluck(_.filter(row.documentationContexts, function(docContext){
					return docContext.Checked === true;
				}), 'Key');
				var summarizedDocContext = documentService.getDocumentationContext(docContext);
				var change = {
					Guid: row.GuidDocument,
					DocumentationContext: summarizedDocContext
				};
				changeList.push(JSON.stringify(change));
			});

			repository.patch(repository.apiData.document.url, null, changeList).then(function(result){
				repository.growl(translationService.translate('web-document-dataAcqTable-save-success', 'Informasjonstyper oppdatert'), 'success');
				if (destination)
					$scope.goBack(destination, { menuGuid: $scope.navigation.params.menuGuid });
				else
					$scope.documentTable.reload();
			}, function (error) {
				repository.showError(error);
			});
		};

		function modifyResultFunc(list) {
			$scope.rowState = {};
			resetColumnState();

			for (var i = 0; i < list.length; i++) {
				addDocumentationContexts(list[i]);
				$scope.rowState[list[i].GuidDocument] = false;
			}
		}

		listService.getWebListViews(null, repository.apiData.documentXEntity.prefix, false, planiaUtils.contextIds.dataAcquisitionDocXEntity, {}).then(function (result) {
			$scope.documentSetting.listOptions = result.listOptions;
			$scope.documentSetting.listSetup = result.selectedListSetup;

			var entityData = angular.copy(repository.apiData.documentXEntity);
			entityData.contextId =  planiaUtils.contextIds.dataAcquisitionDocXEntity;

			$scope.documentTable = listService.getNgTableParams($scope.documentSetting.listSetup, $scope.navigation, entityData, null, $scope.filters, modifyResultFunc);

		});

		$scope.$watch('documentSetting.listSetup', function (newValue, oldValue) {
			if (oldValue === newValue)
				return;

			if ($scope.documentTable) {
				$scope.documentTable.$params.sorting = $scope.documentSetting.listSetup.Sorting;

				$scope.documentTable.reload();
			}
		});
		function addDocumentationContexts(row) {
			row.documentationContexts = {};
			for (var key in $scope.documentationContextColumns) {
				row.documentationContexts[key] = {
					Key: key,
					Value: $scope.documentationContextColumns[key],
					Checked: false,
					OriginalChecked: false
				};
				var docContextChecked = row.Document.DocumentationContext.indexOf(key) > -1;
				if (docContextChecked){
					row.documentationContexts[key].Checked = true;
					row.documentationContexts[key].OriginalChecked = true;
				}
			}
		}

		$scope.equipmentTreeViewItems = new ngTableParams({
				page: 1,
				count: 20,
				sorting: {},
				filter: {}
			},
			{
				total: 0,
				counts: [20, 50, 100, 200],
				filterDelay: 10,
				paginationMaxBlocks: 8,
				getData: function ($defer, params) {

					repository.GetPaginated(repository.apiData.equipment.url, params.page() - 1, params.count(), {}, {}, null, JSON.stringify(['Id', 'Description'])).then(
						function (result) {

							$scope.equipmentTreeViewItems.settings().total = result.TotalCount;
							$scope.equipmentTreeViewItems.settings().filterDelay = 500;

							if (result.List.length === 0 && result.TotalCount > 0) {
								$scope.equipmentTreeViewItems.page(1);
							}

							$defer.resolve(result.List);
						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		$scope.menuOptions = function (docXRef) {
			var options = [];

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href=""><i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-document-view', 'Åpne dokument siden') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$rootScope.navigation.go('document.edit', { guid: docXRef.GuidDocument });
				}
			});

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href=""><i class="zmdi zmdi-eye zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-document-openDocument', 'Åpne dokument fil') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					documentService.openDocument({Guid: docXRef.GuidDocument, FilePath: docXRef.Document.FilePath});
				}
			});

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href=""><i class="zmdi zmdi-settings zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-listview-contextMenu-document-openEquipment', 'Åpne anlegg') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$rootScope.navigation.go('equipment.edit', { guid: docXRef.GuidEquipment });
				}
			});

			return options;
		};
	}
})();
