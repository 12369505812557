(function () {

	angular.module('Plania').controller('DeviationController', ['$scope', 'Repository', '$stateParams', '$modalInstance', '$modal', '$localStorage', 'TranslationService', 'Constants', '$rootScope', 'DeviationService', 'RegisteredFieldService', controller]);

	function controller($scope, repository, $stateParams, $modalInstance, $modal, $localStorage, translationService, constants, $rootScope, deviationService, registeredFieldService) {
		var isModal = $stateParams.isModal;
		$scope.isUpdate = $stateParams.guid ? true : false;

		$scope.model = {};
		$scope.modelDto = {};
		$scope.modelIsLoaded = false;
		if ($stateParams.guid) $scope.model.Guid = $stateParams.guid;
		$scope.parentEntity = $stateParams.parentEntity;
		$scope.disableEntityInput = $stateParams.disableEntityInput;

		$scope.activateAutocomplete = false;
		$scope.viewMode = (isModal || !$scope.isUpdate) ? false : true;
		$scope.filterOnBuilding = $localStorage.generalOptions.InspectedEntityLocation === constants.inspectedEntityLocation.building && $scope.parentEntity === 'WorkOrder';
		$scope.filterOnEstate = $localStorage.generalOptions.InspectedEntityLocation === constants.inspectedEntityLocation.estate && $scope.parentEntity === 'WorkOrder';
		$scope.reloadDocumentTable = false;
		$scope.isDocumentsCollapsed = true;
		$scope.defaultDocumentCategory = { Guid: repository.commonService.getFilterData().selectedDataOwner.GuidWoXEqDocumentCategory, Name: repository.commonService.getFilterData().selectedDataOwner.WoXEqDocumentCategory };

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};

		var updateAccess = function (status) {
			if (status && status === 'Closed') {
				$scope.restrictEdit = true;
				$scope.restrictDelete = true;
			}
			else if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Deviation, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Deviation, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Deviation);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Deviation);
			}
		};

		$scope.canCloseDeviation = function (guidWorkOrder) {
			if ($scope.model.Status === 'Closed') return false;

			if ($localStorage.generalOptions.CloseDeviationFromWoOnly)
				return guidWorkOrder && $scope.model.GuidWorkOrder === guidWorkOrder;
			else
				return guidWorkOrder ? $scope.model.GuidWorkOrder === guidWorkOrder : true;
		};

		$scope.closeDeviation = function () {
			swal({
				title: translationService.translate('web-swal-close-Deviation', 'Bekreft lukking av avvik'),
				text: translationService.translate('web-swal-close-Deviation-message', 'Er avviket klar til å bli lukket?'),
				type: "info",
				showCancelButton: true,
				confirmButtonColor: "#2196f3",
				confirmButtonText: translationService.translate('web-swal-deviation-button-confirm', 'Ja, Lukk avviket!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				$scope.model.RequestAction = "Close";
				repository.updateSingleDictionary(repository.apiData.deviation.url, $scope.model).then(function (result) {
					swal(translationService.translate('web-swal-deviation-success', 'Avviket ble lukket!'), '', "success");
					$scope.goBack('deviation.edit', { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
				});
				$scope.model.RequestAction = null;
			});
		};

		$scope.closeDeviationDirectly = function () {
			$modal.open({
				templateUrl: 'app/deviation/views/closeDeviationDirectlyModal.html',
				controller: 'CloseDeviationDirectlyModalController',
				resolve: {
					params: function () {
						return {
							guid: $scope.model.Guid,
							close: true
						};
					}
				},
			}).result.then(function (result) { //Ok
				getDeviation();

			});
		};

		$scope.postponeDeviation = function () {
			$modal.open({
				templateUrl: 'app/deviation/views/closeDeviationDirectlyModal.html',
				controller: 'CloseDeviationDirectlyModalController',
				resolve: {
					params: function () {
						return {
							guid: $scope.model.Guid,
							close:false
						};
					}
				},
			}).result.then(function (result) { //Ok
				getDeviation();

			});
		};

		//Prefill
		if ($stateParams.building) {
			$scope.model.Building = $stateParams.building;
			$scope.model.GuidBuilding = $stateParams.building.guid;
		}

		if ($stateParams.estate) {
			$scope.model.Estate = $stateParams.estate;
			$scope.model.GuidEstate = $stateParams.estate.guid;
		}

		if ($stateParams.area) {
			$scope.model.Area = $stateParams.area;
			$scope.model.GuidArea = $stateParams.area.guid;
		}

		if ($stateParams.equipment) {
			$scope.model.Equipment = $stateParams.equipment;
			$scope.model.GuidEquipment = $stateParams.equipment.guid;
		}

		var columns = [
			"User2.RealName", "User1.RealName", "Estate.Id", "Estate.Description", "Building.Id", "Building.Description",
            "Area.Id", "Area.Description", "Equipment.Id", "Equipment.Description",
			"DeviationType.Id", "DeviationType.Description",
			'ControlListItemAnswer.Value',
			'ControlListItemAnswer.ControlListItem.Name',
			'ControlListItemAnswer.ControlListXEntity.Guid',
			'ControlListItemAnswer.ControlListXEntity.Equipment.Id', 'ControlListItemAnswer.ControlListXEntity.Equipment.Description',
			'ControlListItemAnswer.ControlListXEntity.Area.Id', 'ControlListItemAnswer.ControlListXEntity.Area.Description',
			'ControlListItemAnswer.ControlListXEntity.ControlList.Name', 'ControlListItemAnswer.ControlListXEntity.ControlList.Id', 'ControlListItemAnswer.ControlListXEntity.ControlList.Description',

			// WorkOrder columns
			'ControlListItemAnswer.ControlListXEntity.WorkOrder.Id', 'ControlListItemAnswer.ControlListXEntity.WorkOrder.Description', 'ControlListItemAnswer.ControlListXEntity.WorkOrder.Status', 'ControlListItemAnswer.ControlListXEntity.WorkOrder.DueDate', 'ControlListItemAnswer.ControlListXEntity.WorkOrder.GuidProject', 'ControlListItemAnswer.ControlListXEntity.WorkOrder.EndDate', 'ControlListItemAnswer.ControlListXEntity.WorkOrder.GuidPeriodicTask',
			'ControlListItemAnswer.ControlListXEntity.WorkOrder.Project.Id', 'ControlListItemAnswer.ControlListXEntity.WorkOrder.Project.Id',
			"CorrectiveWorkOrder.Id", "CorrectiveWorkOrder.Description", "CorrectiveWorkOrder.Status", "CorrectiveWorkOrder.DueDate", "CorrectiveWorkOrder.GuidProject", "CorrectiveWorkOrder.EndDate", "CorrectiveWorkOrder.GuidPeriodicTask",
			"CorrectiveWorkOrder.Project.Id", "CorrectiveWorkOrder.Project.Id",
			"InspectionWorkOrder.Id", "InspectionWorkOrder.Description", "InspectionWorkOrder.Status", "InspectionWorkOrder.DueDate", "InspectionWorkOrder.GuidProject", "InspectionWorkOrder.EndDate", "InspectionWorkOrder.GuidPeriodicTask",
			"InspectionWorkOrder.Project.Id", "InspectionWorkOrder.Project.Id",
		];

		var getDeviation = function () {
			repository.getMainModel(repository.apiData.deviation.url, $scope.model.Guid, JSON.stringify(columns)).then(
				function (result) {
					$scope.model = result.Data;
					$scope.reloadDocumentTable = true;
					updateAccess($scope.model.Status);
					//setProperties();
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
					$scope.modelIsLoaded = true;
				},
				function (error) {
					repository.growl(error, 'danger');
                });
		};

		if ($scope.isUpdate) {
			getDeviation();
		} else {
			if (!isModal) {
				var selectedBuilding = repository.commonService.getFilterData().selectedBuilding;
				if (selectedBuilding.Guid) {
					$scope.model.GuidBuilding = selectedBuilding.Guid;
					$scope.model.Building = { Caption: selectedBuilding.Name };

					if (selectedBuilding.Estate) {
						$scope.model.GuidEstate = selectedBuilding.Estate.Guid;
						$scope.model.Estate = selectedBuilding.Estate;
					}
				}
			}

			var selectedEstate = repository.commonService.getFilterData().selectedEstate;
			if (selectedEstate && selectedEstate.Guid !== '') {
				$scope.model.GuidEstate = selectedEstate.Guid;
				$scope.modelDto.estate = selectedEstate.Name;
            }

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						switch (prefill.Property) {
							case 'Estate':
								$scope.model.Estate = { Caption: prefill.Value.Label } ;
								$scope.model.GuidEstate = prefill.Value.Guid;
								break;
							case 'Building':
								$scope.model.Building = { Caption: prefill.Value.Label } ;
								$scope.model.GuidBuilding = prefill.Value.Guid;
								break;
							case 'Equipment':
								$scope.model.Equipment = { Caption: prefill.Value.Label } ;
								$scope.model.GuidEquipment = prefill.Value.Guid;
								break;
							case 'Area':
								$scope.model.Area = { Caption: prefill.Value.Label } ;
								$scope.model.GuidArea = prefill.Value.Guid;
								break;
							case 'DeviationType':
								$scope.model.DeviationType = { Caption: prefill.Value.Label };
								$scope.model.GuidDeviationType = prefill.Value.Guid;
								break;
						}
					} else if (prefill.Value === '@CurrentBuilding') {
						var selectedBuilding = repository.commonService.getFilterData().selectedBuilding;
						if (selectedBuilding.Guid) {
							$scope.model.Building = { Caption: selectedBuilding.Name };
							$scope.model.GuidBuilding = selectedBuilding.Guid;
						}
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}

			$scope.activateAutocomplete = true;
			$scope.modelIsLoaded = true;
		}

		$scope.saveAction = function (action) {
			if ($scope.model.DeadlineDate)
				$scope.model.DeadlineDate = new Date($scope.model.DeadlineDate).toISOString();

			if ($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.deviation.url, $scope.model).then(function (result) {
					repository.growl(translationService.translate('web-deviation-deviationUpdated', 'Avvik oppdatert'), 'success');
					if (isModal)
						$modalInstance.close('ok');
					else {
						$scope.goBack(action, { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
						$scope.model = result;
					}
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			} else {
				$scope.model.GuidInspectionWorkOrder = $stateParams.GuidInspectionWorkOrder;
				repository.createSingleDictionary(repository.apiData.deviation.url, $scope.model)
					.then(function (result) {
						repository.growl(translationService.translate('web-deviation-deviationAdded', "Avvik har blitt lagt til"), 'success');

						if (action === 'close') {
							$modalInstance.close('ok');
						} else {
							if (!isModal) {
								$scope.viewMode = true;
								repository.commonService.setLastRegisterGuid('deviation.list', result.Guid);
								$scope.goBack(action, { guid: result.Guid, menuGuid: $scope.navigation.params.menuGuid });
							}

							$scope.model.Guid = result.Guid;
							getDeviation();
							$scope.isUpdate = true;
						}
					}).catch(function (error) {
						repository.growl(error, 'danger');
					});
			}
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};

			var estateFieldRule = registeredFieldService.getLocalRegisteredField(repository.apiData.deviation.prefix, 'GuidEstate');
			var isEstateHidden = estateFieldRule && estateFieldRule.Hidden;

			switch (filterName) {
				case "building":
					if ($scope.model.GuidEstate) {
						if (!isEstateHidden) {
							filter.PropertyFilter = [{ Property: 'GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }];
						}
					}
					return filter;
				case "area":
					filter = { GuidBuilding: $scope.model.GuidBuilding ? $scope.model.GuidBuilding : null };
					if ($scope.model.GuidEstate && !isEstateHidden) {
						filter.PropertyFilter = [{ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }];
					}
					return filter;
				case "equipment":
					filter = { GuidBuilding: $scope.model.GuidBuilding, hasConditionControl: true, PropertyFilter: [] };
					if ($scope.model.GuidEstate && !$scope.model.GuidBuilding && !isEstateHidden) {
						filter.PropertyFilter = [{ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }];
					}
					if ($localStorage.generalOptions.FilterDeviationByEqPrefix && $scope.model.GuidDeviationType) {
						var id = $scope.model.DeviationType.Caption.substr(0, 3).trim();
						filter.PropertyFilter.push({ Property: 'Id', Operator: 'StartsWith', Value: id });
					}
					filter.PropertyFilter.push({ Property: 'IsTemplate', Operator: '=', Value: false });
					return filter;
				case "deviationType":
					if ($localStorage.generalOptions.FilterDeviationByEqPrefix && $scope.model.GuidEquipment) {
						return { PropertyFilter: [{ Property: 'Id', Operator: 'StartsWith', Value: $scope.model.Equipment.Caption.substr(0, 3).trim() }] };
					}
					return {};
			}
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		$scope.onEstateSelect = function (estate) {
			if (!estate) {
				$scope.model.GuidEstate = "";
				return;
			}

			if ($scope.model.GuidEstate !== estate.Guid) {
				$scope.model.GuidBuilding = "";
				$scope.model.Building = null;
				$scope.model.GuidEquipment = "";
				$scope.model.Equipment = null;
				$scope.model.GuidArea = "";
				$scope.model.Area = null;
			}

			$scope.model.GuidEstate = estate.Guid;
		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}

			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidEquipment = "";
				$scope.model.Equipment = null;
				$scope.model.GuidArea = "";
				$scope.model.Area = null;
			}

			$scope.model.GuidBuilding = building.Guid;

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "") {
				$scope.model.GuidEstate = building.GuidEstate;
				$scope.model.Estate = building.Estate;
			}
		};

		$scope.onAreaSelect = function (area) {
			if (!area) {
				$scope.model.GuidArea = "";
				return;
			}

			$scope.model.GuidArea = area.Guid;

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = area.GuidBuilding;
				$scope.model.Building = area.Building;
			}

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "") {
				$scope.model.GuidEstate = area.Building.Estate.Guid;
				$scope.model.Estate = area.Building.Estate;
			}
		};

		$scope.onEquipmentSelect = function (equipment) {
			if (!equipment) {
				$scope.model.GuidEquipment = "";
				return;
			}

			$scope.model.GuidEquipment = equipment.Guid;

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "") {
				$scope.model.GuidEstate = equipment.Building.Estate.Guid;
				$scope.model.Estate = equipment.Building.Estate;
			}

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = equipment.GuidBuilding;
				$scope.model.Building = equipment.Building;
			}

			if (equipment.GuidArea) {
				$scope.model.GuidArea = equipment.GuidArea;
				$scope.model.Area = equipment.Area;
			}
		};

		$scope.onDeviationTypeSelect = function (deviationType) {
			if (!deviationType) {
				$scope.model.GuidDeviationType = "";
				$scope.model.DeviationType = null;
				return;
			}

			$scope.model.GuidDeviationType = deviationType.Guid;
		};

		$scope.openControlList = function () {
			$modal.open({
				templateUrl: 'app/controlList/views/controlListCompletionModal.html',
				size: 'lg',
				controller: 'ControlListCompletionModalController',
				resolve: {
					params: function () {
						return {
							workOrderCaption: $scope.model.ControlListItemAnswer.ControlListXEntity.WorkOrder.Caption,
							controlList: $scope.model.ControlListItemAnswer.ControlListXEntity.ControlList,
							controlListXEntity: $scope.model.ControlListItemAnswer.ControlListXEntity,
							restrictEdit: true
						};
					}
				}
			}).result.then(function (result) {
			}, function () {
				//cancel
			});
        };

        $scope.createWorkOrder = function () {
	        deviationService.createWorkOrder($scope.model);
		};

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			if (icon)
				htmlText += '<i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.originContextMenu = function () {
			var options = [];

			if ($scope.hasReadAccess('ControlListXEntity') && $scope.model.GuidControlListItemAnswer) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-deviation-originContextMenu-viewInspectionControlListXEntity', 'Åpne kontrollisten') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.openControlList();
					}
				});
			}

			if ($scope.hasReadAccess('WorkOrder') && $scope.model.GuidInspectionWorkOrder && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: $scope.model.GuidInspectionWorkOrder }), translationService.translate('web-deviation-originContextMenu-viewInspectionWorkOrder', 'Gå til arbeidsordre')));
			if ($scope.hasReadAccess('Project') && $scope.model.GuidInspectionWorkOrder && $scope.model.InspectionWorkOrder && $scope.model.InspectionWorkOrder.GuidProject && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('project.edit', { guid: $scope.model.InspectionWorkOrder.GuidProject }), translationService.translate('web-deviation-originContextMenu-viewInspectionProject', 'Gå til prosjekt')));

			if ($scope.hasReadAccess('PeriodicTask') && $scope.model.GuidInspectionWorkOrder && $scope.model.InspectionWorkOrder && $scope.model.InspectionWorkOrder.GuidPeriodicTask && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: $scope.model.InspectionWorkOrder.GuidPeriodicTask }), translationService.translate('web-deviation-originContextMenu-viewInspectionPeriodicTask', 'Gå til periodisk rutine')));

			if (options.length === 0) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#">' + translationService.translate('web-deviation-originContextMenu-empty', 'Ingen aksjoner tilgjengelig') + '</a>',
					enabled: false
				});
			}

			return options;
		};

		$scope.followUpContextMenu = function () {
			var options = [];

			if ($scope.hasEditAccess('Deviation') && $scope.model.GuidCorrectiveWorkOrder && $scope.model.CorrectiveWorkOrder && !$scope.model.CorrectiveWorkOrder.EndDate && $scope.canCloseDeviation($scope.model.GuidCorrectiveWorkOrder)) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-deviation-followUpContextMenu-closeDeviationFromCorrective', 'Lukk avviket via') + ' ' + $scope.model.CorrectiveWorkOrder.Caption + '</a >',
					click: function () {
						$scope.closeDeviation();
					}
				});
			}

			if ($scope.hasEditAccess('Deviation') && !$scope.model.GuidCorrectiveWorkOrder && $scope.model.GuidInspectionWorkOrder && $scope.model.InspectionWorkOrder && !$scope.model.InspectionWorkOrder.EndDate && $scope.canCloseDeviation($scope.model.GuidInspectionWorkOrder)) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-deviation-followUpContextMenu-closeDeviationFromInspection', 'Lukk avviket via') + ' ' + $scope.model.InspectionWorkOrder.Caption + '</a >',
					click: function () {
						$scope.closeDeviation();
					}
				});
			}

			if ($scope.hasEditAccess('Deviation') && !$scope.model.GuidCorrectiveWorkOrder && !$scope.model.GuidInspectionWorkOrder && $scope.canCloseDeviation()) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-deviation-followUpContextMenu-closeDeviationFromDirectly', 'Lukk avviket direkte uten arbeidsordre')+ '</a >',
					click: function () {
						$scope.closeDeviation();
					}
				});
			}

			if ($scope.hasReadAccess('WorkOrder') && $scope.model.GuidCorrectiveWorkOrder && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: $scope.model.GuidCorrectiveWorkOrder }), translationService.translate('web-deviation-followUpContextMenu-viewCorrectiveWorkOrder', 'Gå til arbeidsordre')));

			if ($scope.hasReadAccess('Project') && $scope.model.GuidCorrectiveWorkOrder && $scope.model.CorrectiveWorkOrder && $scope.model.CorrectiveWorkOrder.GuidProject && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('project.edit', { guid: $scope.model.CorrectiveWorkOrder.GuidProject }), translationService.translate('web-deviation-followUpContextMenu-viewCorrectiveProject', 'Gå til prosjekt')));

			if ($scope.hasReadAccess('PeriodicTask') && $scope.model.GuidCorrectiveWorkOrder && $scope.model.CorrectiveWorkOrder && $scope.model.CorrectiveWorkOrder.GuidPeriodicTask && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: $scope.model.CorrectiveWorkOrder.GuidPeriodicTask }), translationService.translate('web-deviation-followUpContextMenu-viewCorrectivePeriodicTask', 'Gå til periodisk rutine')));

			if ($scope.hasEditAccess('Deviation') && $scope.model.Status === 'InWorks' && $scope.model.GuidCorrectiveWorkOrder && !$scope.model.CorrectiveWorkOrder.EndDate) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-deviation-followUpContextMenu-removeFromWorkOrder', 'Koble fra arbeidsordre') + '</a >',
					click: function () {
						swal({
							title: translationService.translate('web-deviation-followUpContextMenu-removeFromWorkOrder-swalTitle', 'Bekreft frakobling av avvik'),
							text: translationService.translate('web-deviation-followUpContextMenu-removeFromWorkOrder-swalMessage', 'Er du sikker på at du vil koble dette avviket ifra arbeidsordren?'),
							type: "warning",
							showCancelButton: true,
							confirmButtonColor: "#f44336",
							confirmButtonText: translationService.translate('web-deviation-followUpContextMenu-removeFromWorkOrder-swalConfirm', 'Ja, koble fra!'),
							cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
							closeOnConfirm: false,
							showLoaderOnConfirm: true
						}, function () {
							window.onkeydown = null;
							window.onfocus = null;
							$scope.model.RequestAction = "ResetCorrectiveWorkorder";
							repository.updateSingleDictionary(repository.apiData.deviation.url, $scope.model).then(function (result) {
								swal(translationService.translate('web-deviation-followUpContextMenu-removeFromWorkOrder-success', 'Avviket ble frakoblet arbeidsordren'), result.Caption, "success");
								$scope.goBack('deviation.edit', { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
							}, function (error) {
								swal('Error', error, "error");
							});
						});
					}
				});
			}

			if ($scope.hasCreateAccess('WorkOrder') && $scope.model.Status !== 'Closed' && !$scope.model.GuidCorrectiveWorkOrder) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-deviation-followUpContextMenu-createWorkOrder', 'Opprett ny arbeidsordre for utbedring av avvik') + '</a >',
					click: function () {
						$scope.createWorkOrder();
					}
				});
			}

			if ($scope.hasReadAccess('WorkOrder') && $scope.model.Status !== 'Closed' && $scope.hasEditAccess('Deviation') && !$scope.model.GuidCorrectiveWorkOrder) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-deviation-followUpContextMenu-addToExistingWo', 'Koble til eksisterende arbeidsordre') + '</a >',
					click: function () {
						$modal.open({
							templateUrl: 'app/common/views/singleSelectModal.html',
							controller: 'SingleSelectModalController',
							size: 'lg',
							resolve: {
								modalParams: function () {
									return {
										title: translationService.translate('web-deviation-followUpContextMenu-addToExistingWo-modalTitle', 'Velg arbeidsordre avviket skal kobles til'),
										url: repository.apiData.workOrder.url,
										sorting: { 'Id': 'desc' },
										filter: { PropertyFilter: [{ Property: 'EndDate', Operator: '=', Value: null }] },
										columns: [
											{ title: translationService.translate('web-workOrder-Id', 'Ao Nr'), property: 'Id' },
											{ title: translationService.translate('web-workOrder-Description', 'Beskrivelse'), property: 'Description', PropertyType: 'String' },
											{ title: translationService.translate('web-workOrder-Building-Id', 'Bygg Id'), property: 'Building.Id' },
											{ title: translationService.translate('web-workOrder-Building-Description', 'Byggbeskrivelse'), property: 'Building.Description', PropertyType: 'String' },
										]
									};
								}
							}
						}).result.then(function (selectedEntity) {
							$scope.model.GuidCorrectiveWorkOrder = selectedEntity.Guid;
							$scope.saveAction('deviation.edit');
						});
					}
				});
			}

			if ($scope.model.Status !== 'Closed' && $scope.hasEditAccess('Deviation') && !$scope.model.GuidCorrectiveWorkOrder) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-deviation-followUpContextMenu-closeDeviationDirectly', 'Lukk avvik direkte') + '</a >',
					click: function () {
						$scope.closeDeviationDirectly();
					}
				});
			}

			if ($scope.hasEditAccess('Deviation') && ($scope.model.Status === 'New' || $scope.model.Status === 'InWorks')) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-deviation-followUpContextMenu-postponeDeviation', 'Utsett avviket') + '</a >',
					click: function () {
						$scope.postponeDeviation();
					}
				});
			}

			if (options.length === 0) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#">' + translationService.translate('web-deviation-followUpContextMenu-empty', 'Ingen aksjoner tilgjengelig') + '</a>',
					enabled: false
				});
			}
			return options;
		};
	}
})();
