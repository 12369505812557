(function () {
	var app = angular.module('Plania');
	app.directive('controlListConnectionsDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate',
				activateAutocomplete: '=activateAutocomplete',
				currentTab: '=currentTab',
				conditionColumns: '=conditionColumns'
			},
			controller: ['$scope', 'ngTableParams', 'Repository', 'TranslationService', '$modal', '$rootScope', 'ListService', '$timeout', controller],
			templateUrl: 'app/controlList/views/editControlListViews/controlListConnections.html',
		};
	});

	function controller($scope, ngTableParams, repository, translationService, $modal, $rootScope, listService, $timeout) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.rootScope = $rootScope;
		$scope.hasModule = $rootScope.hasModule;
		$scope.controlListXEntitySearch = {
			focus: false,
			toggle: false
		};

		$scope.hasPageLoaded = $scope.currentTab === 'connections';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'connections' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						pageListener();
					}
				}, 250);
			});
		}

		$scope.testingCondition = false;

		var filter = {
			PropertyFilter: [
				{ Property: 'GuidPeriodicTask', Operator: '<>', Value: null },
				{ Property: "GuidControlList", Operator: "=", Value: $scope.model.Guid },
				{ Property: 'PeriodicTask.IsDeactivated', Operator: '=', Value: false }
			]
		};

		$scope.controlListXEntityColumns = [
			{ Position: 1, Title: "Periodisk rutine", Property: "PeriodicTask.Id", PropertyType: "string", isCaption: true },
			{ Position: 2, Title: "Eiendom", Property: "PeriodicTask.Estate.Id", PropertyType: "string", isCaption: true },
			{ Position: 3, Title: "Bygg", Property: "PeriodicTask.Building.Id", PropertyType: "string", isCaption: true },
			{ Position: 4, Title: "Tilknyttet objekt", PropertyType: "string", isComplex: true, SortingDisabled: true },
			{ Position: 5, Title: "Forfallsdato", Property: "PeriodicTask.DueDate", PropertyType: "date" },
			{ Position: 6, Title: "Sist utført", Property: "PeriodicTask.LastExecutedDate", PropertyType: "date" },
		];

		if ($scope.isUpdate) {
			$scope.controlListXEntityTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'PeriodicTask.Id': 'desc' },
				filter: filter
			}, {
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				getData: function ($defer, params) {
					var columns = [];
					$scope.controlListXEntityColumns.forEach(function (col) {
						if (col.Property)
							columns.push(col.Property);
					});

					columns.push("PeriodicTask.Description");
					columns.push("PeriodicTask.GuidBuilding");
					columns.push("PeriodicTask.GuidEstate");
					columns.push("Equipment.Id");
					columns.push("Equipment.Description");
					columns.push("Area.Id");
					columns.push("Area.Description");
					columns.push("Area.IsHousing");
					columns.push("PeriodicTask.Building.Description");
					columns.push("PeriodicTask.Estate.Description");

					repository.GetPaginated(repository.apiData.controlListXEntity.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns)).then(function (result) {
						$scope.controlListXEntityTable.settings().total = result.TotalCount;
						$scope.controlListXEntityTable.settings().filterDelay = 500;
						$defer.resolve(result.List);
					}, function (error) {
						repository.growl(error, 'danger');
					});
				}
			});
		}

		var getEntityIcon = function (entity) {
			if (entity.Equipment) return 'zmdi-settings c-bluegray';
			if (entity.Area) return 'zmdi-layers c-lightblue';
		};

		var getLowestEntityCaption = function (entity) {
			if (entity.Equipment) return entity.Equipment.Caption;
			if (entity.Area) return entity.Area.Caption;

			if (entity.PeriodicTask) {
				return translationService.translate('web-controlListXEntity-directConnectionPeriodicTask', 'Direkte tilkoblet rutine');
			} else {
				return translationService.translate('web-controlListXEntity-directConnectionWorkOrder', 'Direkte tilkoblet arbeidsordre');
			}
		};

		$scope.getPropertyValue = function (item, column) {
			if (column.isComplex) {
				var icon = '<i class="zmdi zmdi-hc-lg ' + getEntityIcon(item) + '"></i> ';
				var span = '<span>' + getLowestEntityCaption(item) + '</span>';
				return '<span>' + icon + span + '</span>';
			}

			if (column.isCaption) {
				var newColumn = {
					Position: column.Position,
					Property: column.Property.replace(".Id", ".Caption"),
					PropertyType: column.PropertyType,
					Title: column.Title
				};
				return listService.GetPropertyValue(item, newColumn);
			}

			return listService.GetPropertyValue(item, column);
		};

		$scope.openPeriodicModal = function () {
			if ($scope.model.VersionStatus !== 'Published') return;

			if (!$scope.model.UseWithArea && !$scope.model.UseWithEquipment && !$scope.model.UseWithWorkOrder) {
				repository.growl(translationService.translate('web-controlList-useWith-error', 'Kan ikke koble periodiske rutiner fordi kontrollisten er satt opp til å ikke kunne kobles opp til arbeidsordre, anlegg eller areal'), 'danger');
				return;
			}

			$modal.open({
				templateUrl: 'app/controlList/views/controlListPeriodicTaskModal.html',
				controller: 'ControlListPeriodicTaskModalController',
				size: 'xl',
				resolve: {
					modalParams: function () {
						return {
							guid: $scope.model.Guid,
							canConnect: {
								UseWithArea: $scope.model.UseWithArea,
								UseWithEquipment: $scope.model.UseWithEquipment,
								UseWithWorkOrder: $scope.model.UseWithWorkOrder
							}
						};
					}
				}
			}).result.then(function () {
				$scope.controlListXEntityTable.reload();
			});
		};

		$scope.removePeriodicTaskConnection = function (controlListXEntity) {
			if ($scope.model.VersionStatus !== 'Draft') return;

			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-controlListXEntity-message', "Knytningen mellom kontrollisten og den periodiske rutinen vil bli fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-controlListXEntity-button-confirm', 'Ja, fjern knytning'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.deleteSingle(repository.apiData.controlListXEntity.url, controlListXEntity.Guid).then(function (result) {
					swal(translationService.translate('web-swal-controlListXEntity-success', 'Knytningen ble fjernet!'), result, "success");
					$scope.controlListXEntityTable.reload();
				}, function (error) {
					swal('Error', error, "error");
				});
			});
		};

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			// Show icon, else draw a random icon to keep same padding on left.
			if (icon)
				htmlText += '<i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.controlListXEntityContextMenuOptions = function (item) {
			var options = [];

			if (item.GuidPeriodicTask && $scope.hasReadAccess("PeriodicTask")) {
				options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: item.GuidPeriodicTask }), translationService.translate('web-controlList-connectionsTab-controlListXEntityContextMenuOptions-periodicTask-view', 'Åpne periodisk rutine')));

				if (item.PeriodicTask) {
					if (item.PeriodicTask.GuidEstate && $scope.hasReadAccess("Estate"))
						options.push(getMenuLink($rootScope.navigation.href('estate.edit', { guid: item.PeriodicTask.GuidEstate }), translationService.translate('web-controlList-connectionsTab-controlListXEntityContextMenuOptions-estate-view', 'Åpne eiendom')));
					if (item.PeriodicTask.GuidBuilding && $scope.hasReadAccess("Building"))
						options.push(getMenuLink($rootScope.navigation.href('building.edit', { guid: item.PeriodicTask.GuidBuilding }), translationService.translate('web-controlList-connectionsTab-controlListXEntityContextMenuOptions-building-view', 'Åpne bygg')));
				}
			}

			if ($scope.hasReadAccess("Area") && item.GuidArea) {
				if (item.Area.IsHousing)
					options.push(getMenuLink($rootScope.navigation.href('housing.edit', { guid: item.GuidArea }), translationService.translate('web-controlList-connectionsTab-controlListXEntityContextMenuOptions-housing-view', 'Åpne bolig')));
				else
					options.push(getMenuLink($rootScope.navigation.href('area.edit', { guid: item.GuidArea }), translationService.translate('web-controlList-connectionsTab-controlListXEntityContextMenuOptions-area-view', 'Åpne rom')));
			}

			if ($scope.hasReadAccess("Equipment") && item.GuidEquipment) {
				options.push(getMenuLink($rootScope.navigation.href('equipment.edit', { guid: item.GuidEquipment }), translationService.translate('web-controlList-connectionsTab-controlListXEntityContextMenuOptions-equipment-view', 'Åpne anlegg')));
			}
			
			if ($scope.hasDeleteAccess("ControlListXEntity") && $scope.model.VersionStatus === 'Draft') {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-controlList-connectionsTab-controlListXEntityContextMenuOptions-removePeriodicTaskConnection', 'Fjern knytning') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.removePeriodicTaskConnection(item);
					}
				});
			}

			return options;
		};

		$scope.addNewColumn = function () {
			if ($scope.model.VersionStatus !== 'Draft') return;

			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/addNewListColumnModal.html',
				controller: 'AddNewListColumnController',
				resolve: {
					params: function () {
						return {
							prefix: repository.apiData.workOrder.prefix,
							showAdvancedMode: true
						};
					}
				}
			});

			modalInstance.result.then(function (selectedColumns) {
				selectedColumns.forEach(function (column) {
					column.Filter = [{ Operator: '=', Property: column.Property }];
					$scope.conditionColumns.push(column);
				});
			});
		};

		$scope.testConditions = function () {
			if ($scope.model.VersionStatus !== 'Draft') return;

			if (!$scope.conditionColumns || $scope.conditionColumns.length === 0 || $scope.testingCondition) return;

			var kpi = {
				Prefix: repository.apiData.workOrder.prefix,
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				DateProperty: null,
				IncludePeriodicTask: false,
				Interval: null,
				PropertyFilter: [],
				Properties: [{ Property: 'Guid', Function: 'count' }]
			};

			$scope.conditionColumns.forEach(function (col) {
				if (col.Filter) {
					kpi.PropertyFilter.push(angular.copy(col.Filter[0]));
				}
			});

			$scope.testingCondition = true;
			repository.createSingle(repository.apiData.kpi.url, kpi).then(function (result) {
				if (result && result[0] && result[0].Result && result[0].Result[0]) {
					var res = result[0].Result[0];
					repository.growl(res.Value + " " + translationService.translate('web-controlList-conditions-testConditions-result', 'arbeidsordre oppfylte betingelsene'), 'info');
				}
				$scope.testingCondition = false;
			}, function (error) {
				repository.growl(error, 'danger');
				$scope.testingCondition = false;
			});
		};
	}
})();
