(function () {
	angular.module('Plania').controller('ConditionController', ['$scope', 'Repository', '$stateParams', '$modalInstance', '$localStorage', 'TranslationService', '$modal', '$rootScope', 'ngTableParams', 'ListService', 'Constants', '$q', 'RegisteredFieldService', controller]);
	function controller($scope, repository, $stateParams, $modalInstance, $localStorage, translationService, $modal, $rootScope, ngTableParams, listService, constants, $q, registeredFieldService) {
		var isModal = $stateParams.isModal;
		$scope.isEdit = $stateParams.guid ? true : false;
		$scope.viewMode = (isModal || !$scope.isEdit) ? false : true;
		$scope.model = {
			Guid: $stateParams.guid
		};

		if (isModal && $stateParams.model)
			$scope.model = $stateParams.model;

		$scope.modelIsLoaded = false;
		$scope.reloadDocumentTable = false;
		$scope.activateAutocomplete = false;
		$scope.reload = false;
		$scope.isDocumentsCollapsed = true;

		$scope.useNewCorrectiveAction = $localStorage.generalOptions.UseNewCorrectiveAction;

		$scope.defaultDocumentCategory = {
			Guid: repository.commonService.getFilterData().selectedDataOwner.GuidWoXEqDocumentCategory,
			Name: repository.commonService.getFilterData().selectedDataOwner.WoXEqDocumentCategory
		};

		$scope.year = {
			range: function () {
				var range = [];
				var startYear = $localStorage.generalOptions.YearRangeStart;
				var year = new Date().getFullYear();
				if (startYear === 0) startYear = year - 10;
				var endYear = $localStorage.generalOptions.YearRangeEnd;
				if (endYear === 0) endYear = year + 10;

				for (var i = startYear; i <= endYear; i++)
					range.push(i);
				return range;
			}()
		};

		$scope.dropdown = {
			ConditionType: { Description: "Ikke valgt", Guid: null },
			Consequence: { Id: "Ikke valgt", Guid: null },
			ConsequenceType: { Id: "Ikke valgt", Guid: null },
			ConditionTypes: [{ Description: "Ikke valgt", Guid: null }],
			Consequences: [{ Id: "Ikke valgt", Guid: null }],
			ConsequenceTypes: [{ Id: "Ikke valgt", Guid: null }],
			EstimateDateYear: null
		};

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		var updateAccess = function () {
			if ($scope.isEdit) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Condition, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Condition, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Condition);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Condition);
			}
		};
		updateAccess();

		if (isModal && !$scope.isEdit) {
			$scope.canChangeMaintenanceObject = {
				estate: false,
				building: false
			};

			switch ($localStorage.generalOptions.InspectedEntityLocation) {
				case constants.inspectedEntityLocation.anywhere:
					$scope.canChangeMaintenanceObject = {
						estate: true,
						building: true
					};
					break;
				case constants.inspectedEntityLocation.estate:
					$scope.canChangeMaintenanceObject = {
						estate: !$scope.model.GuidEstate,
						building: true
					};
					break;
				case constants.inspectedEntityLocation.building:
					$scope.canChangeMaintenanceObject = {
						estate: !$scope.model.GuidBuilding,
						building: !$scope.model.GuidBuilding
					};
					break;
				default:
			}
		}

		if (!isModal && !$scope.isEdit) {
			$scope.steps = [
				{ stepNumber: 1, name: 'chooseConditionType', title: translationService.translate('web-condition-section-title-type', 'Velg type') },
				{ stepNumber: 2, name: 'basicInfo', title: translationService.translate('web-condition-section-title-basicInformation', 'Basisinformasjon') },
			];

			$scope.currentStep = $scope.steps[0].name;

			$scope.getCurrentStepNumber = function () {
				return _.find($scope.steps, { name: $scope.currentStep }).stepNumber;
			};

			$scope.isStepDisabled = function (step) {
				if (_.find($scope.steps, { name: $scope.currentStep }).stepNumber <= step.stepNumber) {
					return true;
				}
				return false;
			};

			$scope.isLastStep = function () {
				return $scope.currentStep === $scope.steps[$scope.steps.length - 1].name;
			};

			$scope.setConditionType = function (conditionType) {
				$scope.model.Type = conditionType;
				$scope.currentStep = 'basicInfo';

				if ($scope.model.Type === 'CurrentConditionInfo')
					$scope.model.ConditionDate = new Date().toISOString();

				if ($scope.model.Type === 'CorrectiveAction') {
					$scope.setCorrectiveActionType();
				}
			};
		} else if (isModal) {
			$scope.activeTab = {
				Condition: true,
				CorrectiveAction: false,
				Document: false
			};

			$scope.selectTab = function (action) {
				for (var key in $scope.activeTab) {
					if ($scope.activeTab.hasOwnProperty(key))
						$scope.activeTab[key] = false;
				}
				$scope.activeTab[action] = true;
			};
		}

		$scope.canCloseCorrectiveAction = function (condition, guidWorkOrder) {
			if (isModal) {
				return $stateParams.canCloseCorrectiveAction(condition);
			} else {
				return !$scope.model.EndDate &&
					$scope.model.GuidWorkOrder &&
					$scope.model.GuidWorkOrder === guidWorkOrder &&
					$scope.model.Type === 'CorrectiveAction';
			}
		};

		$scope.closeCorrectiveAction = function (condition) {
			if (isModal) {
				$stateParams.closeCorrectiveAction(condition).then(function (result) {
					if (isModal) {
						$modalInstance.close('ok');
					}
				});
			} else {
				swal({
					title: translationService.translate('web-condition-closeCorrectiveAction-swalTitle', 'Bekreft lukking av tiltak'),
					text: translationService.translate('web-condition-closeCorrectiveAction-swalMessage', 'Er du sikker på at du vil lukke tiltaket?'),
					type: "info",
					showCancelButton: true,
					confirmButtonColor: "#2196f3",
					confirmButtonText: translationService.translate('web-condition-closeCorrectiveAction-swalConfirm', 'Ja, Lukk tiltaket!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					$scope.model.RequestAction = "Close";
					repository.updateSingleDictionary(repository.apiData.condition.url, $scope.model).then(function (result) {
						swal(translationService.translate('web-condition-closeCorrectiveAction-swalSuccess', 'Tiltaket ble lukket!'), result.Id, "success");
						$scope.goBack('condition.edit', { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
					}, function (error) {
						swal(translationService.translate('web-error', "Feil"), error, "error");
					});
					$scope.model.RequestAction = null;
				});
			}
		};

		$scope.autoCompleteFilter = function (filterName) {
			var estateFieldRule = registeredFieldService.getLocalRegisteredField(repository.apiData.condition.prefix, 'GuidEstate');
			var isEstateHidden = estateFieldRule && estateFieldRule.Hidden;

			switch (filterName) {
				case "estate":
                    return {};
				case "building":
					if (!isEstateHidden && $scope.model.GuidEstate) {
                        return { PropertyFilter: [{ Property: 'GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }] };
					}
					return {};
				case "area":
					if ($scope.model.GuidBuilding) {
                        return { PropertyFilter: [{ Property: 'GuidBuilding', Operator: '=', Value: $scope.model.GuidBuilding }] };
					} else if (!isEstateHidden && $scope.model.GuidEstate) {
                        return { PropertyFilter: [{ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }] };
					}
					return {};
				case "equipment":
					var eqFilter = {
						HasConditionControl: true,
						PropertyFilter: [{ Property: 'IsTemplate', Operator: '=', Value: false }]
					};

					if ($scope.model.GuidBuilding) {
						eqFilter.GuidBuilding = $scope.model.GuidBuilding;
					} else if (!isEstateHidden && $scope.model.GuidEstate) {
						eqFilter.PropertyFilter.push({ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.model.GuidEstate });
					}

					return eqFilter;
                case "priority":
					return { PropertyFilter: [{ Property: 'EntityType', Operator: '=', Value: 'Condition' }] };
				case "deviation":
					return {};
				default:
                    return {};
			}
		};

		$scope.setCorrectiveActionType = function () {
			$scope.model.Type = 'CorrectiveAction';

			$scope.dropdown.EstimateDateYear = new Date().getFullYear();
			$scope.model.EstimateDate = new Date($scope.dropdown.EstimateDateYear.toString()).toISOString();
			$scope.model.Quantity = 1;
			$scope.model.Unit = "Stk";

			if (isModal) {
				$scope.selectTab('CorrectiveAction');
				$scope.activeTabIndex = 1;
			}
		};

		$scope.cancelCorrectiveAction = function() {
			$scope.model.IsCancelled = true;
			$scope.saveAction('condition.edit');
		};

		var getDropdownData = function () {
			var conditionTypeColumns = ['Description', 'LikertScale'];
			repository.GetPaginated(repository.apiData.conditionType.url, 0, -1, { 'Description': 'asc' }, {}, null, JSON.stringify(conditionTypeColumns)).then(function (result) {
				$scope.dropdown.ConditionTypes = [{ Description: "Ikke valgt", Guid: null }].concat(result.List);

				if ($scope.model.GuidConditionType) {
					var conditionType = _.filter($scope.dropdown.ConditionTypes, { Guid: $scope.model.GuidConditionType });
					if (conditionType && conditionType[0])
						$scope.dropdown.ConditionType = conditionType[0];
				}
			});

			var consequenceColumns = ['Id', 'LikertScale'];
			repository.GetPaginated(repository.apiData.consequence.url, 0, -1, { 'Id': 'asc' }, {}, null, JSON.stringify(consequenceColumns)).then(function (result) {
				$scope.dropdown.Consequences = [{ Id: "Ikke valgt", Guid: null }].concat(result.List);
				if ($scope.model.GuidConsequence) {
					var consequence = _.filter($scope.dropdown.Consequences, { Guid: $scope.model.GuidConsequence });
					if (consequence && consequence[0])
						$scope.dropdown.Consequence = consequence[0];
				}
			});

			var consequenceTypeColumns = ['Id'];
			repository.GetPaginated(repository.apiData.consequenceType.url, 0, -1, { 'Id': 'asc' }, {}, null, JSON.stringify(consequenceTypeColumns)).then(function (result) {
				$scope.dropdown.ConsequenceTypes = [{ Id: "Ikke valgt", Guid: null }].concat(result.List);
				if ($scope.model.GuidConsequenceType) {
					var consequenceType = _.filter($scope.dropdown.ConsequenceTypes, { Guid: $scope.model.GuidConsequenceType });
					if (consequenceType && consequenceType[0])
						$scope.dropdown.ConsequenceType = consequenceType[0];
				}
			});
		};

		var refreshDropdownDataBinding = function () {
			if ($scope.dropdown.ConditionTypes && $scope.model.GuidConditionType) {
				var conditionType = _.filter($scope.dropdown.ConditionTypes, { Guid: $scope.model.GuidConditionType });
				if (conditionType && conditionType[0])
					$scope.dropdown.ConditionType = conditionType[0];
			}

			if ($scope.dropdown.Consequences && $scope.model.GuidConsequence) {
				var consequence = _.filter($scope.dropdown.Consequences, { Guid: $scope.model.GuidConsequence });
				if (consequence && consequence[0])
					$scope.dropdown.Consequence = consequence[0];
			}

			if ($scope.dropdown.ConsequenceTypes && $scope.model.GuidConsequenceType) {
				var consequenceType = _.filter($scope.dropdown.ConsequenceTypes, { Guid: $scope.model.GuidConsequenceType });
				if (consequenceType && consequenceType[0])
					$scope.dropdown.ConsequenceType = consequenceType[0];
			}

			if ($scope.model.EstimateDate) {
				$scope.dropdown.EstimateDateYear = new Date($scope.model.EstimateDate).getFullYear();
			}
		};

		getDropdownData();

		$scope.versionTableColumns = [
			{ Position: 1, Title: translationService.translate('web-condition-versionTable-version', 'Nr'), Property: 'Version', PropertyType: 'number' },
			{ Position: 2, Title: translationService.translate('web-condition-versionTable-conditionType', 'Tilstand'), Property: 'ConditionType.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-condition-versionTable-year', 'Tiltaksår'), Property: 'Year', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-condition-versionTable-estimate', 'Overslag'), Property: 'Estimate', PropertyType: 'double' },
			{ Position: 5, Title: translationService.translate('web-condition-versionTable-confirmed', 'Bekreftet'), Property: 'ConfirmedDate', PropertyType: 'date' },
			{ Position: 6, Title: translationService.translate('web-condition-versionTable-status', 'Status'), Property: 'Status', PropertyType: 'enum.DeviationStatus' },
		];

		$scope.isVersionTableLoading = false;

		$scope.confirmVersion = function () {
			if (!$scope.useNewCorrectiveAction) return;

			formatModelBeforeSave($scope.model);
			repository.updateSingleDictionary(repository.apiData.condition.url, $scope.model).then(function (response) {
				$modal.open({
					templateUrl: 'app/condition/views/conditionConfirmVersionModal.html',
					controller: 'ConditionConfirmVersionModalController',
					size: 'md',
					resolve: {
						modalParams: function () {
							return {
								guids: [response.Guid],
								conditionDate: response.ConditionDate,
								type: response.Type,
								guidConditionType: response.GuidConditionType,
							};
						}
					}
				}).result.then(function () {
					$scope.goBack('condition.edit', { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
				});
			}, function (error) {
				repository.showError(error);
			});
		};

		$scope.goToVersion = function (guid) {
			if (!guid || guid === $scope.model.Guid) return;
			window.open($rootScope.navigation.href('condition.edit', { guid: guid }), "_blank");
		};

		var getVersionTable = function () {
			if ($scope.versionTable || !$scope.useNewCorrectiveAction) return;
			$scope.versionTable = new ngTableParams({
				page: 1,
				count: 5,
				sorting: { 'Version': 'desc' },
				filter: { GetAllVersions: true, PropertyFilter: [{ Property: 'GuidMasterRecord', Operator: '=', Value: $scope.model.GuidMasterRecord }] },
			}, {
				total: 0,
				filterDelay: 10,
				counts: [5, 10, 20],
				getData: function ($defer, params) {
					var columns = [];
					$scope.versionTableColumns.forEach(function (col) {
						columns.push(col.Property);
					});

					columns.push("VersionComment");
					columns.push("UserConfirmedBy.RealName");
					columns.push("IsCurrentVersion");
					columns.push("ConditionDate");
					columns.push("ConditionType.LikertScale");

					$scope.isVersionTableLoading = true;
					repository.GetPaginated(repository.apiData.condition.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
						$scope.isVersionTableLoading = false;
						$scope.versionTable.settings().total = result.TotalCount;
						$scope.versionTable.settings().filterDelay = 500;
						$defer.resolve(result.List);
					}, function (error) { repository.growl(error, 'danger'); $scope.isVersionTableLoading = false; });
				}
			});
		};

		var columns = [
			'Estate.Id', 'Estate.Description',
			'Building.Id', 'Building.Description',
			'Area.Id', 'Area.Description',
			'Equipment.Id', 'Equipment.Description', 'Equipment.ConditionContextDescription',
			'ConditionType.Id', 'ConditionType.LikertScale', 'ConditionType.Description',
			'Consequence.Id', 'Consequence.LikertScale', 'ConsequenceType.Id',
			'ConstructionType.Id', 'Priority.Description',
			'UserCreatedBy.RealName', 'UserUpdatedBy.RealName',
			'WorkOrder1.Id', 'WorkOrder1.Description', 'WorkOrder1.DueDate', 'WorkOrder1.GuidProject', 'WorkOrder1.EndDate', 'WorkOrder1.Status', 'WorkOrder1.GuidPeriodicTask',
			'WorkOrder1.Project.Id', 'WorkOrder1.Project.Description',
			'WorkOrder2.Id', 'WorkOrder2.Description', 'WorkOrder2.DueDate', 'WorkOrder2.GuidProject', 'WorkOrder2.EndDate', 'WorkOrder2.Status', 'WorkOrder2.GuidPeriodicTask',
			'WorkOrder2.Project.Id', 'WorkOrder2.Project.Description',
		];

		var getPresets = function () {
			if ($scope.isEdit || isModal)
				return null;
			var model = {};
			applyFilterPropertiesToModel(model);
			// Remove parent models since the deserialization will break if these are present.
			delete model.Building;
			delete model.Estate;
			return model;
		};

		var applyFilterPropertiesToModel = function (model) {
			if (!isModal) {
				var selectedBuilding = repository.commonService.getFilterData().selectedBuilding;
				if (selectedBuilding.Guid) {
					model.GuidBuilding = selectedBuilding.Guid;
					model.Building = { Guid: selectedBuilding.Guid, Caption: selectedBuilding.Name };

					if (selectedBuilding.Estate) {
						model.GuidEstate = selectedBuilding.Estate.Guid;
						model.Estate = { Guid: selectedBuilding.Estate.Guid, Caption: selectedBuilding.Estate.Caption };
					}
				}
			}
		};

		var getEntity = function () {
			var defer = $q.defer();

			// If we are creating a new condition via modal or not using copy, then use local resources.
			if (!$scope.isEdit && (isModal || !$stateParams.guidEntityToCopy)) {
				applyFilterPropertiesToModel($scope.model);
				return $.Deferred().resolve($scope.model).promise();
			}

			// for either update (with existing GUID) or insert (with default null GUID), fetch the whole entity to get proper FieldRules, which do not only depend on the entity type, but also on the current entity properties
			// furthermore, some fields may be dynamically initialized for new entities, what may be difficult to maintain in front end code
			// (there is not performance loss compared to the old solution, since a new object already needed a request to fetch field rules anyway)

			var queryString = JSON.stringify(columns);
			if (!$scope.isEdit && $stateParams.guidEntityToCopy) {
				queryString += "&GuidCopyFrom=" + $stateParams.guidEntityToCopy;
				$scope.currentStep = 'basicInfo';
			}

			var presets = getPresets();

			repository.getMainModel(repository.apiData.condition.url, ($scope.isEdit ? $stateParams.guid : "00000000-0000-0000-0000-000000000000"), queryString, presets ? JSON.stringify(presets) : null).then(function (response) {
				defer.resolve(response.Data);
			}, function (error) {
				repository.showError(error);
				defer.reject();
			});

			return defer.promise;
		};

		getEntity().then(function (model) {
			$scope.model = model;

			$scope.reloadDocumentTable = true;
			$scope.reload = true;

			updateAccess();

			if ($scope.isEdit) {
				if (!$scope.restrictEdit)
					$scope.restrictEdit = !$scope.model.IsCurrentVersion;

				if (isModal) {
					$scope.selectTab($scope.model.Type === 'CorrectiveAction' ? 'CorrectiveAction' : 'Condition');
				} else {
					if ($scope.model.Type === 'CurrentConditionInfo') {
						$scope.versionTableColumns = _.filter($scope.versionTableColumns, function (col) {
							return col.Property !== "Year" && col.Property !== "Estimate";
						});
					}
					getVersionTable();
				}
			}

			refreshDropdownDataBinding();
			$scope.modelIsLoaded = true;
			setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
		});

		// Any preparations to be done on the model should be done here.
		var formatModelBeforeSave = function (model) {
			if (model.ConditionDate) model.ConditionDate = new Date(model.ConditionDate).toISOString();
			if (model.DeadlineDate) model.DeadlineDate = new Date(model.DeadlineDate).toISOString();
			model.GuidConditionType = $scope.dropdown.ConditionType.Guid;
			model.GuidConsequence = $scope.dropdown.Consequence.Guid;
			model.GuidConsequenceType = $scope.dropdown.ConsequenceType.Guid;

			if (model.GuidConditionType && model.Type === 'Undefined') {
				model.Type = 'Condition';
			}
		};

		var isValid = function () {
			if (isModal) {
				if ($scope.model.Type === 'Condition' && !$scope.model.GuidConditionType) {
					repository.growl(translationService.translate('web-condition-isValid-conditionMissingConditionType', 'Tilstand krever tilstandsgrad'), 'danger');
					return false;
				}
			}
			return true;
		};

		$scope.saveAction = function (destination) {
			formatModelBeforeSave($scope.model);

			if (!isValid()) return;

			var success = function (result) {
				repository.growl($scope.isEdit ?
					translationService.translate('web-condition-update-success', 'Tiltak oppdatert') :
					translationService.translate('web-condition-create-success', 'Tiltak har blitt lagt til'), 'success');

				if ($scope.isEdit) {
					swal.close();
					if (isModal) {
						if (destination === 'close')
							$modalInstance.close('ok');
						else {
							$scope.model = result;
							$scope.isEdit = true;
							$scope.reloadDocumentTable = true;
							getCondition();
						}
					}
					else {
						repository.commonService.setLastRegisterGuid(destination, $scope.model.Guid);
						$scope.goBack(destination, { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
					}
					return;
				}

				if (destination === 'exit') {
					$scope.goBack('condition.list', { guid: result.Guid, menuGuid: $scope.navigation.params.menuGuid });
				} else if (destination === 'close') {
					$modalInstance.close('ok');
				} else {
					$scope.model = result;
					if (isModal) {
						$scope.isEdit = true;
						$scope.reloadDocumentTable = true;
						getCondition();
					} else {
						$scope.goBack('condition.edit', { guid: result.Guid, menuGuid: $scope.navigation.params.menuGuid });
					}
				}
			};

			var error = function (error) {
				repository.growl(typeof (error) === "string" ? error : error.Data.Message, 'danger');
			};

			if ($scope.isEdit) {
				repository.updateSingleDictionary(repository.apiData.condition.url, $scope.model).then(success, error);
			} else {
				repository.createSingleDictionary(repository.apiData.condition.url, $scope.model).then(success, error);
			}
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-condition-currentConditionInfo-message', 'Tilstandsinformasjon vil bli fjernet'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-condition-currentConditionInfo-button-confirm', 'Ja, fjern tilstandsinformasjon'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.condition.url, $scope.model.Guid).then(function (result) {
					swal(translationService.translate('web-swal-condition-currentConditionInfo-success', 'Tilstandsinformasjon ble fjernet!'), result, "success");
					$scope.goBack('condition.list', { menuGuid: $scope.navigation.params.menuGuid });
				});
			});
		};

		// Following onEvents should not depend on $scope variables as they are reused in a popup modal
		var onEstateSelect = function (model, estate) {
			if (!estate) {
				model.GuidEstate = "";
				return;
			}

			if (model.GuidEstate !== estate.Guid) {
				model.GuidBuilding = "";
				model.GuidEquipment = "";
				model.GuidArea = "";

				delete model.Building;
				delete model.Equipment;
				delete model.Area;
			}

			model.GuidEstate = estate.Guid;
		};

		var onBuildingSelect = function (model, building) {
			if (!building) {
				model.GuidBuilding = "";
				return;
			}

			if (model.GuidBuilding !== building.Guid) {
				model.GuidEquipment = "";
				model.GuidArea = "";

				delete model.Equipment;
				delete model.Area;
			}

			model.GuidBuilding = building.Guid;

			if (!model.GuidEstate || model.GuidEstate === "") {
				model.GuidEstate = building.GuidEstate;
				model.Estate = {
					Guid: building.Estate.Guid,
					Caption: building.Estate.Caption
				};
			}
		};

		var onAreaSelect = function (model, area) {
			if (!area) {
				model.GuidArea = "";
				return;
			}

			model.GuidArea = area.Guid;

			if (!model.GuidEstate || model.GuidEstate === "") {
				model.GuidEstate = area.Building.Estate.Guid;
				model.Estate = {
					Guid: area.Building.Estate.Guid,
					Caption: area.Building.Estate.Caption
				};
			}

			if (!model.GuidBuilding || model.GuidBuilding === "") {
				model.GuidBuilding = area.GuidBuilding;
				model.Building = {
					Guid: area.Building.Guid,
					Caption: area.Building.Caption
				};
			}
		};

		var onEquipmentSelect = function (model, equipment) {
			if (!equipment) {
				model.GuidEquipment = "";
				return;
			}

			model.GuidEquipment = equipment.Guid;
			model.Equipment = equipment;

			if (!model.GuidEstate || model.GuidEstate === "") {
				model.GuidEstate = equipment.Building.Estate.Guid;
				model.Estate = {
					Guid: equipment.Building.Estate.Guid,
					Caption: equipment.Building.Estate.Caption
				};
			}

			if (!model.GuidBuilding || model.GuidBuilding === "") {
				model.GuidBuilding = equipment.GuidBuilding;
				model.Building = {
					Guid: equipment.GuidBuilding,
					Caption: equipment.Building.Caption
				};
			}

			if (equipment.GuidArea) {
				model.GuidArea = equipment.GuidArea;
				model.Area = {
					Guid: equipment.GuidArea,
					Caption: equipment.Area.Caption
				};
			}
		};

		$scope.onEstateSelect = function (estate) {
			onEstateSelect($scope.model, estate);
		};

		$scope.onBuildingSelect = function (building) {
			onBuildingSelect($scope.model, building);
		};

		$scope.onAreaSelect = function (area) {
			onAreaSelect($scope.model, area);
		};

		$scope.onEquipmentSelect = function (equipment) {
			onEquipmentSelect($scope.model, equipment);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		$scope.calculateEstimate = function () {
			if (!$scope.useNewCorrectiveAction) return;
			// Math.Round((Quantity * UnitPrice) / 100) * 100   from api.
			$scope.model.Estimate = Math.round(($scope.model.Quantity * $scope.model.UnitPrice) / 100) * 100;
			if (isNaN($scope.model.Estimate))
				$scope.model.Estimate = 0;
		};

		$scope.onEstimateDateYearDropdownChange = function () {
			if ($scope.dropdown.EstimateDateYear)
				$scope.model.EstimateDate = new Date($scope.dropdown.EstimateDateYear.toString()).toISOString();
			else
				$scope.model.EstimateDate = null;
		};

		$scope.onGetValidityRules = function (originalValidityRules) {
			var validityRules = _.clone(originalValidityRules);// work on a copy, not to alter the original rules
			if (validityRules.EntityType === 'Condition' && validityRules.DomainFieldName === "ActionComment")
				validityRules.Mandatory |= $scope.model.Type === 'CorrectiveAction';
			return validityRules;
		};

		$scope.changeMaintenanceObjects = function () {
			$modal.open({
				templateUrl: 'app/condition/views/conditionChangeMaintenanceReferencesModal.html',
				controller: 'ConditionChangeMaintenanceReferencesModalController',
				size: 'lg',
				resolve: {
					modalParams: function () {
						return {
							model: $scope.model,
							autoCompleteFilter: $scope.autoCompleteFilter,
							onChangeEvents: {
								onAreaSelect: onAreaSelect,
								onEquipmentSelect: onEquipmentSelect,
							}
						};
					}
				}
			}).result.then(function () {
				$scope.goBack('condition.edit', { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
			});
		};

		$scope.getPropertyValue = function (building, column) {
			return listService.GetPropertyValue(building, column);
		};

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			if (icon)
				htmlText += '<i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.originContextMenu = function () {
			var options = [];

			if ($scope.hasReadAccess('WorkOrder') && $scope.model.GuidInspectionWorkOrder && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: $scope.model.GuidInspectionWorkOrder }), translationService.translate('web-condition-originContextMenu-viewInspectionWorkOrder', 'Gå til arbeidsordre')));

			if ($scope.hasReadAccess('Project') && $scope.model.GuidInspectionWorkOrder && $scope.model.WorkOrder1 && $scope.model.WorkOrder1.GuidProject && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('project.edit', { guid: $scope.model.WorkOrder1.GuidProject }), translationService.translate('web-condition-originContextMenu-viewInspectionProject', 'Gå til prosjekt')));

			if ($scope.hasReadAccess('PeriodicTask') && $scope.model.GuidInspectionWorkOrder && $scope.model.WorkOrder1 && $scope.model.WorkOrder1.GuidPeriodicTask && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: $scope.model.WorkOrder1.GuidPeriodicTask }), translationService.translate('web-condition-originContextMenu-viewInspectionPeriodicTask', 'Gå til periodisk rutine')));

			if (options.length === 0) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#">' + translationService.translate('web-condition-originContextMenu-empty', 'Ingen aksjoner tilgjengelig') + '</a>',
					enabled: false
				});
			}

			return options;
		};

		$scope.followUpContextMenu = function () {
			var options = [];

			if ($scope.hasEditAccess('Condition') && $scope.model.GuidCorrectiveWorkOrder && $scope.model.WorkOrder2 && !$scope.model.WorkOrder2.EndDate && $scope.canCloseCorrectiveAction($scope.model, $scope.model.GuidCorrectiveWorkOrder)) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-followUpContextMenu-closeCorrectiveActionFromCorrective', 'Lukk tiltak via') + ' ' + $scope.model.WorkOrder2.Caption + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.closeCorrectiveAction();
					}
				});
			}

			if ($scope.hasEditAccess('Condition') && !$scope.model.GuidCorrectiveWorkOrder && $scope.model.GuidInspectionWorkOrder && $scope.model.WorkOrder1 && !$scope.model.WorkOrder1.EndDate && $scope.canCloseCorrectiveAction($scope.model, $scope.model.GuidInspectionWorkOrder)) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-followUpContextMenu-closeCorrectiveActionFromInspection', 'Lukk tiltak via') + ' ' + $scope.model.WorkOrder1.Caption + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.closeCorrectiveAction();
					}
				});
			}

			if ($scope.hasEditAccess('Condition') && $scope.model.IsCurrentVersion && $scope.useNewCorrectiveAction) {
				var text = translationService.translate('web-condition-followUpContextMenu-confirmVersion', 'Bekreft versjon');
				if ($scope.model.Type === 'CurrentConditionInfo')
					test = translationService.translate('web-condition-currentConditionInfo-followUpContextMenu-confirmVersion', 'Bekreft versjon');

				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + text + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.confirmVersion();
					}
				});
			}

			if ($scope.hasReadAccess('WorkOrder') && $scope.model.GuidCorrectiveWorkOrder && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: $scope.model.GuidCorrectiveWorkOrder }), translationService.translate('web-condition-followUpContextMenu-viewCorrectiveWorkOrder', 'Gå til arbeidsordre')));

			if ($scope.hasReadAccess('Project') && $scope.model.GuidCorrectiveWorkOrder && $scope.model.WorkOrder2 && $scope.model.WorkOrder2.GuidProject && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('project.edit', { guid: $scope.model.WorkOrder2.GuidProject }), translationService.translate('web-condition-followUpContextMenu-viewCorrectiveProject', 'Gå til prosjekt')));

			if ($scope.hasReadAccess('PeriodicTask') && $scope.model.GuidCorrectiveWorkOrder && $scope.model.WorkOrder2 && $scope.model.WorkOrder2.GuidPeriodicTask && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: $scope.model.WorkOrder2.GuidPeriodicTask }), translationService.translate('web-condition-followUpContextMenu-viewCorrectivePeriodicTask', 'Gå til periodisk rutine')));

			if ($scope.hasEditAccess('Condition') && $scope.model.Status === 'InWorks' && $scope.model.Type !== "CurrentConditionInfo" && $scope.model.GuidCorrectiveWorkOrder && !$scope.model.WorkOrder2.EndDate) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-followUpContextMenu-removeFromWorkOrder', 'Koble fra arbeidsordre') + '</a >',
					click: function () {
						swal({
							title: translationService.translate('web-condition-followUpContextMenu-removeFromWorkOrder-swalTitle', 'Bekreft frakobling av tiltak'),
							text: translationService.translate('web-condition-followUpContextMenu-removeFromWorkOrder-swalMessage', 'Er du sikker på at du vil koble dette tiltaket ifra arbeidsordren?'),
							type: "info",
							showCancelButton: true,
							confirmButtonColor: "#2196f3",
							confirmButtonText: translationService.translate('web-condition-followUpContextMenu-removeFromWorkOrder-swalConfirm', 'Ja, koble fra!'),
							cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
							closeOnConfirm: false,
							showLoaderOnConfirm: true
						}, function () {
							window.onkeydown = null;
							window.onfocus = null;
							$scope.model.GuidCorrectiveWorkOrder = null;
							$scope.saveAction('condition.edit');
						});
					}
				});
			}

			if ($scope.hasCreateAccess('WorkOrder') && $scope.model.Status !== 'Closed' && $scope.model.Type !== "CurrentConditionInfo" && !$scope.model.GuidCorrectiveWorkOrder) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-followUpContextMenu-createWorkOrder', 'Opprett ny arbeidsordre for gjennomføring av tiltak') + '</a >',
					click: function () {
						var workOrder = {
							guidSelections: [$scope.model.Guid],
							fromCondition: true,
							fromDeviation: false,
							defaultDescription: translationService.translate('correctiveAction-new-workOrder-defaultDescription', 'Gjennomføring av tiltak')
						};

						if ($scope.model.GuidEstate) {
							workOrder.guidEstate = $scope.model.GuidEstate;
							workOrder.estate = $scope.model.Estate.Caption;
						}

						if ($scope.model.GuidBuilding) {
							workOrder.guidBuilding = $scope.model.GuidBuilding;
							workOrder.building = $scope.model.Building.Caption;
						}

						if ($scope.model.GuidEquipment) {
							workOrder.guidEquipment = $scope.model.GuidEquipment;
							workOrder.equipment = $scope.model.Equipment.Caption;
						}

						repository.persistedData.setPersistedData('workOrder.create', workOrder);
						$scope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
					}
				});
			}

			if ($scope.hasReadAccess('WorkOrder') && $scope.model.Status !== 'Closed' && $scope.model.Type !== "CurrentConditionInfo" && $scope.hasEditAccess('Condition') && !$scope.model.GuidCorrectiveWorkOrder) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-followUpContextMenu-addToExistingWo', 'Koble til eksisterende arbeidsordre') + '</a >',
					click: function () {
						$modal.open({
							templateUrl: 'app/common/views/singleSelectModal.html',
							controller: 'SingleSelectModalController',
							size: 'lg',
							resolve: {
								modalParams: function () {
									return {
										title: 'Velg arbeidsordre tiltaket skal kobles til',
										url: repository.apiData.workOrder.url,
										sorting: { 'Id': 'desc' },
										filter: { PropertyFilter: [{ Property: 'EndDate', Operator: '=', Value: null }] },
										columns: [
											{ title: translationService.translate('web-workOrder-Id', 'Ao Nr'), property: 'Id' },
											{ title: translationService.translate('web-workOrder-Description', 'Beskrivelse'), property: 'Description', PropertyType: 'String' },
											{ title: translationService.translate('web-workOrder-Building-Id', 'Bygg Id'), property: 'Building.Id' },
											{ title: translationService.translate('web-workOrder-Building-Description', 'Byggbeskrivelse'), property: 'Building.Description', PropertyType: 'String' },
										]
									};
								}
							}
						}).result.then(function (selectedEntity) {
							$scope.model.GuidCorrectiveWorkOrder = selectedEntity.Guid;
							$scope.saveAction('condition.edit');
						});
					}
				});
			}

			if ($scope.hasEditAccess('Condition') && $scope.model.Status === 'New' && $scope.model.Type === 'CorrectiveAction' && !$scope.model.IsCancelled) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-currentConditionInfo-followUpContextMenu-cancelCorrectiveAction', 'Kanseller') + '</a >',
					click: function () {
						$scope.cancelCorrectiveAction();
					}
				});
			}

			if ($scope.hasEditAccess('Condition') && $scope.model.Type === 'CurrentConditionInfo' && $scope.model.EndDate && $scope.model.IsCurrentVersion) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-currentConditionInfo-followUpContextMenu-reOpenCurrentCondition', 'Reaktiver') + '</a >',
					click: function () {
						$scope.model.EndDate = null;
						$scope.saveAction('condition.edit');
					}
				});
			}

			if ($scope.hasEditAccess('Condition') && $scope.model.Type === 'CurrentConditionInfo' && !$scope.model.EndDate && $scope.model.IsCurrentVersion) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-followUpContextMenu-deactivateCurrentCondition', 'Deaktiver') + '</a >',
					click: function () {
						$scope.model.EndDate = new Date().toISOString();
						$scope.saveAction('condition.edit');
					}
				});
			}


			if (options.length === 0) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#">' + translationService.translate('web-condition-followUpContextMenu-empty', 'Ingen aksjoner tilgjengelig') + '</a>',
					enabled: false
				});
			}
			return options;
		};

		$scope.$watch('dropdown.ConditionType.Guid', function (newValue, oldValue) {
			if (newValue === oldValue)
				return;
			if (!newValue) {
				$scope.model.ConditionDate = '';
			}
			if (newValue && !$scope.model.ConditionDate) {
				$scope.model.ConditionDate = moment().toDate();
			}
		});

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
