(function () {
	var app = angular.module('Plania');
	app.directive('cleaningTaskArea', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reloadTables: '=reloadTables'
			},
			controller: ['$scope', '$localStorage', 'TranslationService', 'ngTableParams', '$stateParams', 'Repository', 'ListService', '$modal', '$rootScope', controller],
			templateUrl: 'app/cleaningTask/views/cleaningTaskArea.html'
		};
	});

	function controller($scope, $localStorage, translationService, ngTableParams, $stateParams, repository, listService, $modal, $rootScope) {
		$scope.selectedAreas = [];
		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.areaColumns = [
			'Area.Building.Id', 'Area.Building.Description', 'Area.Building.Guid',
			'Area.Id', 'Area.Description', 'Area.Guid',
			'ResourceGroup.Id', 'ResourceGroup.Guid',
			'AverageCleaningPerYear', 'Recurrence', 'Area.RoomName', 'Area.RoomSpecification', 'Area.MainFunction', 'Area.PartFunction', 'Area.GuidDrawing'
		];

		$scope.editedAreaColumns = [
			{ Title: translationService.translate('web-building'), Property: 'Area.Building.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
			{ Title: translationService.translate('web-area'), Property: 'Area.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidArea', AutocompleteService: 'area' },
			{ Title: translationService.translate('web-resourcegroup-id'), Property: 'ResourceGroup.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidCleaningTeam', AutocompleteService: 'resourceGroup' },
			{ Title: translationService.translate('web-areaxcleaningtask-AverageCleaningPerYear', 'Tid neste 12 mnd'), Property: 'AverageCleaningPerYear', PropertyType: 'number' },
			{ Title: translationService.translate('web-areaxcleaningtask-Recurrence', 'Gjentakelse'), Property: 'Recurrence', PropertyType: 'recurrence' },
		];

		$scope.useAreaClassification = $localStorage.generalOptions && $localStorage.generalOptions.UseAreaClassification;
		if ($scope.useAreaClassification) {
			$scope.editedAreaColumns.push({ Title: translationService.translate('web-Area-MainFunction'), Property: 'Area.MainFunction', PropertyType: 'string' });
			$scope.editedAreaColumns.push({ Title: translationService.translate('web-Area-PartFunction'), Property: 'Area.PartFunction', PropertyType: 'string' });
			$scope.editedAreaColumns.push({ Title: translationService.translate('web-Area-RoomName'), Property: 'Area.RoomName', PropertyType: 'string' });
			$scope.editedAreaColumns.push({ Title: translationService.translate('web-Area-RoomSpecification'), Property: 'Area.RoomSpecification', PropertyType: 'string' });
		}

		var filterTypes = [
			{ value: 'Active', name: translationService.translate('web-cleaningTask-areaXCleaningTask-filterType-active', 'Aktive') },
			{ value: 'Expired', name: translationService.translate('web-cleaningTask-areaXCleaningTask-filterType-expired', 'Utgått') },
			{ value: 'Undefined', name: translationService.translate('web-cleaningTask-areaXCleaningTask-filterType-all', 'Alle') },
		];

		$scope.currentSelectedFilterType = filterTypes[0];

		$scope.setFilter = function (filterTypeValue) {
			var selectedFilterType = _.find(filterTypes, { value: filterTypeValue });
			if (selectedFilterType && filterTypeValue !== $scope.currentSelectedFilterType.value) {
				$scope.currentSelectedFilterType = selectedFilterType;
				$scope.areaTable.reload();
			}
		};

		var applyFilter = function (filter) {
			var typeValue = $scope.currentSelectedFilterType.value;

			filter.CleaningStatus = typeValue;
		};

		$scope.areaTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { 'Area.Id': 'asc' },
			filter: { PropertyFilter: [{ Property: 'GuidCleaningTask', Operator: '=', Value: $stateParams.guid }] }
		}, {
			total: 0,
			counts: [10, 20, 50, 100, 200],
			filterDelay: 10,
			getData: function ($defer, params) {
				var filter = angular.copy(params.filter());

				if (filter.mainFunction !== undefined && filter.mainFunction !== "") {
					filter.PropertyFilter.push({ Property: 'Area.MainFunction', Operator: "contains", Value: filter.mainFunction });
				}

				if (filter.partFunction !== undefined && filter.partFunction !== "") {
					filter.PropertyFilter.push({ Property: 'Area.PartFunction', Operator: "contains", Value: filter.partFunction });
				}

				if (filter.roomName !== undefined && filter.roomName !== "") {
					filter.PropertyFilter.push({ Property: 'Area.RoomName', Operator: "contains", Value: filter.roomName });
				}

				if (filter.roomSpecification !== undefined && filter.roomSpecification !== "") {
					filter.PropertyFilter.push({ Property: 'Area.RoomSpecification', Operator: "contains", Value: filter.roomSpecification });
				}

				applyFilter(filter);

				$scope.loadingAreas = true;
				repository.GetPaginated(repository.apiData.areaXCleaningTask.url, params.page() - 1, params.count(), params.sorting(), filter, "", JSON.stringify($scope.areaColumns)).then(function (result) {
					$scope.loadingAreas = false;
					$scope.areaTable.settings().total = result.TotalCount;
					$scope.areaTable.settings().filterDelay = 500;
					$defer.resolve(result.List);
				}, function (error) {
					$scope.loadingAreas = false;
					repository.growl(error, 'danger');
				});
			}
		});

		$scope.addSelected = function (key, guid) {
			if (!_.contains($scope.selectedAreas, guid))
				$scope.selectedAreas.push(guid);
			else {
				for (var i = 0; i < $scope.selectedAreas.length; i++) {
					var p = $scope.selectedAreas[i];
					if (p === guid) {
						$scope.selectedAreas.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.isSelected = function (key, guid) {
			return _.contains($scope.selectedAreas, guid);
		};

		$scope.addArea = function () {
			var columns = [
				{ Title: translationService.translate('web-building-caption', 'Bygg'), Property: 'Building.Id' },
				{ Title: translationService.translate('web-area-caption', "Areal"), Property: 'Id' },
			];
			if ($localStorage.generalOptions && $localStorage.generalOptions.UseAreaClassification) {
				columns.push({ Title: translationService.translate('web-Area-MainFunction'), Property: 'MainFunction' });
				columns.push({ Title: translationService.translate('web-Area-PartFunction'), Property: 'PartFunction' });
				columns.push({ Title: translationService.translate('web-Area-RoomName'), Property: 'RoomName' });
				columns.push({ Title: translationService.translate('web-Area-RoomSpecification'), Property: 'RoomSpecification' });
			} else {
				columns.push({ Title: translationService.translate('web-areacategory-Description'), Property: 'AreaCategory.Description' });
			}

			var modalInstance = $modal.open({
				templateUrl: 'app/cleaningTask/views/cleaningTaskWizardModal.html',
				controller: 'CleaningTaskWizardModalController',
				size: 'lg',
				resolve: {
					modalParams: function () {
						return {
							columns: columns,
							url: repository.apiData.area.url,
							sorting: { Id: 'asc' },
							filter: { GuidCleaningTask: $scope.model.Guid },
							cleaningTask: $scope.model,
							useCaption: true
						};
					}
				}
			});

			modalInstance.result.then(function (result) {
				var selectedGuids = result.selectedGuids, recurrenceRule = result.recurrenceRule, attributeValues = result.attributeValues, model = result.model;
				var areaxCleaningTasks = [];
				selectedGuids.forEach(function (guid) {
					areaxCleaningTasks.push(JSON.stringify({
						GuidCleaningTask: $scope.model.Guid,
						RecurrenceRule: recurrenceRule,
						GuidArea: guid,
						AttributeValues: attributeValues,
						StartDate: model.StartDate ? new Date(model.StartDate).toISOString() : model.StartDate,
						UntilDate: model.UntilDate ? new Date(model.UntilDate).toISOString() : model.UntilDate
					}));
				});
				repository.createMultiple(repository.apiData.areaXCleaningTask.url, areaxCleaningTasks).then(function (result) {
					$scope.areaTable.reload();
				}, function (error) {
					repository.growl(error, 'danger');
				});
			}, function (error) {
			});
		};

		$scope.editArea = function (task) {
			$modal.open({
				templateUrl: 'app/area/views/EditAreaXCleaningTaskModal.html',
				controller: 'AreaXCleaningTaskController',
				resolve: {
					modalParams: function () {
						return { Guid: task.Guid };
					}
				}
			}).result.then(function (result) {
				repository.growl(result, 'success');
				$scope.areaTable.reload();
			});
		};

		$scope.massEdit = function () {
			if ($scope.selectedAreas.length === 0) {
				swal({
					title: translationService.translate('web-swal-error-missingSelect-title', 'Ingen valg'),
					text: translationService.translate('list-mass-edit-missingSelect', 'Ingen valg. Velg en eller flere rader fra listen for å masseredigere'),
					type: "error"
				});
				return;
			}

			var showRecurrence = $scope.model.CleaningType !== 'AdditionalCleaning' && $scope.model.CleaningType !== 'InfectionCleaning' && $scope.model.CleaningType !== 'ExitCleaning';
			var columns = [];
			$scope.editedAreaColumns.forEach(function (col) {
				if (col.Property === "Recurrence" && !showRecurrence) {
					// Skip
				} else {
					columns.push(col);
				}
			});

			$modal.open({
				templateUrl: 'app/common/views/massEditModal.html',
				controller: 'MassEditModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							columns: columns,
							selectedRows: $scope.selectedAreas,
							selectedRowsObjects: _.filter($scope.areaTable.data, function (o) { return _.contains($scope.selectedAreas, o.Guid); }),
							prefix: 'AreaXCleaningTask',
							showPeriodicCleaningRecurrence: $scope.model.CleaningType === 'PeriodicCleaning'
						};
					}
				}
			}).result.then(function (editedRows) {
				$scope.areaTable.reload();
			}, function () { });
		};

		$scope.removeArea = function (area) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-cleaningquality-area-message', "Rommet vil ikke lenger være knyttet til renholdskvaliteten!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-cleaningquality-area-button-confirm', 'Ja, fjern rommet'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.areaXCleaningTask.url, '', 'guids=' + JSON.stringify([area.Guid]))
					.then(function (result) {
						swal(translationService.translate('web-swal-area-cleaningTask-success', 'Rommet ble fjernet!'), result, "success");
						repository.growl('Rommet har blitt fjernet', 'success');
						$scope.selectedAreas = [];
						$scope.areaTable.reload();
					});
			});
		};

		$scope.massDeleteArea = function () {
			if ($scope.selectedAreas.length === 0) {
				swal({
					title: translationService.translate('web-swal-error-missingSelect-title', 'Ingen valg'),
					text: translationService.translate('list-mass-delete-missingSelect', 'Ingen valg. Velg en eller flere rader fra listen for å slette flere rom'),
					type: "error"
				});
				return;
			}

			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-cleaningtask-areas-message',
					"Rommene vil ikke lenger være knyttet til renholdsoppgaven!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-cleaningquality-areas-button-confirm',
					'Ja, fjern rommene'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			},
			function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.areaXCleaningTask.url, '', 'guids=' + JSON.stringify($scope.selectedAreas)).then(function (result) {
					swal({
						title: translationService.translate('web-swal-area-cleaningTasks-success', 'Rommene ble fjernet!'),
						text: result,
						type: "success"
					});

					$scope.selectedAreas = [];
					$scope.areaTable.reload();
				});
			});
		};

		$scope.$watch('reloadTables', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			$scope.areaTable.reload();
		}, true);

		$scope.menuOptions = function (area) {
			var options = [];

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href=""><i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-cleaningTask-areaTable-edit', 'Rediger rom') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$scope.editArea(area);
				}
			});

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href=""><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-cleaningTask-areaTable-delete', 'Slett rom') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$scope.removeArea(area);
				}
			});

			if (area.Area.GuidDrawing && repository.enableWebNavigation) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href=""><i style="visibility:hidden;" class="zmdi zmdi-image zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-open-drawing', 'Gå til tegning') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$rootScope.navigation.go('dwg.view', { guid: area.Area.GuidDrawing, theme: 'cleaningMaintenance', guidArea: area.GuidArea });
					}
				});
			}

			return options;
		};
	}
})();
