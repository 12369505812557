(function () {
	angular.module('Plania').controller('EmailModalController', ['$scope', '$modalInstance', '$modal', 'params', 'TranslationService', 'DocumentService', 'Repository', '$q', '$sce', '$filter', '$localStorage', controller]);

	function controller($scope, $modalInstance, $modal, params, translationService, documentService, repository, $q, $sce, $filter, $localStorage) {
		var entity = params.entity;
		$scope.translatedEntity = translationService.translate('web-enum-prefix-' + entity);
		$scope.model = params.model;
		$scope.emailModel = params.model; // For validation and fill out missing values
		$scope.columns = angular.copy(params.columns);
		$scope.attachments = [];
		$scope.emailTemplates = [];
		$scope.isInitialized = true;

		$scope.email = params.email || {
			to: [],
			copy: [],
			body: '',
			subject: ''
		};

		if (params.prefillSubjectWithCaption) $scope.email.subject = $scope.translatedEntity + " " + $scope.getEntityCaption($scope.model);

		// Two different event methods to update the modals since we cannot be sure that the original input of attachments are updated via reference.
		// Event to signal that a new document / image has been created. Does not expect any in return.
		var onNewDocument = params.onNewDocument;
		var onNewImage = params.onNewImage;

		// Event to signal that a new document / image has been created. This does expect a new list of entities.
		var reloadDocuments = params.reloadDocuments;
		var reloadImages = params.reloadImages;

		if (!$scope.email.from && $localStorage.userData.email) {
			var fromEmail = $localStorage.userData.email;
			if (fromEmail.includes('<') && fromEmail.includes('>')) {
				$scope.email.from = fromEmail;
			} else {
				$scope.email.from = $localStorage.userData.realName + " <" + $localStorage.userData.email + ">";
			}
		}

		var camelCasePrefix = _.camelCase(entity);

		var url = repository.apiData[camelCasePrefix].url;

		var setAttachments = function () {
			if (!params.attachments || !_.isArray(params.attachments)) return;
			params.attachments.forEach(function (attachment) {
				if (attachment.ArchiveType === 1) {
					$scope.attachments.push(attachment);
				} else if (params.preselectedAttachments && _.contains(params.preselectedAttachments, attachment.Guid)) {
					$scope.attachments.push(attachment);
				}
			});
		};

		var fetchDocuments = function () {
			var filter = {};
			if (params.documentFilter) {
				filter = params.documentFilter;
			} else {
				filter['Guid' + entity] = $scope.model.Guid;
			}

			var documentColumns = [
				'Document.Id', 'Document.Description', 'Document.Guid',
				'Document.FilePath', 'ArchiveType'
			];

			return repository.GetPaginated(repository.apiData.documentXEntity.url, 0, 1000, { 'Document.Description': 'asc' }, filter, "", JSON.stringify(documentColumns)).then(function (response) {
				response.List.forEach(function (document) {
					if (!_.find(params.attachments, { Guid: document.Guid })) {
						params.attachments.push(document);
					}
				});
				setAttachments();
				return params.attachments;
			});
		};

		var fetchImages = function () {
			var url = "";

			if (entity === "WorkOrder")
				url = repository.apiData.workOrder.url;
			else if (entity === "Request")
				url = repository.apiData.request.url;

			if (!url)
				return;

			return repository.getSingle(url, $scope.model.Guid).then(function (response) {
				response.Data.Images.forEach(function (image) {
					if (!_.find(params.attachments, { Guid: image.Guid })) {
						params.attachments.push(image);
					}
				});
				setAttachments();
				return params.attachments;
			});
		};

		if (params.getAllDocuments) {
			fetchDocuments();
		} else {
			setAttachments();
		}

		var getEmailTemplates = function () {
			repository.GetPaginated(repository.apiData.emailTemplate.url, 0, 50, { Id: 'asc' }, { PropertyFilter: [{ Property: 'EntityType', Operator: '=', Value: entity }] }).then(function (result) {
				$scope.emailTemplates = result.List;

				if ($scope.emailTemplates.length > 0) {
					$scope.emailTemplates.forEach(function (emailTemplate) {
						emailTemplate.TemplateBody = decodeHtml(emailTemplate.TemplateBody);
					});

					$scope.selectedEmailTemplate = getDefaultTemplate();
					$scope.selectedTemplateChanged($scope.selectedEmailTemplate);
				}
			});
		};

		getEmailTemplates();

		var getDefaultTemplate = function () {
			var defaultTemplate = _.find($scope.emailTemplates, { IsDefault: true });
			if (!defaultTemplate) {
				defaultTemplate = _.find($scope.emailTemplates, function (o) {
					return o.Id === 'Plania Standard';
				});
			}

			return defaultTemplate;
		};

		$scope.selectedTemplateChanged = function (newValue) {
			$scope.setEmailBodyWithValues(newValue.TemplateBody);
		};

		$scope.loadTags = function (query) {
			var deferred = $q.defer();

			repository.GetPaginated(repository.apiData.emailContact.url, 0, 20, { Email: 'asc' }, { searchString: query, PropertyFilter: [{ Property: 'Email', Operator: '<>', Value: '' }] }).then(function (result) {
                deferred.resolve(result);
			});

			return deferred.promise;
		};

		$scope.tinymceOptions = {
			height: 350,
			language: 'nb_NO',
			branding: false,
			plugins: [
				'advlist autolink lists link image charmap print preview hr anchor pagebreak',
				'searchreplace visualblocks visualchars code codesample',
				'insertdatetime media nonbreaking save table contextmenu directionality',
				'emoticons template paste textcolor colorpicker textpattern imagetools toc help'
			],
			toolbar: 'undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright | bullist numlist outdent indent',
			image_advtab: true,
			paste_data_images: true,
			relative_urls: false,
			convert_urls: false,
			templates: [
				{ title: 'Test template 1', content: 'Test 1' },
				{ title: 'Test template 2', content: 'Test 2' }
			],
			inline_styles: true,
			font_formats: 'Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Calibri=calibri;Comic Sans MS=comic sans ms,sans-serif;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;',
			setup: function (editor) {
			}
		};

		$scope.setEmailBodyWithValues = function (string) {
			if (!string) {
				$scope.email.body = '';
				return;
			}

			var properties = string.match(/{{\s*[\w\.]+\s*}}/g);
			var userProperties = string.match(/{{\@\s*[\w\.]+\s*}}/g);
			var customProperties = string.match(/{{\#\s*[\w\.]+\s*}}/g);

			if (!properties)
				properties = [];

			var mappedProperties = properties.map(function (x) { return x.match(/[\w\.]+/)[0]; });
			var dottedColumns = _.filter(mappedProperties, function (o) { return o.indexOf('.') !== -1; });

			var tmpString = string;

			if (checkNestedProperties(dottedColumns)) {
				setEmailBody(tmpString, properties, mappedProperties, userProperties, customProperties);
			} else {
				repository.getSingle(url, $scope.model.Guid, JSON.stringify(dottedColumns)).then(function (response) {
					$scope.emailModel = response.Data;

					setEmailBody(tmpString, properties, mappedProperties, userProperties, customProperties);
				});
			}
		};

		var customPropertyFunctions = [
			{
				property: '#web_base_url',
				getValue: function () { return window.location.protocol + '//' + window.location.host; }
			}
		];

		function setEmailBody (tmpString, properties, mappedProperties, userProperties, customProperties) {
			for (var i = 0; i < properties.length; i++) {
				tmpString = tmpString.replace(properties[i], getPropertyValue(mappedProperties[i]));
			}

			if (userProperties && userProperties.length > 0) {
				var mappedUserProperties = userProperties.map(function (x) { return x.match(/[\@\w\.]+/)[0]; });

				for (var j = 0; j < userProperties.length; j++) {
					tmpString = tmpString.replace(userProperties[j], $scope.userInfo[mappedUserProperties[j].replace('@CurrentUser.', '')]);
				}
			}

			if (customProperties && customProperties.length > 0) {
				var mappedCustomProperties = customProperties.map(function (x) { return x.match(/[\#\w\.]+/)[0]; });
				for (var k = 0; k < customProperties.length; k++) {
					var prop = _.find(customPropertyFunctions, { property: mappedCustomProperties[k] });
					if (prop) {
						tmpString = tmpString.replace(customProperties[k], prop.getValue());
					}
				}
			}
			$scope.email.body = $sce.trustAsHtml(tmpString);
		}


		function getPropertyValue (property) {
			var properties = property.split('.');
			var propertyValue = $scope.emailModel;

			properties.forEach(function (prop) {
				if (propertyValue) {
					propertyValue = propertyValue[prop];
				}
			});

			if (typeof (propertyValue) === 'string' && isNaN(Number(propertyValue)) && moment(propertyValue).isValid()) {
				propertyValue = $filter('date')(propertyValue, 'dd.MM.yyyy');
			}

			if (typeof (propertyValue) === 'string')
				propertyValue = propertyValue.replace(/(?:\r\n|\r|\n)/g, '<br />');

			return propertyValue ? propertyValue : '';
		}

		function hasValue(testModel, prop, isLastProperty) {
			if (!testModel || !testModel.hasOwnProperty(prop))
				return false;

			// If we are on the last property, we should check if any values are not a default value.
			// Ex fetching a part of Area via WorkOrder might return 0 for number fields despite it having an acutal value.
			if (isLastProperty && typeof (testModel) === "object") {
				var value = testModel[prop];
				if (value === 0)
					return false;
				if (value === "Default")
					return false;
				if (value === "")
					return false;
			}

			return true;
		}

		function checkNestedProperties (dottedColumns) {
			try {
				for (var i = 0; i < dottedColumns.length; i++) {
					var testModel = $scope.emailModel;
					var splittedProperties = dottedColumns[i].split('.');
					for (var j = 0; j < splittedProperties.length; j++) {
						var isLastProperty = j === splittedProperties.length - 1;
						var prop = splittedProperties[j];
						if (!hasValue(testModel, prop, isLastProperty))
							return false;
						testModel = testModel[splittedProperties[j]];
					}
				}
				return true;
			}
			catch (err) {
				return false;
			}
		}

		$scope.send = function () {
			if ($scope.email.to.length < 1) {
				swal("Manglende mottaker!", "Minst 1 mottaker må velges", "info");
				$scope.showToErrorMessage = true;
				return;
			} else {
				$scope.showToErrorMessage = false;
			}

			var attachments = "";
			$scope.attachments.forEach(function (attachment) {
				var guid;

				if (attachment.GuidDocument) {
					guid = attachment.GuidDocument;
				} else {
					guid = attachment.Guid;
				}

				if (attachments.length === 0) {
					attachments += guid;
				} else {
					attachments += (',' + guid);
				}
			});

			if (!$scope.email.subject) $scope.email.subject = $scope.getEntityCaption($scope.model);

			var postEmail = {
				GuidEntity: $scope.model.Guid,
				EmailTo: _.map($scope.email.to, 'Email').join(';'),
				EmailCopyTo: _.map($scope.email.copy, 'Email').join(';'),
				EmailSubject: $scope.email.subject,
				EmailBody: encodeHtml($scope.email.body),
				EmailFrom: $scope.email.from,
				Attachments: attachments,
				Status: 'Outbox'
			};

			if (params.returnEmailObject) {
				$modalInstance.close(postEmail);
			}
			else {
				repository.createSingleDictionary(repository.apiData.alarmLog.url, postEmail).then(function (result) {
					swal("Suksess!", "Eposten ble lagt til i utboksen", "success");
					$modalInstance.close(result);
				},
					function (error) {
						swal("Feil!", error, "error");
					});
			}
		};

		var encodeHtml = function (html) {
			var tmpElement = document.createElement('div');
			return $(tmpElement).text(html).html();
		};

		var decodeHtml = function (html) {
			var tmpElement = document.createElement('textarea');
			tmpElement.innerHTML = html;
			return tmpElement.value;
		};

		$scope.isEmptyGuid = function (guid) {
			return guid === "00000000-0000-0000-0000-000000000000";
		};

		$scope.onTagAdded = function (tag) {
			if (!tag.Email) {
				tag.Email = tag.Name;
            }

            if (!tag.DisplayProperty) {
                tag.DisplayProperty = tag.Name + new Date().valueOf();
            }
        };

		$scope.$watch('email.to', function (newValue, oldValue) {
			$scope.showToErrorMessage = false;
		});

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		$scope.addAttachments = function () {
			var parameters = {
				entity: entity,
				guid: $scope.model.Guid,
				attachments: params.attachments,
				selectedAttachmentGuids: _.map($scope.attachments, function (o) { return o.Guid; }),
				onNewDocument: onNewDocument,
				onNewImage: onNewImage,
				reloadDocuments: function () {
					if (reloadDocuments) {
						return reloadDocuments().then(function (documents) {
							documents.forEach(function (document) {
								if (!_.find(params.attachments, { Guid: document.Guid })) {
									params.attachments.push(document);
								}
							});
							setAttachments();
							return params.attachments;
						});
					} else {
						return fetchDocuments();
					}
				},
				reloadImages: function () {
					if (reloadImages) {
						return reloadImages().then(function (images) {
							images.forEach(function (image) {
								if (!_.find(params.attachments, { Guid: image.Guid })) {
									params.attachments.push(image);
								}
							});
							setAttachments();
							return params.attachments;
						});
					} else {
						return fetchImages();
					}
				},
			};

			if (entity === 'PurchaseOrder') {
				parameters.connectedEntity = 'WorkOrder';
				parameters.connectedEntityGuid = $scope.model.GuidWorkOrder;
			}

			$modal.open({
				templateUrl: 'app/common/modal/views/selectAttachmentModal.html',
				controller: 'SelectAttachmentModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return parameters;
					}
				}
			}).result.then(function (result) {
				result.forEach(function (attachment) {
					if (!_.find($scope.attachments, { Guid: attachment.Guid }))
						$scope.attachments.push(attachment);
				});
			}, function (error) {

			});
		};

		$scope.openDocument = function(document){
			documentService.openDocument(document.Document);
		};
	}
})();
