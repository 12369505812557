(function () {

    var app = angular.module('Plania');

    app.directive('conditionDirective', function () {
        return {
            restrict: 'E',
            scope: {
                isCollapsed: '=isCollapsed',
                reload: '=reload',
                conditions: '=conditions',
                estate: '=estate',
                building: '=building',
                area: '=area',
                equipment: '=equipment',
                parentEntity: '=parentEntity',
                guid: '=guid',
                restrictEdit: "=restrictEdit",
                useList: '=useList', //Set to true if using directive in small width container
                inline: '=inline', //Set to true if used inline in modal
            },
			controller: ['$scope', '$modal', 'ngTableParams', 'Repository', '$rootScope', 'TranslationService', '$localStorage', 'ListService', 'Constants', '$q', 'CommonService', controller],
            link: function (scope, element, attrs) {
            },
            templateUrl: 'app/condition/views/conditionDirective.html'
        };
    });

	function controller($scope, $modal, ngTableParams, repository, $rootScope, translationService, $localStorage, listService, constants, $q, commonService) {
		var isSVV = $localStorage.generalOptions.CustomerId === '10088' || $localStorage.generalOptions.CustomerId === '12258';
        $scope.conditionsIsLoading = true;
		$scope.search = {};
		var contextId = $scope.parentEntity ? $scope.parentEntity + '-Condition' : null;

        $scope.hasEditAccess = $rootScope.hasEditAccess('Condition');

        $scope.$watch('reload', function (newValue, oldValue) {
            if ($scope.reload && $scope.reload === true) {
                if ($scope.conditionTable) {
                    $scope.conditionTable.reload();
                }
                $scope.reload = false;
            }
        });

        $scope.defaultListSetup = {
            Id: 'WorkOrderCondition',
            Sorting: { 'Id': 'desc' },
			EntityType: repository.apiData.condition.prefix,
			ContextId: contextId,
            IsPublicView: true,
            Columns: [
				{ Position: 1, Title: translationService.translate('web-condition-id'), Property: 'Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-conditionType-description'), Property: 'ConditionType.Description', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-consequence-id'), Property: 'Consequence.Id', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-condition-conditiondate', 'Tilstandsdato'), Property: 'ConditionDate', PropertyType: 'date' },
				{ Position: 5, Title: translationService.translate('web-condition-actioncomment-measures', 'Tiltak'), Property: 'ActionComment', PropertyType: 'string' },
            ]
        };

        $scope.menuOptions = function (condition) {
            var options = [];

            if ($scope.hasEditAccess) {
                options.push({
                    html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-workorder-condition-edit', 'Rediger tilstand') + '</a >',
                    click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.createOrUpdate(condition);
                    }
                });
            }

            if ($scope.hasEditAccess && $scope.hasAvailableDefaultCondition) {
                options.push({
                    html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-check zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-workorder-condition-approve', 'Godkjenn tilstand') + '</a >',
                    click: function ($itemScope, $event, modelValue, text, $li) {
                        $scope.approveCondition(condition);
                    }
                });
            }

            if ($scope.hasEditAccess && $scope.canCloseCorrectiveAction(condition)) {
                options.push({
                    html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-square-down zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-button-close-correctiveAction', 'Lukk tiltak') + '</a >',
                    click: function ($itemScope, $event, modelValue, text, $li) {
                        $scope.closeCorrectiveAction(condition);
                    }
                });
            }

            if ($rootScope.hasDeleteAccess('Condition')) {
                options.push({
                    enabled: !condition.Condition1 && !condition.ActionComment,
                    hasTopDivider: true,
                    html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-button-delete', 'Slett') + '</a >',
                    click: function ($itemScope, $event, modelValue, text, $li) {
                        $scope.remove(condition);
                    }
                });
            }

            return options;
        };

        var originalListSetup = angular.copy($scope.defaultListSetup);

        $scope.columns = [
			'Estate.Id', 'Estate.Description',
			'Building.Id', 'Building.Description',
			'Area.Id', 'Area.Description',
			'Equipment.Id', 'Equipment.Description'
        ];

        var hasAccessToCreateNewWebList = function() {
			return $scope.$root.userInfo.isSystemAdministrator &&
				repository.authService.hasCreateAccess(repository.commonService.prefix.WebListView, false);
        };

		repository.GetPaginated(repository.apiData.webListView.url, 0, 10, {}, { Prefix: repository.apiData.condition.prefix, ContextId: contextId }).then(function (result) {
			if (result.List.length > 0) {
				var guidCurrentDataOwner = commonService.getFilterData().selectedDataOwner.Guid;
				var webListCurrentDataOwner = _.find(result.List, function(x) { return x.GuidDataOwner === guidCurrentDataOwner; });

				// to simplify, sysadm creates a new default web list view on this data owner
				if (!webListCurrentDataOwner && hasAccessToCreateNewWebList()) {
					repository.createSingle(repository.apiData.webListView.url, $scope.defaultListSetup).then(
						function(result) {
							$scope.defaultListSetup.Guid = result;
						});
				} else {
					$scope.defaultListSetup = webListCurrentDataOwner ? webListCurrentDataOwner : result.List[0];
				}
			}

            getTable();
        });

        var getTable = function () {
            $scope.conditionTable = new ngTableParams({
                page: 1,
                count: 10,
                sorting: { 'Id': 'desc' },
            }, {
                total: 0,
                counts: [5, 10, 20],
                filterDelay: 50,
                paginationMaxBlocks: $scope.useList ? 6 : 8,
                getData: function ($defer, params) {
                    if (!$scope.conditionTable.filter().PropertyFilter) {
                        $scope.conditionTable.filter().PropertyFilter = [{ Property: 'Guid' + $scope.parentEntity, Operator: '=', Value: $scope.guid }];
                        if ($scope.parentEntity === "WorkOrder")
                            $scope.conditionTable.filter().PropertyFilter.push({ Operand: 'or', Property: 'GuidInspectionWorkOrder', Operator: '=', Value: $scope.guid });
                    }

                    $scope.conditionsIsLoading = true;

                    var columns = [];
                    $scope.columns.forEach(function (prop) {
                        columns.push(prop);
                    });

                    $scope.defaultListSetup.Columns.forEach(function (col) {
                        columns.push(col.Property);
                    });

                    columns.push('EndDate');
                    columns.push('Type');

                    if ($scope.conditions) {
                        while ($scope.conditions.length > 0)
                            $scope.conditions.pop();
                    }

                    repository.GetPaginated(repository.apiData.condition.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns))
                        .then(function (result) {
                            $scope.conditionTable.settings().total = result.TotalCount;
                            $scope.conditionTable.settings().filterDelay = 500;

                            if (result.List.length > 0) {
                                var conditionTypeColumns = ['Description', 'IsApproved'];
								repository.GetPaginated(repository.apiData.conditionType.url, 0, 20, { 'Description': 'asc' }, { PropertyFilter: [{ Property: 'IsApproved', Operator: '=', Value: 1 }] }, null, JSON.stringify(conditionTypeColumns))
									.then(function (result) {
										if (result.TotalCount === 1) {
											$scope.hasAvailableDefaultCondition = true;
										}

										if (result.TotalCount > 1) {
											if ($localStorage.generalOptions.GuidCommonDataOwner && _.some(result.List, { GuidDataOwner: $localStorage.generalOptions.GuidCommonDataOwner })) {
												$scope.hasAvailableDefaultCondition = true;
											}
										}
                                    });
                            }

                            $defer.resolve(result.List);
                            $scope.conditionsIsLoading = false;

                        }, function (error) {
                            $scope.conditionsIsLoading = false;
                            repository.growl(error, 'danger');
                        });
                }
            });
        };

        //Returns the entity connected to the the deviation that is the lowest in the hierarchy
        $scope.getLowestEntityCaption = function (deviation) {
            if (deviation.Equipment) return $rootScope.getEntityCaption(deviation.Equipment);
            if (deviation.Area) return $rootScope.getEntityCaption(deviation.Area);
            if (deviation.Building) return $rootScope.getEntityCaption(deviation.Building);
            if (deviation.Estate) return $rootScope.getEntityCaption(deviation.Estate);
        };

        $scope.getEntityCaption = function (object) {
            return $rootScope.getEntityCaption(object);
        };

        $scope.getEntityIcon = function (deviation) {
            if (deviation.Equipment) return 'zmdi-settings c-bluegray';
            if (deviation.Area) return 'zmdi-layers c-lightblue';
            if (deviation.Building) return 'zmdi-balance c-brown';
            if (deviation.Estate) return 'zmdi-city c-teal';
        };

        $scope.getPropertyValue = function (item, column) {
            return listService.GetPropertyValue(item, column);
        };

        $scope.selectedConditions = [];

        $scope.conditionIsSelected = function (guid) {
            return _.contains($scope.selectedConditions, guid);
        };

        $scope.addCondition = function (guid) {
            if (!_.contains($scope.selectedConditions, guid))
                $scope.selectedConditions.push(guid);
            else {
                for (var i = 0; i < $scope.selectedConditions.length; i++) {
                    if ($scope.selectedConditions[i] === guid) {
                        $scope.selectedConditions.splice(i, 1);
                        return;
                    }
                }
            }
        };

        $scope.allRowsSelected = false;

        $scope.selectAllRows = function () {
            if ($scope.allRowsSelected) {
                $scope.conditionTable.data.forEach(function (row) {
                    var index = _.findIndex($scope.selectedConditions, function (o) { return o === row.Guid; });
                    $scope.selectedConditions.splice(index, 1);
                });

                $scope.allRowsSelected = false;
            } else {
                $scope.conditionTable.data.forEach(function (row) {
                    if (!_.contains($scope.selectedConditions, row.Guid)) {
                        $scope.selectedConditions.push(row.Guid);
                    }
                });
                $scope.allRowsSelected = true;
            }
        };

        $scope.$watch('conditionTable.data', function (newValue, oldValue) {
            if (newValue === oldValue) return;

            if (newValue.length === 0) {
                $scope.allRowsSelected = false;
                return;
            }

            for (var i = 0; i < newValue.length; i++) {
                if (!_.contains($scope.selectedConditions, newValue[i].Guid)) {
                    $scope.allRowsSelected = false;
                    return;
                }
            }

            $scope.allRowsSelected = true;
        });

        $scope.addEntityToCheck = function () {
            $modal.open({
                templateUrl: 'app/condition/views/createConditionForInspection.html',
				controller: 'CreateConditionForInspectionController',
				size: isSVV ? 'lg' : 'md',
                resolve: {
                    params: function () {
                        var object = { guidWorkOrder: $scope.guid };

                        switch ($localStorage.generalOptions.InspectedEntityLocation) {
                            case constants.inspectedEntityLocation.estate:
                                if ($scope.estate) {
                                    object.guidEstate = $scope.estate.Guid;
                                }
                                break;
                            case constants.inspectedEntityLocation.building:
                                if ($scope.building) {
                                    object.guidBuilding = $scope.building.Guid;
                                }
                                break;
                        }

                        return object;
                    }
                }
            }).result.then(function () {
                $scope.conditionTable.reload();
            }, function () {
                //cancel
            });
		};

        $scope.createOrUpdate = function (condition) {
            $modal.open({
                templateUrl: 'app/condition/views/editConditionModal.html',
				controller: 'ConditionController',
                resolve: {
					$stateParams: function () {
						var params = {
							isModal: true,
							canCloseCorrectiveAction: $scope.canCloseCorrectiveAction,
							closeCorrectiveAction: $scope.closeCorrectiveAction
						};

						if (condition) {
							params.guid = condition.Guid;
						}
						else {
							params.model = {
								GuidEstate: $scope.estate ? $scope.estate.Guid : null,
								Estate: $scope.estate,
								GuidBuilding: $scope.building ? $scope.building.Guid : null,
								Building: $scope.building,
								GuidWorkOrder: $scope.guid,
								Type: 'Condition'
							};
						}

						return params;
                    }
                }
            }).result.then(function () {
                $scope.conditionTable.reload();
            }, function () {
                //cancel
            });
        };

        //Approve single condition
        $scope.approveCondition = function (condition) {
            swal({
                title: translationService.translate('web-swal-condition-approve', 'Bekreft godkjenning av Tilstand'),
                text: translationService.translate('web-swal-condition-approve-message', 'Er du sikker på at du vil godkjenne tilstanden?'),
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#2196f3",
                confirmButtonText: translationService.translate('web-swal-condition-approve-confirm', 'Ja, Godkjenn tilstanden!'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
            }, function () {
                var payload = {
                    Guid: condition.Guid,
                    RequestAction: 'ApproveCondition'
                };
                repository.updateSingleDictionary(repository.apiData.condition.url, payload).then(function (result) {
                    swal(translationService.translate('web-swal-condition-approve-success', 'Tilstanden ble godkjent!'), result.Id, "success");
                    $scope.conditionTable.reload();
                }, function (error) {
                    swal(translationService.translate('web-error', "Feil"), error, "error");
                });
            });
        };

        $scope.canCloseCorrectiveAction = function (condition) {
            return !condition.EndDate &&
				condition.GuidWorkOrder &&
				condition.GuidWorkOrder === $scope.guid &&
				condition.Type === 'CorrectiveAction';
        };

        //close correctiveAction
		$scope.closeCorrectiveAction = function (condition) {
            if (condition.EndDate) {
                swal(translationService.translate('web-correctiveAction-alreadyClosed',
					"Feil",
					"Tiltaket er allerede lukket",
					"error"));
				return $.Deferred().reject().promise();
			}

			var deferred = $q.defer();

            swal({
                title: translationService.translate('web-swal-correctiveAction-close', 'Bekreft lukking av tiltak'),
                text: translationService.translate('web-swal-corectiveAction-approve-message', 'Er du sikker på at du vil lukke tiltaket?'),
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#2196f3",
                confirmButtonText: translationService.translate('web-swal-correctiveAction-close-confirm', 'Ja, Lukk tiltaket!'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
            }, function () {
                condition.RequestAction = "Close";
                repository.updateSingleDictionary(repository.apiData.condition.url, condition).then(function (result) {
                    swal(translationService.translate('web-swal-correctiveAction-close-success', 'Tiltaket ble lukket!'), result.Id, "success");
					$scope.conditionTable.reload();
					deferred.resolve(result);
                }, function (error) {
					swal(translationService.translate('web-error', "Feil"), error, "error");
					deferred.reject(error);
                });
                condition.RequestAction = null;
			});
			return deferred.promise;
        };

        //Approve multiple conditions
        $scope.approveSelectedConditions = function () {
            if ($scope.selectedConditions.length === 0) {
                swal(translationService.translate('web-no-choice-header', 'Ingen valg'),
					translationService.translate('workorder-condition-list-noSelect', 'Ingen tilstander valgt. Velg en eller flere fra listen for å godkjenne'),
					'error');
                return;
            }

			var nrSuccessCalls = 0;
			var nrOfCalls = 0;
			var errors = [];

            swal({
                title: translationService.translate('web-swal-conditions-approve', 'Bekreft godkjenning av Tilstander'),
                text: translationService.translate('web-swal-conditions-approve-message', 'Er du sikker på at du vil godkjenne tilstandene?'),
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#2196f3",
                confirmButtonText: translationService.translate('web-swal-conditions-approve-confirm', 'Godkjenn tilstandene'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
            }, function () {
                $scope.selectedConditions.forEach(function (condition) {
                    updateCondition({ Guid: condition, RequestAction: 'ApproveCondition' });
                });
            });

            var updateCondition = function (condition) {
                repository.updateSingleDictionary(repository.apiData.condition.url, condition).then(function (result) {
					nrSuccessCalls++;
					nrOfCalls++;
                    if (nrSuccessCalls === $scope.selectedConditions.length) {
                        swal(translationService.translate('web-swal-conditions-approve-success', 'Tilstandene ble godkjent!'), '', "success");
                        $scope.conditionTable.reload();
					} else if (nrOfCalls === $scope.selectedConditions.length && errors.length > 0) {
						$scope.conditionTable.reload();
						swal(nrOfCalls - nrSuccessCalls + ' ' + translationService.translate('web-condition-approveSelectedConditions-failed', 'ble ikke satt som standardtilstand'), _.uniq(errors).join("\r\n"), "error");
					}
				}, function (error) {
					nrOfCalls++;
					errors.push(error);
					if (nrOfCalls === $scope.selectedConditions.length && errors.length > 0) {
						$scope.conditionTable.reload();
						swal(nrOfCalls - nrSuccessCalls + ' ' + translationService.translate('web-condition-approveSelectedConditions-failed', 'ble ikke satt som standardtilstand'), _.uniq(errors).join("\r\n"), "error");
					}
				});
            };
        };

        //Delete multiple conditions
        $scope.deleteSelectedConditions = function () {
            var deletedConditions = [], notDeletedConditions = [];

            swal({
                title: translationService.translate('web-swal-conditions-delete', 'Bekreft sletting av Tilstander'),
                text: translationService.translate('web-swal-conditions-delete-message', 'Er du sikker på at du vil slette alle tilstandene?'),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
                confirmButtonText: translationService.translate('web-swal-conditions-delete-confirm', 'Slett tilstandene'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
            }, function () {
                $scope.selectedConditions.forEach(function (condition) {
                    var fullCondition = _.find($scope.conditionTable.data, { Guid: condition });
                    if (fullCondition.Condition1 || fullCondition.ActionComment)
                        notDeletedConditions.push(fullCondition);
                    else {
                        deleteCondition({ Guid: condition });
                    }
                });

                if ($scope.selectedConditions.length === notDeletedConditions.length) {
                    swal('Kunne ikke slette tilstandene', 'Ikke slettet: ' + _.map(notDeletedConditions, 'Id'), "info");
                }
            });

            var nrSuccessCalls = 0;

            var deleteCondition = function (condition) {
                repository.deleteSingle(repository.apiData.condition.url, condition.Guid).then(function (result) {
                    nrSuccessCalls++;
                    if (nrSuccessCalls === $scope.selectedConditions.length) {
                        swal(translationService.translate('web-swal-conditions-delete-success', 'Tilstandene ble slettet!'), '', "success");
                        $scope.selectedConditions = [];
                        $scope.conditionTable.reload();
                    } else if (nrSuccessCalls > 0) {
                        swal('Kunne ikke slette alle tilstandene', 'Ikke slettet: ' + _.map(notDeletedConditions, 'Id'), "info");
                        $scope.selectedConditions = _.map(notDeletedConditions, 'Guid');
                        $scope.conditionTable.reload();
                    } else {
                        swal('Kunne ikke slette tilstandene', 'Ikke slettet: ' + _.map(notDeletedConditions, 'Id'), "danger");
                    }
                });
            };
        };

        $scope.remove = function (condition) {
            swal({
                title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                text: translationService.translate('web-swal-equipment-message', 'Anlegget vil bli permanent fjernet!'),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
                confirmButtonText: translationService.translate('web-swal-equipment-button-confirm', 'Ja, fjern anlegget!'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
            }, function () {
                repository.deleteSingle(repository.apiData.condition.url, condition.Guid)
					.then(function (result) {
					    swal(translationService.translate('web-swal-equipment-success', 'Anlegget ble fjernet!'), result, "success");
					    $scope.conditionTable.reload();
					});
            });
        };

		$scope.editColumns = function () {
			$modal.open({
                templateUrl: 'app/common/modal/views/editTableColumnsModal.html',
                controller: 'EditTableColumnsModalController',
                resolve: {
                    params: function () {
                        return {
                            defaultListSetup: originalListSetup,
                            listSetup: $scope.defaultListSetup,
                            entity: 'Condition'
                        };
                    }
                }
            }).result.then(function (result) {
                $scope.conditionTable.reload();
            }, function (dismiss) {
            });
        };

        $scope.$on($rootScope.events.newSelection, function () {
            $scope.conditionTable.reload();
        });
    }
})();
